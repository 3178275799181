import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { APP_CONFIG } from '../config/Config';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: APP_CONFIG.APPINSIGHTS_KEY,
    extensions: [reactPlugin],
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
