/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { DisplayBox, IBoxProps } from '../boxes/DisplayBox';
import { Button } from '@progress/kendo-react-buttons';
import { CommonResources } from '../../../config/CommonResources';
import { IResponseProps } from '../../services/GraphQLShared';
import { createPortal } from 'react-dom';
import { parseGraphQLErrors } from '../../helpers/Utils';
import { Alert } from 'react-bootstrap';
import { OnDemandIcon } from '../../helpers/IconUtils';
import cn from 'classnames';

export interface IDisplayPanelProps {
  Boxes: IBoxProps[];
  onClick: () => void;
}
export const DisplayPanel = (props: IDisplayPanelProps): React.ReactElement => {
  return (
    <>
      {props.Boxes.map((box: any, i: number) => (
        <DisplayBox
          key={i}
          title={box.label}
          value={box.value}
          format={box.format}
          icon={box.icon}
          enum={box.enum}
          type={box.type}
          field={box.field}
        />
      ))}
      <div className="form-row justify-content-end">
        <Button themeColor={'primary'} onClick={props.onClick}>
          {CommonResources.Button_Edit}
        </Button>
      </div>
    </>
  );
};
export interface IErrorPanelProps {
  error: Error;
  onClose?: () => void;
}

export const ErrorPanel = (props: IErrorPanelProps): React.ReactElement => {
  return (
    <div className="k-messagebox k-messagebox-error pt-2 mt-1">
      {props.onClose && <OnDemandIcon className="k-messagebox__close-icon" icon="times" onClick={props.onClose} />}
      <h5 className="k-messagebox-error mb-0">{CommonResources.Error_Title}</h5>
      {props.error &&
        parseGraphQLErrors().map((me: string, i: number) => (
          <p
            className="mt-0 k-messagebox-error"
            key={i}
            dangerouslySetInnerHTML={{
              __html: me
                .replace('App.Framework.Common.Exceptions.GraphQlException:', '')
                .replace(
                  'App.Framework.Common.Exceptions.UnauthorizedException: Security error.',
                  'You are not <b>authorized</b> to run this query. Please ensure you are a <u>Origin active user</u> for the selected application',
                )
                .replace('--->', '')
                .replace('GraphQL.Execution.UnhandledError:', '<u>Error</u>: '),
            }}
          ></p>
        ))}
    </div>
  );
};
export interface IResponsePanelProps {
  response: IResponseProps;
}
export const ShowResponsePanel = (props: IResponsePanelProps): React.ReactElement => {
  return (
    <Alert variant={props.response.isError ? 'danger' : 'success'} className="mt-2" role="alert">
      {props.response.isError ? (
        <Alert.Heading>{CommonResources.Error_Title}</Alert.Heading>
      ) : (
        <Alert.Heading>{CommonResources.Success_Title}</Alert.Heading>
      )}
      <hr />
      {props.response.message && <p className="mb-0">{props.response.message}</p>}
      {props.response.totalRecords && props.response.totalRecords > 0 && (
        <p className="mb-0">
          {CommonResources.TotalRecords}
          {props.response.totalRecords}
        </p>
      )}
    </Alert>
  );
};
export interface IAlertPanelProps {
  error: string;
}
const AlertPanelBase = (props: IAlertPanelProps): React.ReactElement => {
  return (
    <Alert variant="danger" role="alert">
      <Alert.Heading>{CommonResources.Error_Title}</Alert.Heading>
      <hr />
      <p>{props.error}</p>
    </Alert>
  );
};
function AlertPanelAreEqual(prev: IAlertPanelProps, next: IAlertPanelProps) {
  return prev.error === next.error;
}
export const AlertPanel = React.memo(AlertPanelBase, AlertPanelAreEqual);

export interface IWarningPanelProps {
  message: string;
}
const WarningPanelBase = (props: IWarningPanelProps): React.ReactElement => {
  return (
    <Alert variant="warning" role="alert">
      <Alert.Heading>{CommonResources.Error_Title}</Alert.Heading>
      <hr />
      <p>{props.message}</p>
    </Alert>
  );
};
function WarningPanelAreEqual(prev: IWarningPanelProps, next: IWarningPanelProps) {
  return prev.message === next.message;
}
export const WarningPanel = React.memo(WarningPanelBase, WarningPanelAreEqual);

export interface ISuccessPanelProps {
  message: string;
}
const SuccessPanelBase = (props: ISuccessPanelProps): React.ReactElement => {
  return (
    <Alert variant="success" role="alert">
      <Alert.Heading>{CommonResources.Success_Title}</Alert.Heading>
      <hr />
      <p>{props.message}</p>
    </Alert>
  );
};
function SuccessPanelAreEqual(prev: ISuccessPanelProps, next: ISuccessPanelProps) {
  return prev.message === next.message;
}
export const SuccessPanel = React.memo(SuccessPanelBase, SuccessPanelAreEqual);

function LoadingPanelBase() {
  return (
    <div className="k-loading-mask" style={{ minHeight: '12rem' }}>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
}
export const LoadingPanel = React.memo(LoadingPanelBase);

function GridLoadingPanelBase() {
  return (
    <div className="k-loading-mask mt-4">
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
}
export const GridLoadingPanel = React.memo(GridLoadingPanelBase);

export const loadingPanel: React.ReactElement = (
  <div className="k-loading-mask">
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

interface ICLoadingPanelProps {
  size?: 'sm' | 'md';
  hasTransparentBg?: boolean;
  withContainer?: boolean;
}
const CLoadingPanel = (props: ICLoadingPanelProps) => (
  <div className={cn('k-loading-mask-wrapper', { 'k-loading-mask-wrapper_active': props.withContainer })}>
    <div className="k-loading-mask">
      <div className={cn('k-loading-image', { [`k-loading-image_size-${props.size}`]: props.size })}></div>
      <div className={cn('k-loading-color', { 'k-loading-color_no-bg': props.hasTransparentBg })}></div>
    </div>
  </div>
);

export interface LoadingPanelProps extends ICLoadingPanelProps {
  entityName?: string;
}
export const GenericLoadingPanel = (props: LoadingPanelProps) => {
  const { entityName, ...restProps } = props;
  const gridContent = entityName && document.querySelector(`.${entityName}`);
  const loadingPanel = <CLoadingPanel {...restProps} />;
  return gridContent ? createPortal(loadingPanel, gridContent) : loadingPanel;
};

export const ListViewLoadingPanel = () => {
  const gridContent = document && document.querySelector(`.k-listview-content`);
  return gridContent ? createPortal(loadingPanel, gridContent) : loadingPanel;
};
export const TreeViewLoadingPanel = () => {
  const gridContent = document && document.querySelector('.k-treeview ');
  return gridContent ? createPortal(loadingPanel, gridContent) : loadingPanel;
};
export const DialogLoadingPanel = () => {
  const gridContent = document && document.querySelector('.k-dialog');
  return gridContent ? createPortal(loadingPanel, gridContent) : loadingPanel;
};
export const TabContainerLoadingPanel = () => {
  const gridContent = document && document.querySelector('.k-tabstrip-wrapper');
  return gridContent ? createPortal(loadingPanel, gridContent) : loadingPanel;
};
