import { OnDemandIcon } from '../../helpers/IconUtils';
import { DateInput, DatePicker, DateRangePicker, DateTimePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import { AutoComplete, ComboBox, DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Editor, EditorTools } from '@progress/kendo-react-editor';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import {
  Checkbox,
  ColorPicker,
  Input,
  MaskedTextBox,
  NumericTextBox,
  RadioGroup,
  Rating,
  Slider,
  SliderLabel,
  Switch,
  TextArea,
} from '@progress/kendo-react-inputs';
import { Error, FloatingLabel, Hint, Label } from '@progress/kendo-react-labels';
import { Upload } from '@progress/kendo-react-upload';
import { produce } from 'immer';
import React from 'react';
import { CommonResources } from '../../../config/CommonResources';
import { isEmpty } from '../../helpers/Utils';
import { filterBy } from '@progress/kendo-data-query';
import { Link } from 'react-router-dom';

export const RouteLabel = (fieldRenderProps: FieldRenderProps) => {
  const { label, route } = fieldRenderProps;
  return route ? (
    <Link
      to={route}
      target="_blank noreferrer"
      title={CommonResources.Button_Navigate}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      {label}
      <OnDemandIcon icon={'arrow-right'} className="ms-1" />
    </Link>
  ) : (
    label
  );
};
export const FormLegend = (fieldRenderProps: any) => {
  const { title, id } = fieldRenderProps;
  return (
    <div className="form-row">
      <div className="mt-2 col-md-12">
        <legend id={id} className={'k-form-legend'}>
          {title}
        </legend>
      </div>
    </div>
  );
};
export const FormInput = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, title, label, id, valid, disabled, hint, type, optional, ...others } =
    fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const showTitle = title;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';

  return (
    <FieldWrapper>
      <div className={'k-form-field-wrapper'}>
        {showTitle && (
          <Label editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>
            {title}
          </Label>
        )}
        <Input
          valid={valid}
          type={type}
          id={id}
          label={label}
          disabled={disabled}
          ariaDescribedBy={`${hindId} ${errorId}`}
          {...others}
        />
      </div>
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormStickyInput = (fieldRenderProps: FieldRenderProps) => {
  fieldRenderProps.value = fieldRenderProps.value.name;
  return FormInput(fieldRenderProps);
};

export const FormRadioGroup = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, id, title, valid, disabled, hint, ...others } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = title ? `${id}_label` : '';
  const editorRef = React.useRef<any>(null);
  return (
    <FieldWrapper>
      <Label id={labelId} editorRef={editorRef} editorValid={valid} editorDisabled={disabled} className={'mb-1'}>
        {title}
      </Label>
      <RadioGroup
        ref={editorRef}
        ariaDescribedBy={`${hindId} ${errorId}`}
        ariaLabelledBy={labelId}
        valid={valid}
        disabled={disabled}
        {...others}
      />
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormNumericTextBox = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, title, id, valid, optional, disabled, hint, ...others } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return (
    <FieldWrapper>
      <div className={'k-form-field-wrapper'}>
        <Label editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>
          {title}
        </Label>
        <NumericTextBox
          ariaDescribedBy={`${hindId} ${errorId}`}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
      </div>
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormCheckbox = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, id, valid, readOnly, hint, title, ...others } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return (
    <FieldWrapper>
      <Checkbox
        ariaDescribedBy={`${hindId} ${errorId}`}
        label={title}
        valid={valid}
        id={id}
        disabled={readOnly}
        {...others}
      />
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormCheckboxGroup = (props) => {
  const showValidationMessage = props.modified && props.validationMessage;
  const showHint = !showValidationMessage && props.hint;
  const onChange = (value) => (event) => {
    const shouldValueAdded = event.value;
    props.onChange({
      ...event,
      value: produce(props.value, (i) => {
        const index = i.indexOf(value);
        if (shouldValueAdded) {
          i.push(value);
        } else {
          i.splice(index, 1);
        }
      }),
    });
  };

  return (
    <div className={props.className || 'mt-3'}>
      {props.data.map((el) => (
        <Checkbox
          key={el.value}
          label={el.title}
          value={props.value.includes(el.value)}
          onChange={onChange(el.value)}
          className="d-block"
        />
      ))}
      {showHint && <Hint>{props.hint}</Hint>}
      {showValidationMessage && <Error>{props.validationMessage}</Error>}
    </div>
  );
};

export const FormSwitch = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, title, optional, id, valid, disabled, hint, ...others } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = title ? `${id}_label` : '';

  return (
    <FieldWrapper>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
        style={{ display: 'inline-block', paddingRight: '1rem' }}
      >
        {title}
      </Label>
      <Switch
        ref={editorRef}
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hindId} ${errorId}`}
        valid={valid}
        id={id}
        disabled={disabled}
        {...others}
      />
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormMaskedTextBox = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, title, id, valid, hint, optional, ...others } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return (
    <FieldWrapper>
      <div className={'k-form-field-wrapper'}>
        <Label editorId={id} editorValid={valid} optional={optional}>
          {title}
        </Label>
        <div className={'k-form-field-wrap'}>
          <MaskedTextBox ariaDescribedBy={`${hindId} ${errorId}`} valid={valid} id={id} {...others} />
        </div>
      </div>
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormTextArea = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, title, id, valid, hint, disabled, optional, ...others } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';

  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid} optional={optional}>
        {title}
      </Label>
      <TextArea
        valid={valid}
        id={id}
        disabled={disabled}
        rows={8}
        ariaDescribedBy={`${hintId} ${errorId}`}
        {...others}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormEditor = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, title, id, value, valid, hint, onChange, optional, ...others } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const onChangeHandler = (event) => {
    onChange({ value: event.html });
  };
  const editorRef = React.useRef<any>(null);
  return (
    <FieldWrapper>
      <Label editorRef={editorRef} editorId={id} editorValid={valid} optional={optional}>
        {title}
      </Label>
      <div className={'k-form-field-wrap'}>
        <Editor
          ref={editorRef}
          aria-describedby={`${hindId} ${errorId}`}
          className={'k-textarea k-autofill'}
          defaultEditMode={'div'}
          value={value}
          onChange={onChangeHandler}
          contentStyle={{ height: 120 }}
          tools={[
            [EditorTools.Bold, EditorTools.Italic, EditorTools.Underline],
            [EditorTools.AlignLeft, EditorTools.AlignCenter, EditorTools.AlignRight],
            [EditorTools.OrderedList, EditorTools.UnorderedList, EditorTools.Indent, EditorTools.Outdent],
          ]}
          {...others}
        />
        {showHint && <Hint id={hindId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </div>
    </FieldWrapper>
  );
};

export const FormColorPicker = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, title, valid, optional, disabled, hint, wrapperStyle, ...others } =
    fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';

  return (
    <FieldWrapper style={wrapperStyle}>
      <div className={'k-form-field-wrapper'}>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {title}
        </Label>
        <ColorPicker
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hindId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
      </div>
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormSlider = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, hint, disabled, data, min, max, ...others } = fieldRenderProps;
  const editorRef = React.useRef<any>(null);
  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';
  const errorId: string = showValidationMessage ? `${id}_error` : '';
  const labelId: string = label ? `${id}_label` : '';

  return (
    <FieldWrapper>
      <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
        {label}
      </Label>
      <Slider
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        min={min}
        max={max}
        {...others}
      >
        {data.map((value) => (
          <SliderLabel title={value} key={value} position={value}>
            {value.toString()}
          </SliderLabel>
        ))}
      </Slider>
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormRating = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, hint, disabled, optional, ...others } = fieldRenderProps;

  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';
  const errorId: string = showValidationMessage ? `${id}_error` : '';

  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid} optional={optional}>
        {label}
      </Label>
      <Rating valid={valid} id={id} disabled={disabled} ariaDescribedBy={`${hintId} ${errorId}`} {...others} />
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormUpload = (fieldRenderProps: FieldRenderProps) => {
  const { value, id, optional, label, hint, validationMessage, touched, ...others } = fieldRenderProps;

  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';
  const errorId: string = showValidationMessage ? `${id}_error` : '';
  const labelId: string = label ? `${id}_label` : '';

  const onChangeHandler = (event: any) => {
    fieldRenderProps.onChange({ value: event.newState });
  };
  const onRemoveHandler = (event: any) => {
    fieldRenderProps.onChange({ value: event.newState });
  };

  return (
    <FieldWrapper>
      <Label id={labelId} editorId={id} optional={optional}>
        {label}
      </Label>
      <Upload
        id={id}
        autoUpload={false}
        showActionButtons={false}
        multiple={false}
        files={value}
        onAdd={onChangeHandler}
        onRemove={onRemoveHandler}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ariaLabelledBy={labelId}
        {...others}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormDropDownList = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, optional, disabled, hint, wrapperStyle, ...others } =
    fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return (
    <FieldWrapper style={wrapperStyle}>
      <div className={'k-form-field-wrapper'}>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          <RouteLabel {...fieldRenderProps} />
        </Label>
        <DropDownList
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hindId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          popupSettings={{ animate: false }}
          {...others}
        />
      </div>
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormAutoComplete = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, optional, disabled, hint, wrapperStyle, onChange, ...others } =
    fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return (
    <FieldWrapper style={wrapperStyle}>
      <div className={'k-form-field-wrapper'}>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          <RouteLabel {...fieldRenderProps} />
        </Label>
        <AutoComplete
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hindId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          onChange={onChange}
          disabled={disabled}
          {...others}
        />
      </div>
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormComboBox = (fieldRenderProps: FieldRenderProps) => {
  const [data, setData] = React.useState(fieldRenderProps.data);

  React.useEffect(() => {
    if (isEmpty(fieldRenderProps.data)) return;
    setData(fieldRenderProps.data);
  }, [fieldRenderProps.data]);

  const onFilterChange = (e) => setData(filterBy(fieldRenderProps.data.slice(), e.filter));

  const { validationMessage, touched, label, id, valid, disabled, hint, optional, wrapperStyle, ...others } =
    fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';

  return (
    <FieldWrapper style={wrapperStyle}>
      <div className={'k-form-field-wrapper'}>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          <RouteLabel {...fieldRenderProps} />
        </Label>
        <ComboBox
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hindId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          filterable
          popupSettings={{ animate: false }}
          placeholder="Select an option..."
          {...others}
          data={data}
          onFilterChange={onFilterChange}
        />
      </div>
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormMultiSelect = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, optional, disabled, hint, wrapperStyle, ...others } =
    fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return (
    <FieldWrapper style={wrapperStyle}>
      <div className={'k-form-field-wrapper'}>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          <RouteLabel {...fieldRenderProps} />
        </Label>
        <MultiSelect
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hindId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
      </div>
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormGuidMultiSelect = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, optional, disabled, hint, wrapperStyle, ...others } =
    fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return (
    <FieldWrapper style={wrapperStyle}>
      <div className={'k-form-field-wrapper'}>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {label}
        </Label>
        <MultiSelect
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hindId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
      </div>
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormDatePicker = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, optional, disabled, hint, wrapperStyle, ...others } =
    fieldRenderProps;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';

  return (
    <FieldWrapper style={wrapperStyle}>
      <div className={'k-form-field-wrapper'}>
        <Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>
          {label}
        </Label>
        <div className={'k-form-field-wrap'}>
          <DatePicker
            ariaLabelledBy={labelId}
            ariaDescribedBy={`${hindId} ${errorId}`}
            valid={valid}
            id={id}
            disabled={disabled}
            {...others}
          />
        </div>
      </div>
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormDateTimePicker = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, optional, disabled, hint, wrapperStyle, ...others } =
    fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return (
    <FieldWrapper style={wrapperStyle}>
      <div className={'k-form-field-wrapper'}>
        <Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>
          {label}
        </Label>
        <DateTimePicker
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hindId} ${errorId}`}
          valid={valid}
          id={id}
          disabled={disabled}
          defaultValue={new Date()}
          {...others}
        />
      </div>
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormTimePicker = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, optional, disabled, hint, wrapperStyle, ...others } =
    fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return (
    <FieldWrapper style={wrapperStyle}>
      <div className={'k-form-field-wrapper'}>
        <Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>
          {label}
        </Label>
        <TimePicker
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hindId} ${errorId}`}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
      </div>
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormDateInput = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, disabled, hint, optional, wrapperStyle, ...others } =
    fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return (
    <FieldWrapper style={wrapperStyle}>
      <div className={'k-form-field-wrapper'}>
        <Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>
          {label}
        </Label>
        <DateInput
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hindId} ${errorId}`}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
      </div>
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormDateRangePicker = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, ...others } = fieldRenderProps;
  const editorRef = React.useRef<any>(null);

  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';
  const errorId: string = showValidationMessage ? `${id}_error` : '';
  const labelId: string = label ? `${id}_label` : '';

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
        {label}
      </Label>
      <DateRangePicker
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        {...others}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormFloatingNumericTextBox = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, disabled, hint, optional, value, ...others } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return (
    <FieldWrapper>
      <FloatingLabel
        optional={optional}
        editorValue={value}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        label={label}
      >
        <NumericTextBox
          ariaDescribedBy={`${hindId} ${errorId}`}
          value={value}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
      </FloatingLabel>
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
