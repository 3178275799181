import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScripts } from '../../helpers/Utils';

export function GraphPage(): React.ReactElement {
  const currentSecurity = useSecurity('Shared', 'Graph');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScripts(['Graph.custom.js', 'Graph.js']);
    }
  }, []);
  return (
    <div className="panelcontent-section">
      <div id="sidebar-section" style={{ display: 'none' }}>
        <i className="fas fa-bars" id="sidebarBar"></i>
        <form id="form" className="k-content">
          <h3>Report Setup & Parameters</h3>
          <div id="ParametersList"></div>
          <section className="button-toolbar">
            <div className="right">
              <button id="generateReport" type="button" className="k-button k-primary" title="Go">
                Go
              </button>
            </div>
          </section>
        </form>
      </div>
      <div id="content-section" className="collapsed">
        <i className="fas fa-bars" id="contentBar" style={{ display: 'none' }}></i>
        <div id="Container" className="k-content">
          <div id="LineContent">
            <div id="LineContainer"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
