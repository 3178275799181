import React, { useState } from 'react';
import { appendScriptsCallback, formatDate } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
import { getEntityDetails } from '../../../../services/ApiServices';

export interface IAdminAudit {
  Id: string;
  EntityId?: string;
  InstanceName?: string;
  Domain?: string;
  Aggregate?: string;
  OperationType?: string;
  UserId: string;
  AuditFrom: Date;
  IsRemoved: boolean;
  EntityType: number;
  SourceReference?: string;
}

export function AuditDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IAdminAudit>({
    Id: '',
    UserId: '',
    AuditFrom: new Date(),
    IsRemoved: true,
    EntityType: 0,
  });
  React.useEffect(() => {
    if (state.Id == '') {
      getEntityDetails('Admin', 'Audit', id).then((result) => {
        setState(result.Value[0] as IAdminAudit);
        appendScriptsCallback(
          [
            'Areas/Admin/Scripts/Resources/en/res.Audit.en.js',
            'Areas/Admin/Scripts/Enums.js',
            'Areas/Admin/Scripts/Audit/View.js',
            'Areas/Admin/Scripts/Resources/en/res.AuditDetail.en.js',
            'Areas/Admin/Scripts/AuditDetail/View.js',
          ],
          'initAudit',
          {
            viewmodel: result.Value[0],
            canEdit: false,
            canCreate: false,
            canRemove: false,
            id: id,
            operationType: result.Value[0].OperationType,
            auditFrom: formatDate(result.Value[0].AuditFrom),
          },
        );
      });
    }
  }, []);
  window['reloadAuditState'] = (data) => {
    setState(data.Value[0] as IAdminAudit);
  };
  return (
    <section className="content" id="patron" style={{ display: 'none' }}>
      <div className="clear"></div>
      <section id="viewContent">
        <div id="Message" className="controls-container default block">
          <div className="label">
            <div className="title">
              <div id="summary-selectorsetup">
                <div className="selectorsetup-section k-content wide hidden-on-narrow">
                  <h3 className="selectorsetup-title">
                    Audit For: <span id="auditType"></span>
                  </h3>
                </div>
                <div className="selectorsetup-section k-content wide hidden-on-narrow">
                  <h4 className="selectorsetup-title">
                    Audit From: <span id="auditFrom"></span>
                  </h4>
                </div>
                <div className="selectorsetup-section k-content wide hidden-on-narrow">
                  <h4 className="selectorsetup-title">
                    Audit Type: <span id="auditOperation"></span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="associationUserGroupUser">
        <div className="k-block grid-details">
          <h2>Audit Detail</h2>
          <div id="AuditDetailGrid" className="grid-control"></div>
        </div>
      </div>
    </section>
  );
}
