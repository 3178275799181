import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { HubConnection } from '@microsoft/signalr';
import { toggleNotifications, addNotification, closeNotifications } from '../../store/actions/navActions';
import Message from './elements/Message';
import NotificationPopup from './elements/NotificationPopup';
import { RootState } from '../../store/reducer/rootReducer';
import { setupSignalRConnection, ISignalRMessageProps } from '../../services/SignalRHelper';
import { CommonResources } from '../../config/CommonResources';
import cn from 'classnames';
import { Button } from '@progress/kendo-react-buttons';

const mapState = (state: RootState) => ({
  notificationOpen: state.nav.notificationOpen,
  notifications: state.nav.notifications,
  profile: state.security.profile,
});
const mapDispatch = {
  toggleNotifications,
  addNotification,
  closeNotifications,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export type INotificationsProps = PropsFromRedux & {
  isAuthenticated: boolean;
  getAccessToken: (scopes: string[]) => Promise<string>;
};
const Notifications = (props: INotificationsProps) => {
  const [, setConnection] = useState<HubConnection | null>(null);
  const [newMessage, setNewMessage] = useState<ISignalRMessageProps | undefined>(undefined);
  React.useEffect(() => {
    const closeNotifications = (e: any) => {
      if (e.code === 'Escape' && props.notificationOpen) props.closeNotifications();
    };
    document.addEventListener('keydown', closeNotifications);
    return () => document.removeEventListener('keydown', closeNotifications);
  }, [props.notificationOpen]);

  useEffect(() => {
    if (props.isAuthenticated && props.profile && props.profile.UserId) {
      const setupEventsHub = setupSignalRConnection(
        props.profile,
        (message: ISignalRMessageProps) => {
          props.addNotification(message);
          setNewMessage(message);
        },
        props.getAccessToken,
      );
      setConnection(setupEventsHub);
    }
  }, []);

  React.useEffect(() => {
    const body = document.querySelector('body');
    const removeOverflow = () => body?.classList.remove('body-overflow');
    if (props.notificationOpen) {
      body?.classList.add('body-overflow');
    } else {
      removeOverflow();
    }
    return removeOverflow;
  }, [props.notificationOpen]);

  const onClickClose = () => props.toggleNotifications();
  const notificationsClass = cn('notifications dialog mobile shadow', { open: props.notificationOpen });

  return (
    <>
      <aside className={notificationsClass}>
        <div className="dialog-header large">
          <h4 className="title">{CommonResources.Notifications}</h4>
          <Button type="button" className="btn-close" fillMode={'flat'} icon={'close'} onClick={onClickClose} />
        </div>
        <div className="dialog-content">
          <ul className="list-group list-group-flush list-notification">
            {props.notifications && props.notifications.map((e, i) => <Message data={e} key={i} />)}
          </ul>
        </div>
      </aside>
      {newMessage && <NotificationPopup message={newMessage} />}
    </>
  );
};
export default connector(Notifications);
