import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ISelectMenuItem } from '../components/services/GraphQLShared';
import { Colors } from './Constants';

export interface IEnumProps {
  id: number;
  code: string;
  text: string;
  icon: IconProp;
  color: string;
}
export const OperatorType = {
  Contains: 'CONTAINS',
  StartsWith: 'STARTS_WITH',
  EndsWith: 'ENDS_WITH',
  Equal: 'EQUAL',
  GreaterThan: 'GREATER_THAN',
  GreaterThanOrEqual: 'GREATER_THAN_OR_EQUAL',
  LessThan: 'LESS_THAN',
  LessThanOrEqual: 'LESS_THAN_OR_EQUAL',
  InList: 'IN_LIST',
  Empty: 'EMPTY',
};
export const OpLogic = {
  AND: 'AND',
  OR: 'OR',
};
export const EntityStatus: IEnumProps[] = [
  { text: 'Active', code: 'ACTIVE', id: 1, icon: 'play-circle', color: Colors.SuccessColor },
  { text: 'Inactive', code: 'INACTIVE', id: 2, icon: 'stop-circle', color: Colors.AlertColor },
  { text: 'Disable', code: 'DISABLE', id: 4, icon: 'pause-circle', color: Colors.WarningColor },
  { text: 'All', code: 'ALL', id: 8, icon: 'dot-circle', color: Colors.Primary_Brightblue },
];
export const EntityStatusMenu: ISelectMenuItem[] = [{ title: 'Active' }, { title: 'Inactive' }, { title: 'Disable' }];
export const PageType: IEnumProps[] = [
  { text: 'Standard', code: 'STANDARD', id: 0, icon: 'chart-line', color: Colors.Primary_OrangeHighlight },
  { text: 'Chart', code: 'CHART', id: 1, icon: 'chart-pie', color: Colors.Primary_Pink },
  { text: 'Table', code: 'TABLE', id: 2, icon: 'table-cells', color: Colors.Primary_Brightblue },
  { text: 'Pivot', code: 'PIVOT', id: 3, icon: 'table-list', color: Colors.Primary_Lightblue },
  { text: 'Other', code: 'OTHER', id: 4, icon: 'chart-gantt', color: Colors.Primary_Outlinegray },
];
export const PageTypeMenu: ISelectMenuItem[] = [
  { title: 'Standard', value: 'Standard' },
  { title: 'Chart', value: 'Chart' },
  { title: 'Table', value: 'Table' },
  { title: 'Pivot', value: 'Pivot' },
  { title: 'Other', value: 'Other' },
];
export const ProductType: IEnumProps[] = [
  { text: 'Power BI', code: 'POWER_BI', id: 0, icon: 'chart-line', color: Colors.Primary_OrangeHighlight },
  { text: 'Cross-Tab', code: 'CROSS_TAB', id: 1, icon: 'table-list', color: Colors.Primary_VioletHighlight },
  { text: 'Quick Access', code: 'LINK', id: 4, icon: 'link', color: Colors.Primary_Lightblue },
];
export const ProductTypeEnum = {
  PowerBI: 'POWER_BI',
  CrossTab: 'CROSS_TAB',
  Link: 'LINK',
};
export const ProductTypeMenu: ISelectMenuItem[] = [
  { title: 'Power BI', value: 'PowerBI' },
  { title: 'Cross-Tab', value: 'CrossTab' },
  { title: 'Quick Access', value: 'Other' },
];
export const NetGross: IEnumProps[] = [
  { text: 'None', code: 'NONE', id: 0, icon: ['far', 'circle-xmark'], color: Colors.Primary_Violet },
  { text: 'Net', code: 'NET', id: 1, icon: 'calculator', color: Colors.Primary_OrangeHighlight },
  { text: 'Gross', code: 'GROSS', id: 2, icon: 'calculator', color: Colors.Primary_Lightblue },
];
export const NetGrossEnum = {
  None: 'NONE',
  Net: 'NET',
  Gross: 'GROSS',
};
export const NetGrossMenu: ISelectMenuItem[] = [
  { title: 'None', value: 'None' },
  { title: 'Net', value: 'Net' },
  { title: 'Gross', value: 'Gross' },
];
export const YesNo: IEnumProps[] = [
  { text: 'Yes', code: 'YES', id: 0, icon: ['far', 'circle-xmark'], color: Colors.SuccessColor },
  { text: 'No', code: 'NO', id: 1, icon: 'user-cog', color: Colors.AlertColor },
];
export const YesNoEnum = {
  Yes: 'YES',
  No: 'NO',
};
export const YesNoMenu: ISelectMenuItem[] = [
  { title: 'Yes', value: 'Yes' },
  { title: 'No', value: 'No' },
];
export const FilterScope: IEnumProps[] = [
  { text: 'Report', code: 'REPORT', id: 0, icon: ['far', 'map'], color: Colors.Primary_Pink },
  { text: 'Page', code: 'PAGE', id: 1, icon: ['far', 'file'], color: Colors.Primary_GoldenHighlight },
  { text: 'Internal', code: 'INTERNAL', id: 2, icon: ['far', 'eye-slash'], color: Colors.Primary_VioletHighlight },
];
export const FilterScopeEnum = {
  Report: 'REPORT',
  Page: 'PAGE',
  Internal: 'INTERNAL',
};
export const FilterScopeMenu: ISelectMenuItem[] = [
  { title: 'Report', value: 'Report' },
  { title: 'Page', value: 'Page' },
  { title: 'Internal', value: 'Internal' },
];
export const DataSourceType: IEnumProps[] = [
  { text: 'Matrix', code: 'MATRIX', id: 0, icon: 'table-cells', color: Colors.SuccessColor },
  { text: 'SMI', code: 'SMI', id: 1, icon: 'table-list', color: Colors.AlertColor },
  { text: 'N/A', code: 'OTHER', id: 2, icon: ['far', 'circle-dot'], color: Colors.Primary_Brightblue },
];

export const DataSourceTypeEnum = {
  Matrix: 'MATRIX',
  SMI: 'SMI',
  Other: 'N/A',
};
export const DataSourceTypeMenu: ISelectMenuItem[] = [
  { title: 'Matrix', value: 'Matrix' },
  { title: 'SMI', value: 'SMI' },
  { title: 'N/A', value: 'Other' },
];

export const ControlType: IEnumProps[] = [
  { text: 'DropDown', code: 'DROP_DOWN', id: 0, icon: 'list', color: Colors.Primary_Brightblue },
  { text: 'CheckBox', code: 'CHECK_BOX', id: 1, icon: 'circle-check', color: Colors.Primary_Brightblue },
  { text: 'Text Box', code: 'TEXT_BOX', id: 3, icon: 'font', color: Colors.Primary_Brightblue },
  { text: 'Date Box', code: 'DATE_BOX', id: 4, icon: 'calendar', color: Colors.Primary_Brightblue },
  { text: 'Year Box', code: 'YEAR_BOX', id: 5, icon: 'calendar', color: Colors.Primary_Brightblue },
  { text: 'Month Box', code: 'MONTH_BOX', id: 6, icon: 'calendar', color: Colors.Primary_Brightblue },
  { text: 'MultiSelect', code: 'MULTI_SELECT_BOX', id: 7, icon: 'list-check', color: Colors.Primary_Pink },
  { text: 'Date Range Box', code: 'RANGE_BOX', id: 8, icon: 'calendar-days', color: Colors.Primary_Brightblue },
  {
    text: 'Date Range',
    code: 'RANGE_BOX_EXTENDED',
    id: 11,
    icon: 'calendar-week',
    color: Colors.Primary_Brightblue,
  },
  {
    text: 'Month Range',
    code: 'RANGE_BOX_EXTENDED_MONTH',
    id: 12,
    icon: 'calendar-week',
    color: Colors.Primary_Brightblue,
  },
];
export const ControlTypeEnum = {
  DropDown: 'DROP_DOWN',
  CheckBox: 'CHECK_BOX',
  //SwitchBox: 'SWITCH_BOX',
  TextBox: 'TEXT_BOX',
  DateBox: 'DATE_BOX',
  YearBox: 'YEAR_BOX',
  MonthBox: 'MONTH_BOX',
  MultiSelect: 'MULTI_SELECT_BOX',
  DateRange: 'RANGE_BOX',
  //MonthRange: 'RANGE_MONTH',
  //YearRange: 'RANGE_YEAR',
  DateRangeExtended: 'RANGE_BOX_EXTENDED',
  MonthRangeExtended: 'RANGE_BOX_EXTENDED_MONTH',
};
export const ControlTypeMenu: ISelectMenuItem[] = [
  { title: 'DropDown', value: 'DropDown' },
  { title: 'MultiSelect', value: 'MultiSelect' },
  { title: 'CheckBox', value: 'CheckBox' },
  { title: 'TextBox', value: 'TextBox' },
  { title: 'DateBox', value: 'DateBox' },
  { title: 'YearBox', value: 'YearBox' },
  { title: 'MonthBox', value: 'MonthBox' },
  //{ title: 'SwitchBox', value: 'SwitchBox' },
  { title: 'Date Range', value: 'DateRange' },
  { title: 'Date Range Extended', value: 'DateRangeExtended' },
  { title: 'Month Range', value: 'MonthRangeExtended' },
];
export const EnvironmentType: IEnumProps[] = [
  { text: 'Development', code: 'DEVELOPMENT', id: 0, icon: 'laptop-code', color: Colors.Primary_Brightblue },
  { text: 'Testing/UAT', code: 'TESTING', id: 1, icon: 'laptop-medical', color: Colors.Primary_VioletHighlight },
  { text: 'Staging', code: 'STAGING', id: 2, icon: 'laptop', color: Colors.Primary_OrangeHighlight },
  { text: 'Production', code: 'PRODUCTION', id: 3, icon: 'laptop', color: Colors.Primary_Pink },
  { text: 'Other', code: 'OTHER', id: 4, icon: 'laptop-code', color: Colors.Primary_TrueTeal },
  { text: 'Unknown', code: 'UNKNOWN', id: 5, icon: 'laptop-code', color: Colors.Primary_Outlinegray },
];
export const EnvironmentTypeMenu: ISelectMenuItem[] = [
  { title: 'Development', value: 'Development' },
  { title: 'Testing/UAT', value: 'Testing' },
  { title: 'Staging', value: 'Staging' },
  { title: 'Production', value: 'Production' },
  { title: 'Other', value: 'Other' },
  { title: 'Unknown', value: 'Unknown' },
];
export const DateRanges: IEnumProps[] = [
  { text: 'Last Month', code: 'LM', id: 0, icon: 'calendar-days', color: Colors.Primary_Brightblue },
  { text: 'Last 3 Months', code: 'L3', id: 1, icon: 'calendar-days', color: Colors.Primary_VioletHighlight },
  { text: 'Last 6 Months', code: 'L6', id: 2, icon: 'calendar-days', color: Colors.Primary_OrangeHighlight },
  { text: 'Last 12 Months', code: 'L12', id: 3, icon: 'calendar-days', color: Colors.Primary_Pink },
  { text: 'Current Year', code: 'CY', id: 4, icon: 'calendar-check', color: Colors.Primary_TrueTeal },
  { text: 'Previous Year', code: 'LY', id: 5, icon: 'calendar-minus', color: Colors.Primary_GoldenHighlight },
  { text: 'Custom Range', code: 'C', id: 5, icon: 'calendar-plus', color: Colors.AlertColor },
];
export const DateRangesEnum = {
  LastMonth: 'LM',
  Last3Months: 'L3',
  Last6Months: 'L6',
  Last12Months: 'L12',
  CurrentYear: 'CY',
  PreviousYear: 'LY',
  Custom: 'C',
};
export const RoleType: IEnumProps[] = [
  { text: 'Standard', code: 'STANDARD', id: 0, icon: 'user-lock', color: Colors.Primary_Brightblue },
  { text: 'Market Administrator', code: 'MARKET_ADMINISTRATOR', id: 1, icon: 'user-tie', color: Colors.Primary_Violet },
  {
    text: 'App Administrator',
    code: 'APPLICATION_OWNER',
    id: 4,
    icon: 'user-edit',
    color: Colors.Primary_OrangeHighlight,
  },
  { text: 'Administrator', code: 'ADMINISTRATOR', id: 2, icon: 'user-cog', color: Colors.Primary_Pink },
  { text: 'None', code: 'NONE', id: 3, icon: ['far', 'circle-xmark'], color: Colors.Primary_Outlinegray },
];
export const RoleTypeMenu: ISelectMenuItem[] = [
  { title: 'Standard', value: 'Standard' },
  { title: 'Market Administrator', value: 'MarketAdministrator' },
  { title: 'App Administrator', value: 'ApplicationOwner' },
  { title: 'Administrator', value: 'Administrator' },
  { title: 'None', value: 'None' },
];
export const UserTypeEnum = {
  Standard: 'STANDARD',
  ServiceAccount: 'SERVICE_ACCOUNT',
  Application: 'APPLICATION',
  Local: 'LOCAL',
  EnterpriseApplication: 'ENTERPRISE_APPLICATION',
};
export const UserType: IEnumProps[] = [
  { text: 'User Account', code: UserTypeEnum.Standard, id: 0, icon: ['far', 'user'], color: Colors.Primary_Brightblue },
  { text: 'Service Account', code: UserTypeEnum.ServiceAccount, id: 1, icon: 'user-cog', color: Colors.Primary_Violet },
  {
    text: 'Application',
    code: UserTypeEnum.Application,
    id: 2,
    icon: 'house-user',
    color: Colors.Primary_OrangeHighlight,
  },
  {
    text: 'Enterprise App',
    code: UserTypeEnum.EnterpriseApplication,
    id: 4,
    icon: 'warehouse',
    color: Colors.Primary_Pink,
  },
  { text: 'Local', code: UserTypeEnum.Local, id: 3, icon: 'street-view', color: Colors.Primary_TrueTeal },
];
export const UserTypeMenu: ISelectMenuItem[] = [
  { title: 'User Account', value: 'Standard' },
  { title: 'Service Account', value: 'ServiceAccount' },
  { title: 'Application', value: 'Application' },
  { title: 'Local', value: 'Local' },
  { title: 'Enterprise Application', value: 'EnterpriseApplication' },
];
export const GroupTypeEnum = {
  AdGroup: 'AD_GROUP',
  AdSyncGroup: 'AD_SYNC_GROUP',
  UserAccount: 'USER_ACCOUNT',
  Local: 'LOCAL',
};
export const GroupType: IEnumProps[] = [
  { text: 'AD Group', code: GroupTypeEnum.AdGroup, id: 0, icon: 'network-wired', color: Colors.Primary_Brightblue },
  {
    text: 'AD Sync Group',
    code: GroupTypeEnum.AdSyncGroup,
    id: 1,
    icon: 'network-wired',
    color: Colors.Primary_OrangeHighlight,
  },
  { text: 'User Account', code: GroupTypeEnum.UserAccount, id: 2, icon: 'user-cog', color: Colors.Primary_Violet },
  { text: 'Local', code: GroupTypeEnum.Local, id: 3, icon: 'street-view', color: Colors.Primary_TrueTeal },
];
export const GroupTypeMenu: ISelectMenuItem[] = [
  { title: 'AD Group', value: 'AdGroup' },
  { title: 'AD Sync Group', value: 'AdSyncGroup' },
  { title: 'User Account', value: 'UserAccount' },
];
export const SecurityLevel: IEnumProps[] = [
  { text: 'Group Level', code: 'GROUP', id: 0, icon: 'users', color: Colors.Primary_Lightblue },
  { text: 'User Level', code: 'USER', id: 1, icon: 'user', color: Colors.Primary_TrueTeal },
];
export enum EnumType {
  RoleType,
  SecurityLevel,
  EntityStatus,
  PageType,
  ProductType,
  NetGross,
  YesNo,
  DataSourceType,
  ControlType,
  EnvironmentType,
  DateRanges,
  UserType,
  GroupType,
  FilterScope,
}

const isPresent = (value: string) => value !== null && value !== undefined;
const defaultValue: IEnumProps = { color: Colors.Primary_Outlinegray, icon: 'square', text: '', code: '', id: 0 };
export const defaultTemplateValueOf = (type: EnumType): IEnumProps => {
  switch (type) {
    default:
      return defaultValueOf(type);
  }
};
export const valueOf = (field: string, type: EnumType): IEnumProps => {
  const defaultResult: IEnumProps = defaultValue;
  switch (type) {
    case EnumType.SecurityLevel:
      return isPresent(field) ? SecurityLevel.find((item) => item['code'] === field) || defaultResult : defaultResult;
    case EnumType.DateRanges:
      return isPresent(field) ? DateRanges.find((item) => item['code'] === field) || defaultResult : defaultResult;
    case EnumType.RoleType:
      return isPresent(field) ? RoleType.find((item) => item['code'] === field) || defaultResult : defaultResult;
    case EnumType.EntityStatus:
      return isPresent(field) ? EntityStatus.find((item) => item['code'] === field) || defaultResult : defaultResult;
    case EnumType.PageType:
      return isPresent(field) ? PageType.find((item) => item['code'] === field) || defaultResult : defaultResult;
    case EnumType.ProductType:
      return isPresent(field) ? ProductType.find((item) => item['code'] === field) || defaultResult : defaultResult;
    case EnumType.NetGross:
      return isPresent(field) ? NetGross.find((item) => item['code'] === field) || defaultResult : defaultResult;
    case EnumType.YesNo:
      return isPresent(field) ? YesNo.find((item) => item['code'] === field) || defaultResult : defaultResult;
    case EnumType.DataSourceType:
      return isPresent(field) ? DataSourceType.find((item) => item['code'] === field) || defaultResult : defaultResult;
    case EnumType.ControlType:
      return isPresent(field) ? ControlType.find((item) => item['code'] === field) || defaultResult : defaultResult;
    case EnumType.EnvironmentType:
      return isPresent(field) ? EnvironmentType.find((item) => item['code'] === field) || defaultResult : defaultResult;
    case EnumType.UserType:
      return isPresent(field) ? UserType.find((item) => item['code'] === field) || defaultResult : defaultResult;
    case EnumType.GroupType:
      return isPresent(field) ? GroupType.find((item) => item['code'] === field) || defaultResult : defaultResult;
    case EnumType.FilterScope:
      return isPresent(field) ? FilterScope.find((item) => item['code'] === field) || defaultResult : defaultResult;
    default:
      return defaultResult;
  }
};
export const defaultValueOf = (type: EnumType): IEnumProps => {
  switch (type) {
    case EnumType.DateRanges:
      return DateRanges[0];
    case EnumType.RoleType:
      return RoleType[0];
    case EnumType.EntityStatus:
      return EntityStatus[0];
    case EnumType.PageType:
      return PageType[0];
    case EnumType.ProductType:
      return ProductType[0];
    case EnumType.NetGross:
      return NetGross[0];
    case EnumType.YesNo:
      return YesNo[0];
    case EnumType.DataSourceType:
      return DataSourceType[2];
    case EnumType.ControlType:
      return ControlType[0];
    case EnumType.EnvironmentType:
      return EnvironmentType[0];
    case EnumType.UserType:
      return UserType[0];
    case EnumType.GroupType:
      return GroupType[0];
    case EnumType.FilterScope:
      return FilterScope[0];
    default:
      return defaultValue;
  }
};

export const EntityStatusEnum = {
  Active: 1,
  Inactive: 2,
  Disable: 4,
  All: 7,
};
export const EntityStatusType = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
  Disable: 'DISABLE',
};
export type IEntityStatusType = (typeof EntityStatusType)[keyof typeof EntityStatusType];
export enum EditionFlagEnum {
  CanView = 0,
  CanCreate = 1 << 0,
  CanUpdate = 1 << 1,
  CanDelete = 1 << 2,
  CanDuplicate = 1 << 3,
  All = ~(~0 << 4),
}

export const EditionFlags = {
  AllowEditionWithDuplicate:
    EditionFlagEnum.CanCreate |
    EditionFlagEnum.CanView |
    EditionFlagEnum.CanUpdate |
    EditionFlagEnum.CanDelete |
    EditionFlagEnum.CanDuplicate,
  AllowEdition:
    EditionFlagEnum.CanCreate | EditionFlagEnum.CanView | EditionFlagEnum.CanUpdate | EditionFlagEnum.CanDelete,
  AllowView: EditionFlagEnum.CanView,
};
export const asyncStates = {
  idle: 'idle',
  pending: 'pending',
  resolved: 'resolved',
  rejected: 'rejected',
} as const;
export type IAsyncState = keyof typeof asyncStates;
