import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function TradingOrderedSpendNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingOrderedSpend>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'OrderedSpend');
  const updatedState: ITradingOrderedSpend = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.Year = new Date().getFullYear().toString();
            updatedState.MOA = new Date().toString();
            updatedState.GrossSpend = 0;
            updatedState.NetSpend = 0;
            updatedState.Estimate = "TBD";
            updatedState.NoUnits = 1;
      setState(updatedState);
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.OrderedSpend.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/OrderedSpend/View.js"], "initNewTradingOrderedSpend", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="OrderedSpend" className="controls-container default block">
              <h3>Create a New <b>Ordered Spends</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="OrderedSpendCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="OrderedSpendLabelBlockedCode">Blocked</label>
				<input id="OrderedSpendCheckBlockedCode" defaultChecked name="OrderedSpendCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="AgencyId">Agency</label><select className="" id="OrderedSpendAgencyId" name="AgencyId" defaultValue={state.AgencyId}   required data-required-msg="Agency is required"  >{state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="ProductId">Product</label><select className="" id="OrderedSpendProductId" name="ProductId" defaultValue={state.ProductId}  >{state.ProductId && <option value={state.ProductId}>{state.ProductDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="ClientId">Client</label><select className="" id="OrderedSpendClientId" name="ClientId" defaultValue={state.ClientId}   required data-required-msg="Client is required"  >{state.ClientId && <option value={state.ClientId}>{state.ClientDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MarketId">Market</label><select className="" id="OrderedSpendMarketId" name="MarketId" defaultValue={state.MarketId}  >{state.MarketId && <option value={state.MarketId}>{state.MarketDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterMediaTypeId">Master Media Type</label><select className="" id="OrderedSpendMasterMediaTypeId" name="MasterMediaTypeId" defaultValue={state.MasterMediaTypeId}   required data-required-msg="Master Media Type is required"  >{state.MasterMediaTypeId && <option value={state.MasterMediaTypeId}>{state.MasterMediaTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterVendorOwnerId">Master Vendor Owner</label><select className="" id="OrderedSpendMasterVendorOwnerId" name="MasterVendorOwnerId" defaultValue={state.MasterVendorOwnerId}  >{state.MasterVendorOwnerId && <option value={state.MasterVendorOwnerId}>{state.MasterVendorOwnerDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaOwnerId">Media Owner</label><select className="" id="OrderedSpendMediaOwnerId" name="MediaOwnerId" defaultValue={state.MediaOwnerId}  >{state.MediaOwnerId && <option value={state.MediaOwnerId}>{state.MediaOwnerDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaVendorId">Media Vendor</label><select className="" id="OrderedSpendMediaVendorId" name="MediaVendorId" defaultValue={state.MediaVendorId}   required data-required-msg="Media Vendor is required"  >{state.MediaVendorId && <option value={state.MediaVendorId}>{state.MediaVendorDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="Estimate">Estimate</label><input  id="OrderedSpendEstimate" name="Estimate" type="text" defaultValue={state.Estimate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="MOA">MOA</label><input  required data-required-msg="MOA is required"  id="OrderedSpendMOA" name="MOA"  type="text" defaultValue={state.MOA} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="GrossSpend">Gross Spend</label><input  required data-required-msg="Gross Spend is required"  data-val-number="The field Gross Spend must be a number." id="OrderedSpendGrossSpend" name="GrossSpend" type="text" defaultValue={state.GrossSpend} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="NetSpend">Net Spend</label><input  required data-required-msg="Net Spend is required"  data-val-number="The field Net Spend must be a number." id="OrderedSpendNetSpend" name="NetSpend" type="text" defaultValue={state.NetSpend} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="NoUnits">#Units</label><input  required data-required-msg="#Units is required"  data-val-number="The field #Units must be a number." id="OrderedSpendNoUnits" name="NoUnits" type="text" defaultValue={state.NoUnits} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="Year">Year</label><input  required data-required-msg="Year is required"  data-val-number="The field Year must be a number." id="OrderedSpendYear" name="Year" type="text" defaultValue={state.Year} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="OrderedSpendSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarOrderedSpend">
                <div className="left">
                  <button id="cancelOrderedSpendButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveOrderedSpendButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingOrderedSpend {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  AgencyId?: string,
  AgencyDisplayValue?: string,
  ProductId?: string,
  ProductDisplayValue?: string,
  ClientId?: string,
  ClientDisplayValue?: string,
  MarketId?: string,
  MarketDisplayValue?: string,
  MasterMediaTypeId?: string,
  MasterMediaTypeDisplayValue?: string,
  MasterVendorOwnerId?: string,
  MasterVendorOwnerDisplayValue?: string,
  MediaOwnerId?: string,
  MediaOwnerDisplayValue?: string,
  MediaVendorId?: string,
  MediaVendorDisplayValue?: string,
  Estimate?: string,
  MOA?: string,
  GrossSpend?: number,
  NetSpend?: number,
  NoUnits?: number,
  Year?: string,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}


export function TradingOrderedSpendDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingOrderedSpend>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'OrderedSpend');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Trading", "OrderedSpend", id).then(result => {
        setState(result.Value[0] as ITradingOrderedSpend);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.OrderedSpend.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/OrderedSpend/View.js"
        ], "initDetailTradingOrderedSpend", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadTradingOrderedSpendState'] = (data) => {
    setState(data.Value[0] as ITradingOrderedSpend);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="OrderedSpend" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Ordered Spends: <span>{state.Display}</span><span id="OrderedSpendToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.AgencyId && <span className='element'><label>Agency: <label className="strong">{state.AgencyDisplayValue}</label></label></span>}
                  {state.ProductId && <span className='element'><label>Product: <label className="strong">{state.ProductDisplayValue}</label></label></span>}
                  {state.ClientId && <span className='element'><label>Client: <label className="strong">{state.ClientDisplayValue}</label></label></span>}
                </div>
                <div className='content-line'>
                  {state.MarketId && <span className='element'><label>Market: <label className="strong">{state.MarketDisplayValue}</label></label></span>}
                  {state.MasterMediaTypeId && <span className='element'><label>Master Media Type: <label className="strong">{state.MasterMediaTypeDisplayValue}</label></label></span>}
                  {state.MasterVendorOwnerId && <span className='element'><label>Master Vendor Owner: <label className="strong">{state.MasterVendorOwnerDisplayValue}</label></label></span>}
                </div>
                <div className='content-line'>
                  {state.MediaOwnerId && <span className='element'><label>Media Owner: <label className="strong">{state.MediaOwnerDisplayValue}</label></label></span>}
                  {state.MediaVendorId && <span className='element'><label>Media Vendor: <label className="strong">{state.MediaVendorDisplayValue}</label></label></span>}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="OrderedSpendCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="OrderedSpendLabelBlockedCode">Blocked</label>
				<input id="OrderedSpendCheckBlockedCode" defaultChecked name="OrderedSpendCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="AgencyId">Agency</label><select className="" id="OrderedSpendAgencyId" name="AgencyId" defaultValue={state.AgencyId}   required data-required-msg="Agency is required"  >{state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="ProductId">Product</label><select className="" id="OrderedSpendProductId" name="ProductId" defaultValue={state.ProductId}  >{state.ProductId && <option value={state.ProductId}>{state.ProductDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="ClientId">Client</label><select className="" id="OrderedSpendClientId" name="ClientId" defaultValue={state.ClientId}   required data-required-msg="Client is required"  >{state.ClientId && <option value={state.ClientId}>{state.ClientDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MarketId">Market</label><select className="" id="OrderedSpendMarketId" name="MarketId" defaultValue={state.MarketId}  >{state.MarketId && <option value={state.MarketId}>{state.MarketDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterMediaTypeId">Master Media Type</label><select className="" id="OrderedSpendMasterMediaTypeId" name="MasterMediaTypeId" defaultValue={state.MasterMediaTypeId}   required data-required-msg="Master Media Type is required"  >{state.MasterMediaTypeId && <option value={state.MasterMediaTypeId}>{state.MasterMediaTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterVendorOwnerId">Master Vendor Owner</label><select className="" id="OrderedSpendMasterVendorOwnerId" name="MasterVendorOwnerId" defaultValue={state.MasterVendorOwnerId}  >{state.MasterVendorOwnerId && <option value={state.MasterVendorOwnerId}>{state.MasterVendorOwnerDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaOwnerId">Media Owner</label><select className="" id="OrderedSpendMediaOwnerId" name="MediaOwnerId" defaultValue={state.MediaOwnerId}  >{state.MediaOwnerId && <option value={state.MediaOwnerId}>{state.MediaOwnerDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaVendorId">Media Vendor</label><select className="" id="OrderedSpendMediaVendorId" name="MediaVendorId" defaultValue={state.MediaVendorId}   required data-required-msg="Media Vendor is required"  >{state.MediaVendorId && <option value={state.MediaVendorId}>{state.MediaVendorDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="Estimate">Estimate</label><input  id="OrderedSpendEstimate" name="Estimate" type="text" defaultValue={state.Estimate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="MOA">MOA</label><input  required data-required-msg="MOA is required"  id="OrderedSpendMOA" name="MOA"  type="text" defaultValue={state.MOA} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="GrossSpend">Gross Spend</label><input  required data-required-msg="Gross Spend is required"  data-val-number="The field Gross Spend must be a number." id="OrderedSpendGrossSpend" name="GrossSpend" type="text" defaultValue={state.GrossSpend} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="NetSpend">Net Spend</label><input  required data-required-msg="Net Spend is required"  data-val-number="The field Net Spend must be a number." id="OrderedSpendNetSpend" name="NetSpend" type="text" defaultValue={state.NetSpend} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="NoUnits">#Units</label><input  required data-required-msg="#Units is required"  data-val-number="The field #Units must be a number." id="OrderedSpendNoUnits" name="NoUnits" type="text" defaultValue={state.NoUnits} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="Year">Year</label><input  required data-required-msg="Year is required"  data-val-number="The field Year must be a number." id="OrderedSpendYear" name="Year" type="text" defaultValue={state.Year} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="OrderedSpendSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="OrderedSpendSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="OrderedSpendSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarOrderedSpend">
                <div className="left">
                  <button id="cancelOrderedSpendButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveOrderedSpendButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}


