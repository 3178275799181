import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function TradingDealForecastNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingDealForecast>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'DealForecast');
  const updatedState: ITradingDealForecast = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.ForecastYear = new Date().getFullYear().toString();
            updatedState.BreakCriteria = 3;
      setState(updatedState);
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.DealForecast.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/DealForecast/View.js"], "initNewTradingDealForecast", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="DealForecast" className="controls-container default block">
              <h3>Create a New <b>Deal Forecasts</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="DealForecastCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="DealForecastLabelBlockedCode">Blocked</label>
				<input id="DealForecastCheckBlockedCode" defaultChecked name="DealForecastCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="AgencyProfileId">Agency</label><select className="" id="DealForecastAgencyProfileId" name="AgencyProfileId" defaultValue={state.AgencyProfileId}   required data-required-msg="Agency is required"  >{state.AgencyProfileId && <option value={state.AgencyProfileId}>{state.AgencyProfileDisplayValue}</option>}</select></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="BreakCriteria" className="required">Break Criteria</label>
      <select className="" id="DealForecastBreakCriteria" name="BreakCriteria" value={state.BreakCriteria} required data-required-msg="Break Criteria is required" >
        <option value="5">Week</option>
        <option value="3">Month</option>
        <option value="2">Quarter</option>
        <option value="1">Half Annual</option>
        <option value="0">Annual</option>
      </select>
    </div>
		<div className="col-md-2" ><label htmlFor="ForecastYear">Forecast Year</label><input  required data-required-msg="Forecast Year is required"  data-val-number="The field Forecast Year must be a number." id="DealForecastForecastYear" name="ForecastYear" type="text" defaultValue={state.ForecastYear} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="DealForecastSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarDealForecast">
                <div className="left">
                  <button id="cancelDealForecastButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveDealForecastButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingDealForecast {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  AgencyProfileId?: string,
  AgencyProfileDisplayValue?: string,
  BreakCriteria?: number,
  ForecastYear?: string,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealBreakTypeEnum { 'Week' = 5, 'Month' = 3, 'Quarter' = 2, 'Half Annual' = 1, 'Annual' = 0 }

export function TradingDealForecastDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingDealForecast>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'DealForecast');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Trading", "DealForecast", id).then(result => {
        setState(result.Value[0] as ITradingDealForecast);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.DealForecast.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/DealForecast/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.DealForecastStream.en.js"
        ,"Areas/Trading/Scripts/Resources/en/res.DealForecastDeal.en.js"
        ,"Areas/Trading/Scripts/DealForecastStream/View.js"
        ], "initDetailTradingDealForecast", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadTradingDealForecastState'] = (data) => {
    setState(data.Value[0] as ITradingDealForecast);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="DealForecast" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Deal Forecasts: <span>{state.Display}</span><span id="DealForecastToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.AgencyProfileId && <span className='element'><label>Agency: <label className="strong">{state.AgencyProfileDisplayValue}</label></label></span>}
                  <span className='element'><label>Break Criteria: <label className="strong">{state.BreakCriteria != undefined ? DealBreakTypeEnum[state.BreakCriteria] : ''}</label></label></span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="DealForecastCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="DealForecastLabelBlockedCode">Blocked</label>
				<input id="DealForecastCheckBlockedCode" defaultChecked name="DealForecastCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="AgencyProfileId">Agency</label><select className="" id="DealForecastAgencyProfileId" name="AgencyProfileId" defaultValue={state.AgencyProfileId}   required data-required-msg="Agency is required"  >{state.AgencyProfileId && <option value={state.AgencyProfileId}>{state.AgencyProfileDisplayValue}</option>}</select></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="BreakCriteria" className="required">Break Criteria</label>
      <select className="" id="DealForecastBreakCriteria" name="BreakCriteria" value={state.BreakCriteria} required data-required-msg="Break Criteria is required" >
        <option value="5">Week</option>
        <option value="3">Month</option>
        <option value="2">Quarter</option>
        <option value="1">Half Annual</option>
        <option value="0">Annual</option>
      </select>
    </div>
		<div className="col-md-2" ><label htmlFor="ForecastYear">Forecast Year</label><input  required data-required-msg="Forecast Year is required"  data-val-number="The field Forecast Year must be a number." id="DealForecastForecastYear" name="ForecastYear" type="text" defaultValue={state.ForecastYear} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="DealForecastSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="DealForecastSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="DealForecastSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarDealForecast">
                <div className="left">
                  <button id="cancelDealForecastButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveDealForecastButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="associationDealForecastDealForecastStream">
              <div className="k-block grid-details">
                <h2>Deal Forecast Streams</h2>
                <div id="DealForecastStreamGrid" className="grid-control"></div>
              </div>
            </div>
        </section>
      </section>
    </div>
  );
}


