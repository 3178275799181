import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function TradingForecastBreakNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingForecastBreak>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'ForecastBreak');
  const updatedState: ITradingForecastBreak = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.ForecastYear = new Date().getFullYear().toString();
            updatedState.StartDate = new Date().toString();
            updatedState.EndDate = new Date().toString();
            const date = new Date();
            const startDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 1));
            const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 3, -1));
            updatedState.StartDate = startDate.toISOString();
            updatedState.EndDate = endDate.toISOString();
            const difference = startDate.getTime() - endDate.getTime();
            const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
            updatedState.BreakNo = TotalDays / 7;
            updatedState.StatusSpend = 0;
            updatedState.BreakCriteria = 3;
      setState(updatedState);
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.ForecastBreak.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/ForecastBreak/View.js"], "initNewTradingForecastBreak", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="ForecastBreak" className="controls-container default block">
              <h3>Create a New <b>Forecast Breaks</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="ForecastBreakCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="ForecastBreakLabelBlockedCode">Blocked</label>
				<input id="ForecastBreakCheckBlockedCode" defaultChecked name="ForecastBreakCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="BreakCriteria" className="required">Break Criteria</label>
      <select className="" id="ForecastBreakBreakCriteria" name="BreakCriteria" value={state.BreakCriteria} required data-required-msg="Break Criteria is required" >
        <option value="5">Week</option>
        <option value="3">Month</option>
        <option value="2">Quarter</option>
        <option value="1">Half Annual</option>
        <option value="0">Annual</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Period" className="required">Period</label>
      <select className="" id="ForecastBreakPeriod" name="Period" value={state.Period} required data-required-msg="Period is required" >
        <option value="0">Budget</option>
        <option value="1">Q1</option>
        <option value="2">Q2</option>
        <option value="3">Q3</option>
        <option value="4">Full-Year</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="StatusSpend" className="required">Spend Status</label>
      <select className="" id="ForecastBreakStatusSpend" name="StatusSpend" value={state.StatusSpend} required data-required-msg="Spend Status is required" >
        <option value="0">Forecast</option>
        <option value="1">Planned</option>
        <option value="2">Ordered</option>
        <option value="3">Billed</option>
        <option value="4">Manual</option>
      </select>
    </div>
        <div className="clear"></div>
		<div className="col-md-2" ><label htmlFor="StartDate">Start Date</label><input  required data-required-msg="Start Date is required"  id="ForecastBreakStartDate" name="StartDate"  type="text" defaultValue={state.StartDate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="EndDate">End Date</label><input  required data-required-msg="End Date is required"  id="ForecastBreakEndDate" name="EndDate"  type="text" defaultValue={state.EndDate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="BreakNo">#</label><input  required data-required-msg="# is required"  data-val-number="The field # must be a number." id="ForecastBreakBreakNo" name="BreakNo" type="text" defaultValue={state.BreakNo} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="ForecastYear">Forecast Year</label><input  required data-required-msg="Forecast Year is required"  data-val-number="The field Forecast Year must be a number." id="ForecastBreakForecastYear" name="ForecastYear" type="text" defaultValue={state.ForecastYear} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="ForecastBreakSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarForecastBreak">
                <div className="left">
                  <button id="cancelForecastBreakButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveForecastBreakButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingForecastBreak {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  BreakCriteria?: number,
  Period?: number,
  StatusSpend?: number,
  StartDate?: string,
  EndDate?: string,
  BreakNo?: number,
  ForecastYear?: string,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealBreakTypeEnum { 'Week' = 5, 'Month' = 3, 'Quarter' = 2, 'Half Annual' = 1, 'Annual' = 0 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum SpendLoadStatusEnum { 'Forecast' = 0, 'Actuals' = 1, 'CXG' = 2 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum ForecastQuarterEnum { 'Budget' = 0, 'Q1' = 1, 'Q2' = 2, 'Q3' = 3, 'Full-Year' = 4 }

export function TradingForecastBreakDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingForecastBreak>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'ForecastBreak');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Trading", "ForecastBreak", id).then(result => {
        setState(result.Value[0] as ITradingForecastBreak);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.ForecastBreak.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/ForecastBreak/View.js"
        ], "initDetailTradingForecastBreak", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadTradingForecastBreakState'] = (data) => {
    setState(data.Value[0] as ITradingForecastBreak);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="ForecastBreak" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Forecast Breaks: <span>{state.Display}</span><span id="ForecastBreakToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  <span className='element'><label>Break Criteria: <label className="strong">{state.BreakCriteria != undefined ? DealBreakTypeEnum[state.BreakCriteria] : ''}</label></label></span>
                  <span className='element'><label>Start Date: <label className="strong">{formatDate(state.StartDate)}</label></label></span>
                  <span className='element'><label>End Date: <label className="strong">{formatDate(state.EndDate)}</label></label></span>
                </div>
                <div className='content-line'>
                  <span className='element'><label>#: <label className="strong">{state.BreakNo}</label></label></span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="ForecastBreakCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="ForecastBreakLabelBlockedCode">Blocked</label>
				<input id="ForecastBreakCheckBlockedCode" defaultChecked name="ForecastBreakCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="BreakCriteria" className="required">Break Criteria</label>
      <select className="" id="ForecastBreakBreakCriteria" name="BreakCriteria" value={state.BreakCriteria} required data-required-msg="Break Criteria is required" >
        <option value="5">Week</option>
        <option value="3">Month</option>
        <option value="2">Quarter</option>
        <option value="1">Half Annual</option>
        <option value="0">Annual</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Period" className="required">Period</label>
      <select className="" id="ForecastBreakPeriod" name="Period" value={state.Period} required data-required-msg="Period is required" >
        <option value="0">Budget</option>
        <option value="1">Q1</option>
        <option value="2">Q2</option>
        <option value="3">Q3</option>
        <option value="4">Full-Year</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="StatusSpend" className="required">Spend Status</label>
      <select className="" id="ForecastBreakStatusSpend" name="StatusSpend" value={state.StatusSpend} required data-required-msg="Spend Status is required" >
        <option value="0">Forecast</option>
        <option value="1">Planned</option>
        <option value="2">Ordered</option>
        <option value="3">Billed</option>
        <option value="4">Manual</option>
      </select>
    </div>
        <div className="clear"></div>
		<div className="col-md-2" ><label htmlFor="StartDate">Start Date</label><input  required data-required-msg="Start Date is required"  id="ForecastBreakStartDate" name="StartDate"  type="text" defaultValue={state.StartDate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="EndDate">End Date</label><input  required data-required-msg="End Date is required"  id="ForecastBreakEndDate" name="EndDate"  type="text" defaultValue={state.EndDate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="BreakNo">#</label><input  required data-required-msg="# is required"  data-val-number="The field # must be a number." id="ForecastBreakBreakNo" name="BreakNo" type="text" defaultValue={state.BreakNo} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="ForecastYear">Forecast Year</label><input  required data-required-msg="Forecast Year is required"  data-val-number="The field Forecast Year must be a number." id="ForecastBreakForecastYear" name="ForecastYear" type="text" defaultValue={state.ForecastYear} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="ForecastBreakSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="ForecastBreakSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="ForecastBreakSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarForecastBreak">
                <div className="left">
                  <button id="cancelForecastBreakButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveForecastBreakButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}


