import React from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../../helpers/Utils';

export function TradingMediaOwnerProfilePage(): React.ReactElement {
  const currentSecurity = useSecurity('Trading', 'MediaOwnerProfile');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.MediaOwnerProfile.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/MediaOwnerProfile/Index.js"], "initTradingMediaOwnerProfile", {});
    }
  }, []);
  return (
    <div className="container-fluid">
      <header className="complete-grid">
        <div id="gridFilter1">
          <div id="gridfilter-toolbar"></div>
        </div>
      </header>
      <section id="gridContainer"></section>
      <ul id="gridContainer-menu"></ul>
    </div>
  );
}
