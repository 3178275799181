import { IMessageDataProps } from '../../components/layout/elements/Message';
import { orderBy } from '@progress/kendo-data-query';
import * as navActions from '../actions/navActions';

export interface NavigationState {
  sidebarOpen: boolean;
  notificationOpen: boolean;
  messageOpen: boolean;
  settingsOpen: boolean;
  theme: string;
  notifications: IMessageDataProps[];
  pendingmessages: number;
}
const initialState: NavigationState = {
  sidebarOpen: false,
  notificationOpen: false,
  messageOpen: false,
  settingsOpen: false,
  theme: localStorage.getItem('currentTheme') ?? 'light',
  notifications: [],
  pendingmessages: 0,
};
export function navReducer(state = initialState, action: navActions.NavigationActionTypes): NavigationState {
  switch (action.type) {
    case navActions.CHANGE_THEME:
      localStorage.setItem('currentTheme', action.value);
      return { ...state, theme: action.value ?? 'light' };

    case navActions.TOGGLE_SIDEBAR:
      const sideOpen = state.sidebarOpen;
      return { ...state, sidebarOpen: !sideOpen };

    case navActions.CLOSE_SIDEBAR:
      return { ...state, sidebarOpen: false };

    case navActions.TOGGLE_NOTIFICATIONS:
      const notificationOpen = state.notificationOpen;
      return { ...state, notificationOpen: !notificationOpen };

    case navActions.TOGGLE_MESSAGES:
      const messageOpen = state.messageOpen;
      return { ...state, messageOpen: !messageOpen };

    case navActions.SET_PENDINGMESSAGES:
      return { ...state, pendingmessages: action.value };

    case navActions.CLOSE_NOTIFICATIONS:
      return { ...state, notificationOpen: false };

    case navActions.CLOSE_MESSAGES:
      return { ...state, messageOpen: false };

    case navActions.ADD_NOTIFICATION:
      const newNotification: IMessageDataProps = {
        id: action.data.id,
        type: action.data.type,
        status: action.data.status,
        createOn: action.data.createOn,
        percent: action.data.percent,
        text: action.data.text,
        dismiss: false,
      };
      const added_notifications = state.notifications.filter((a) => a.id !== action.data.id).concat(newNotification);
      const added_result = orderBy(added_notifications, [{ field: 'dateOccurred', dir: 'desc' }]);
      return { ...state, notifications: added_result };

    case navActions.DISMISS_NOTIFICATION:
      const update_notifications = state.notifications.filter((a) => a.id !== action.id);
      const update_result = orderBy(update_notifications, [{ field: 'dateOccurred', dir: 'desc' }]);
      return { ...state, notifications: update_result };

    case navActions.PURGE_NOTIFICATIONS:
      return { ...state, notifications: [] };

    case navActions.TOGGLE_SETTINGS:
      const settingsOpen = state.settingsOpen;
      return { ...state, settingsOpen: !settingsOpen };

    case navActions.CLOSE_SETTINGS:
      return { ...state, settingsOpen: false };
    default:
      return state;
  }
}
export default navReducer;
