import { ISecurityProfileProps } from '../../components/services/GraphQLShared';
import { apiConfig } from '../../config/Config';
import { getContextDetails, setRole } from '../../services/ApiServices';

export const actions = {
  RESET_SECURITY_PROFILE: 'RESET_SECURITY_PROFILE',
  GET_SECURITY_PROFILE_PENDING: 'GET_SECURITY_PROFILE_PENDING',
  GET_SECURITY_PROFILE_RESOLVED: 'GET_SECURITY_PROFILE_RESOLVED',
  GET_SECURITY_PROFILE_REJECTED: 'GET_SECURITY_PROFILE_REJECTED',
} as const;

export const resetSecurityProfile = () => ({ type: actions.RESET_SECURITY_PROFILE });

export const getSecurityProfile = (getAccessToken) => async (dispatch) => {
  //dispatch({ type: actions.GET_SECURITY_PROFILE_PENDING });
  try {
    const accessToken2 = await getAccessToken(apiConfig.scopes);
    const data = await getContextDetails(accessToken2);
    const profile: ISecurityProfileProps = JSON.parse(data);
    window['setUser'](profile);
    dispatch({ type: actions.GET_SECURITY_PROFILE_RESOLVED, profile });
  } catch (error) {
    dispatch({ type: actions.GET_SECURITY_PROFILE_REJECTED, error });
  }
};

export const setSecurityProfile = (id) => async (dispatch) => {
  //dispatch({ type: actions.GET_SECURITY_PROFILE_PENDING });
  try {
    await setRole(id);
    //const profile: ISecurityProfileProps = JSON.parse(data);
    //dispatch({ type: actions.GET_SECURITY_PROFILE_RESOLVED, profile });
  } catch (error) {
    dispatch({ type: actions.GET_SECURITY_PROFILE_REJECTED, error });
  }
};
