import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
import { appendScriptsCallback, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
import { getEntityDetails } from '../../../../services/ApiServices';

export function SecurityUserNewPage(): React.ReactElement {
  const [state] = useState<ISecurityUser>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Security', 'User');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(
        [
          'Areas/Admin/Scripts/Resources/en/res.User.en.js',
          'Areas/Admin/Scripts/Resources/en/Types.en.js',
          'Areas/Admin/Scripts/Enums.js',
          'Areas/Admin/Scripts/AdminShare.js',
          'Areas/Admin/Scripts/User/View.js',
        ],
        'initNewSecurityUser',
        { viewmodel: state, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="User" className="controls-container default block">
              <h3>
                Create a New <b>User</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="UserCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="UserLabelBlockedCode">Blocked</label>
                  <input id="UserCheckBlockedCode" defaultChecked name="UserCheckBlockedCode" type="checkbox" />
                </div>
                <div className="clear"></div>
                <div className="field ">
                  <label htmlFor="LoginName">Login</label>
                  <input
                    data-required-msg="Login is required"
                    id="UserLoginName"
                    max="128"
                    min="1"
                    name="LoginName"
                    required
                    type="text"
                    defaultValue={state.LoginName}
                    maxLength={128}
                    pattern="^\S+"
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <h4>Name</h4>
                <div className="col-md-4">
                  <label htmlFor="FirstName">First Name</label>
                  <input
                    data-required-msg="First Name is required"
                    id="UserFirstName"
                    min="1"
                    name="FirstName"
                    required
                    type="text"
                    defaultValue={state.FirstName}
                    maxLength={250}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="MiddleName">Middle Name</label>
                  <input
                    id="UserMiddleName"
                    name="MiddleName"
                    type="text"
                    defaultValue={state.MiddleName}
                    maxLength={250}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="LastName">Last Name</label>
                  <input
                    id="UserLastName"
                    name="LastName"
                    type="text"
                    defaultValue={state.LastName}
                    maxLength={250}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="Email">Email</label>
                  <input
                    id="UserEmail"
                    name="Email"
                    type="text"
                    defaultValue={state.Email}
                    maxLength={250}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="field textareafield">
                  <label htmlFor="Description">Description</label>
                  <div>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="UserDescription"
                      name="Description"
                      maxLength={2147483647}
                      rows={5}
                      defaultValue={state.Description}
                    ></textarea>
                  </div>
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarUser">
                <div className="left">
                  <button id="cancelUserButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveUserButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

export interface ISecurityUser {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: 1;
    Source?: 0;
  };
  LoginName?: string;
  FirstName?: string;
  Description?: string;
  Email?: string;
  MiddleName?: string;
  LastName?: string;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

export function SecurityUserDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ISecurityUser>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Master', 'User');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Security', 'User', id).then((result) => {
        setState(result.Value[0] as ISecurityUser);
        appendScriptsCallback(
          [
            'Areas/Admin/Scripts/Resources/en/res.User.en.js',
            'Areas/Admin/Scripts/Resources/en/Types.en.js',
            'Areas/Admin/Scripts/Enums.js',
            'Areas/Admin/Scripts/AdminShare.js',
            'Areas/Admin/Scripts/User/View.js',
            'Areas/Admin/Scripts/Resources/en/res.GroupUser.en.js',
            'Areas/Admin/Scripts/GroupUser/ViewUser.js',
          ],
          'initDetailSecurityUser',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadSecurityUserState'] = (data) => {
    setState(data.Value[0] as ISecurityUser);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="User" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      User: <span>{state.Display}</span>
                      <span id="UserToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }}></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line"></div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="UserCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="UserLabelBlockedCode">Blocked</label>
                  <input id="UserCheckBlockedCode" defaultChecked name="UserCheckBlockedCode" type="checkbox" />
                </div>
                <div className="clear"></div>
                <div className="field ">
                  <label htmlFor="LoginName">Login</label>
                  <input
                    data-required-msg="Login is required"
                    id="UserLoginName"
                    max="128"
                    min="1"
                    name="LoginName"
                    required
                    type="text"
                    defaultValue={state.LoginName}
                    maxLength={128}
                    pattern="^\S+"
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <h4>Name</h4>
                <div className="col-md-4">
                  <label htmlFor="FirstName">First Name</label>
                  <input
                    data-required-msg="First Name is required"
                    id="UserFirstName"
                    min="1"
                    name="FirstName"
                    required
                    type="text"
                    defaultValue={state.FirstName}
                    maxLength={250}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="MiddleName">Middle Name</label>
                  <input
                    id="UserMiddleName"
                    name="MiddleName"
                    type="text"
                    defaultValue={state.MiddleName}
                    maxLength={250}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="LastName">Last Name</label>
                  <input
                    id="UserLastName"
                    name="LastName"
                    type="text"
                    defaultValue={state.LastName}
                    maxLength={250}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="Email">Email</label>
                  <input
                    id="UserEmail"
                    name="Email"
                    type="text"
                    defaultValue={state.Email}
                    maxLength={250}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="field textareafield">
                  <label htmlFor="Description">Description</label>
                  <div>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="UserDescription"
                      name="Description"
                      maxLength={2147483647}
                      rows={5}
                      defaultValue={state.Description}
                    ></textarea>
                  </div>
                </div>
                <div className="clear"></div>
                
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="UserSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select className="" id="UserSourceStatus" name="SourceStatus" value={state.LoadingInfo.SourceStatus}>
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select className="" id="UserSource" name="Source" value={state.LoadingInfo.Source}>
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarUser">
                <div className="left">
                  <button id="cancelUserButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveUserButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
          <div id="associationUserGroupUser">
            <div className="k-block grid-details">
              <h2>Groups</h2>
              <div id="GroupUserGrid" className="grid-control"></div>
            </div>
          </div>
        </section>
      </section>
      <div id="CheckDataContainer">
        <h1>Check Data Permissions</h1>
        <div className="field shortcontrol-container">
          <label className="required">Select Role</label>
          <input id="inputRole" />
        </div>
        <div className="field textareafield">
          <label>Resolved Data Permissions</label>
          <textarea className="k-input k-textbox" style={{ height: '250px' }} id="DataPermissionList"></textarea>
        </div>
        <div className="field textareafield">
          <label>Applied Data Permissions</label>
          <textarea className="k-input k-textbox" style={{ height: '350px' }} id="DataPermissionResolve"></textarea>
        </div>
      </div>
    </div>
  );
}
