import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../helpers/Utils';

export function BulkPanelPage(): React.ReactElement {
  const currentSecurity = useSecurity('Trading', 'AgencyProfile');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(['Report.custom.js',
          'Areas/Trading/Scripts/Resources/en/res.AgencyProfile.en.js',
          'Areas/Trading/Scripts/Resources/en/res.AgencyProfilePeriod.en.js',
          'Areas/Trading/Scripts/Resources/en/res.BilledSpend.en.js',
          'Areas/Trading/Scripts/Resources/en/res.OrderedSpend.en.js',
          'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/AgencyProfile/BulkPanel.custom.js'],
        'initNewCanadaBulkPanel', {});
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div id="main-section" className="col-md-12">
          <div id="main-section-header" className="row">
            <div id="BulkPanelToolbar" className="main-toolbar"></div>
          </div>
          <div id="main-section-content" className="row process-section">
              <div className="col-md-6">
                <label htmlFor="optional" id="BulkPanel">Forecast Periods</label>
                <br />
                <select id="optional" title="Available"></select>
                <div className="process-log" style={{ marginTop: '10px' }} >
                  <h4>Console log</h4>
                  <div className="console"></div>
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="optional">Selected</label>
                <br />
                <select id="selected" title="Selected"></select>
                <div style={{ marginTop: '10px', display: 'none' }} className="forecastAction option-list">
                  <label htmlFor="operations" style={{ lineHeight: '2em', fontWeight: 'bold' }} >Actions & Settings</label>
                  <ul id="operations">
                    <li><label>Populate With Actuals</label><input type="checkbox" id="cbox5" aria-label="PopulateWithActuals" /><p>Reconciles the most up-to-date actual spends from the daily loads</p></li>
                    <li><label>Apply Defaults</label><input type="checkbox" id="cbox4" aria-label="ApplyDefaults" /><p>Restores the default comissions and fees from the client profile and recalculates the revenues, overwriting those values manually modified</p></li>
                    <li><label>Reconcile Revenue Forecast</label><input type="checkbox" id="cbox7" aria-label="ReconcileRevenueStreams" /><p>Reloads into the [Current Period] the most up-to-date spends from the realted revenue forecast</p></li>
                    <li><label>Calculate Forecast Spends</label><input type="checkbox" id="cbox9" aria-label="CalculateForecastSpends" /><p>Recalculate Forecast revenue applying commisions and fees</p></li>
                    <li><label>Calculate Network Spends</label><input type="checkbox" id="cbox6" aria-label="CalculateNetworkSpends" /><p>Allocates the deal commitments into the [Current Period] spend and calculates the total allocated/network spend of each deal</p></li>
                    <li><label>Apply Prior Period Share</label><input type="checkbox" id="cbox8" aria-label="ApplyPriorShare" /><p>Applies share percentages from the prior period to the [Current Period] and automatically calculates, distributes, and populates all allocable forecast spend to media owners according to their respective share percent</p></li>
                    <li><label>Clean Spends</label><input type="checkbox" id="cbox1" aria-label="CleanSpends" /><p>Updates to zero the forecast spends of the [Current Period]</p></li>
                    <li><label>Reset Spends</label><input type="checkbox" id="cbox2" aria-label="ResetSpends" /><p>Deletes existing spends; you can start your forecast from the scratch</p></li>
                  </ul>
                </div>
                <section className="button-toolbar">
                  <div className="left"><button id="cancelBulkPanel" type="button" className="k-button button" title="Cancel">Cancel</button></div>
                  <div className="right"><button id="acceptBulkPanel" type="button" className="k-button button" title="Execute">Execute</button></div>
                </section>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}
