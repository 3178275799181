import React from 'react';
import { Route } from 'react-router';
import { Navigate } from 'react-router-dom';
import { DealForecastReportPage } from '../components/pages/custom/DealForecastReportPage';
import { RevenueForecastReportPage } from '../components/pages/custom/RevenueForecastReportPage';
import { DealCommitmentReportPage } from '../components/pages/custom/DealCommitmentReportPage';
import { BulkPanelPage } from '../components/pages/custom/BulkPanelPage';
import { IndexRevenuePeriodPage, IndexDealPeriodPage } from '../components/pages/custom/IndexPeriod';
import { TradingRevenueForecastCustomPage } from '../components/pages/Trading/RevenueForecast/IndexPage.custom';
import { TradingRevenueForecastManualCustomPage } from '../components/pages/Trading/RevenueForecast/IndexManualPage.custom';
import { TradingDealForecastCustomPage } from '../components/pages/Trading/DealForecast/IndexPage.custom';
import { TradingForecastAuditTrailRevenueCustomPage, TradingForecastAuditTrailDealCustomPage } from '../components/pages/Trading/ForecastAuditTrail/IndexPage.custom';
import { TradingAgencyProfileRevenueCustomPage, TradingAgencyProfileDealCustomPage } from '../components/pages/Trading/AgencyProfile/IndexPage.custom';
import { TradingAgencyProfileNewCustomPage, TradingAgencyProfileDetailCustomPage } from '../components/pages/Trading/AgencyProfile/ViewPage.custom';
export const createRoutesCustom = (isAuthenticated) => (
  <>
    <Route path="/Trading/DealForecast/:area" element={isAuthenticated ? <DealForecastReportPage /> : <Navigate to="/" />} />
    <Route path="/Trading/RevenueForecast/:area" element={isAuthenticated ? <RevenueForecastReportPage /> : <Navigate to="/" />} />
    <Route path="/Trading/DealCommitment/:area" element={isAuthenticated ? <DealCommitmentReportPage /> : <Navigate to="/" />} />
    <Route path="/Trading/AgencyProfile/BulkPanel" element={isAuthenticated ? <BulkPanelPage /> : <Navigate to="/" />} />
    <Route path="/Trading/RevenueForecast/Index" element={isAuthenticated ? <TradingRevenueForecastCustomPage /> : <Navigate to="/" />} />
    <Route path="/Trading/RevenueForecast/IndexManual" element={isAuthenticated ? <TradingRevenueForecastManualCustomPage /> : <Navigate to="/" />} />
    <Route path="/Trading/DealForecast/Index" element={isAuthenticated ? <TradingDealForecastCustomPage /> : <Navigate to="/" />} />
    <Route path="/Trading/ForecastAuditTrail/IndexRevenue" element={isAuthenticated ? <TradingForecastAuditTrailRevenueCustomPage /> : <Navigate to="/" />} />
    <Route path="/Trading/ForecastAuditTrail/IndexDeal" element={isAuthenticated ? <TradingForecastAuditTrailDealCustomPage /> : <Navigate to="/" />} />
    <Route path="/Trading/AgencyProfile/IndexRevenue" element={isAuthenticated ? <TradingAgencyProfileRevenueCustomPage /> : <Navigate to="/" />} />
    <Route path="/Trading/AgencyProfile/IndexDeal" element={isAuthenticated ? <TradingAgencyProfileDealCustomPage /> : <Navigate to="/" />} />
    <Route path="/Trading/AgencyProfile/New" element={isAuthenticated ? <TradingAgencyProfileNewCustomPage /> : <Navigate to="/" />} />
    <Route path="/Trading/AgencyProfile/IndexRevenuePeriod" element={isAuthenticated ? <IndexRevenuePeriodPage /> : <Navigate to="/" />} />
    <Route path="/Trading/AgencyProfile/IndexDealPeriod" element={isAuthenticated ? <IndexDealPeriodPage /> : <Navigate to="/" />} />
    <Route path="/Trading/AgencyProfile/:id" element={isAuthenticated ? <TradingAgencyProfileDetailCustomPage /> : <Navigate to="/" />} />
  </>
);
