import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScripts } from '../../helpers/Utils';

export function DocumentFilePage(): React.ReactElement {
  const currentSecurity = useSecurity('Shared', 'DocumentFile');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScripts(['DocumentFile.js']);
    }
  }, []);
  return (
    <div className="container-fluid">
      <header className="complete-grid">
        <div id="gridFilter1">
          <div id="gridfilter-toolbar"></div>
        </div>
      </header>
      <section id="gridContainer"></section>
      <ul id="gridContainer-menu"></ul>
    </div>
  );
}
