import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { useParams } from 'react-router-dom';
import { appendScriptsCallback } from '../../helpers/Utils';

export function DealCommitmentReportPage(): React.ReactElement {
  const { area } = useParams();
  const currentSecurity = useSecurity('Trading', 'DealCommitment');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(['Report.custom.js',
          'Areas/Master/Scripts/Resources/en/res.Client.en.js',
          'Areas/Master/Scripts/Resources/en/res.MasterClient.en.js',
          'Areas/Master/Scripts/Resources/en/res.MediaOwner.en.js',
          'Areas/Trading/Scripts/Resources/en/res.AgencyProfile.en.js',
          'Areas/Trading/Scripts/Resources/en/res.AgencyProfilePeriod.en.js',
          'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/DealCommitment/DealManagerReports/' + area + 'View.custom.js'],
        'initNewCanadaReport', { area: area });
    }
  }, [area]);

  function reportName(area) {
    switch (area) {
      case 'DealAnalysis':
        return 'Deal Pacing Analysis';
      case 'ProjectionCrosstab':
        return 'Projection Crosstab';
      case 'SpendsVsForecast':
        return 'Total Media Spend vs Forecasts';
      case 'DealForecasts':
        return 'Deal Summary';
      case 'ForecastAuditTrail':
        return 'Forecast Audit Trail';
      case 'ForecastBenchmarking':
        return 'Forecast Benchmarking Crosstab';
      case 'AgencyForecasts':
        return 'Agency Forecasts Crosstab';
      case 'DealManager':
        return 'Deal Pacing';
      default:
        return area;
    }
  }

  return (
    <div className="panelcontent-section">
      <div id="sidebar-section" style={{ display: 'none' }}>
        <i className="fas fa-bars" id="sidebarBar"></i>
        <div className="k-content">
          <h3>{reportName(area)}</h3>
          {['ProjectionCrosstab'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Forecast Projected</label>
            <input id="inputSpendsStatus" />
          </div>}
          {['ProjectionCrosstab', 'SpendsVsForecast', 'DealForecasts', 'ForecastBenchmarking','AgencyForecasts'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Year</label>
            <input id="inputYear" />
          </div>}
          {['DealForecasts', 'ForecastBenchmarking', 'AgencyForecasts'].includes(area ? area.toString() : '') && <div className="field shortcontrol-container">
            <label>Forecast Version</label>
            <input id="inputVersion" />
          </div>}
          {['ForecastBenchmarking'].includes(area ? area.toString() : '') && <div className="field shortcontrol-container">
            <label>Dollars</label>
            <input id="inputDealDollar" />
          </div>}
          {['DealForecasts', 'AgencyForecasts'].includes(area ? area.toString() : '') && <div className="field shortcontrol-container">
            <label>Dimension</label>
            <input id="inputDimension" />
          </div>}
          <div className="field fullwidth-container">
            <label>Agency</label>
            <input id="inputAgency" />
          </div>
          {['DealAnalysis', 'ProjectionCrosstab', 'DealForecasts', 'ForecastAuditTrail', 'ForecastBenchmarking', 'AgencyForecasts','DealManager'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Master Client</label>
            <input id="inputMasterClient" />
          </div>}
          {['ForecastAuditTrail'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Year</label>
            <input id="inputForecastYear" />
          </div>}
          {['DealAnalysis', 'ProjectionCrosstab', 'DealForecasts', 'ForecastBenchmarking', 'AgencyForecasts', 'DealManager'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Media Type</label>
            <input id="inputMediaType" />
          </div>}
          {['DealAnalysis'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Agency Target</label>
            <input id="inputAgencyTarget" />
          </div>}
          {['ProjectionCrosstab', 'DealForecasts', 'ForecastBenchmarking', 'AgencyForecasts', 'DealManager'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Media Owner</label>
            <input id="inputMediaOwner" />
          </div>}
          {['DealForecasts', 'ForecastAuditTrail', 'AgencyForecasts'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Created By</label>
            <input id="inputCreatedBy" />
          </div>}
          {['DealForecasts', 'ForecastAuditTrail', 'AgencyForecasts'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Modified By</label>
            <input id="inputModifiedBy" />
          </div>}
          {['DealAnalysis', 'ProjectionCrosstab', 'SpendsVsForecast', 'DealForecasts', 'ForecastBenchmarking', 'AgencyForecasts', 'DealManager'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Gross/Net</label>
            <input id="inputGrossNet" />
          </div>}
          {['DealAnalysis', 'ProjectionCrosstab', 'DealForecasts', 'ForecastBenchmarking', 'AgencyForecasts', 'DealManager'].includes(area ? area.toString() : '') && <div className="field shortcontrol-container">
            <label>Show Medias</label>
            <input id="inputGroupByMedia" />
          </div>}
          {['DealForecasts'].includes(area ? area.toString() : '') && <div className="field shortcontrol-container">
            <label>Show Agencies</label>
            <input id="inputGroupByAgency" />
          </div>}
          {['DealForecasts', 'ForecastBenchmarking'].includes(area ? area.toString() : '') && <div className="field shortcontrol-container">
            <label>Show Clients</label>
            <input id="inputGroupByClient" />
          </div>}
          <section className="button-toolbar">
            <div className="left"><button id="exportFilterButton" type="button" className="k-button" title="Export">Export</button></div>
            <div className="right"><button id="applyFilterButton" type="button" className="k-button k-primary" title="Go">Go!</button></div>
          </section>
        </div>
      </div>
      <div id="content-section">
        <i className="fas fa-bars" id="contentBar"></i>
        {area == 'DealAnalysis' && <div id="DealAnalysisViewReportContainer" className="k-content">
          <div>
            <h3>Deal Pacing Analysis Chart</h3>
            <div style={{ height: '600px', overflowY: 'auto' }}>
              <div id="DealAnalysisViewChartContainer"></div>
            </div>
          </div>
          <div>
            <h3>Deal Pacing Analysis Crosstab</h3>
            <div id="DealAnalysisViewSpreadsheetContainer" className="spreadsheet-container"></div>
          </div>
        </div>}
        {area == 'ProjectionCrosstab' && <div id="DealForecastsViewReportContainer" className="k-content">
          <h3>Projection Crosstab</h3>
          <div id="ProjectionCrosstabViewSpreadsheetContainer" className="spreadsheet-container"></div>
        </div>}
        {area == 'SpendsVsForecast' && <div id="DealForecastsViewReportContainer" className="k-content">
          <h3>Total Media Spend vs Forecasts</h3>
          <div id="SpendsVsForecastViewSpreadsheetContainer" className="spreadsheet-container"></div>
        </div>}
        {area == 'DealForecasts' && <div id="DealForecastsViewReportContainer" className="k-content">
          <h3 id="reportheader">Forecasts</h3>
          <div id="DealForecastsViewSpreadsheetContainer" className="spreadsheet-container"></div>
        </div>}
        {area == 'ForecastAuditTrail' && <div id="ForecastAuditTrailViewReportContainer" className="k-content">
          <h3>Forecast Audit Trail</h3>
          <div id="ForecastAuditTrailViewSpreadsheetContainer" className="spreadsheet-container"></div>
        </div>}
        {area == 'ForecastBenchmarking' && <div id="ForecastBenchmarkingReportContainer" className="k-content">
          <h3>Forecast Benchmarking Crosstab</h3>
          <div id="ForecastBenchmarkingViewSpreadsheetContainer" className="spreadsheet-container"></div>
        </div>}
        {area == 'AgencyForecasts' && <div id="ForecastsViewReportContainer" className="k-content">
          <h3>Agency Forecasts Crosstab</h3>
          <div id="AgencyForecastsViewSpreadsheetToolbarContainer"></div>
          <div id="AgencyForecastsViewSpreadsheetContainer"></div>
        </div>}
        {area == 'DealManager' && <div id="DealManagerViewReportContainer" className="k-content">
          <div>
            <h3>Deal Pacing Chart</h3>
            <div style={{ height: '400px', overflowY: 'auto' }}>
              <div id="DealManagerViewChartContainer"></div>
            </div>
          </div>
          <div>
            <h3>Deal Pacing Crosstab</h3>
            <div id="DealManagerViewSpreadsheetContainer" className="spreadsheet-container"></div>
          </div>
        </div>}
      </div>
    </div>
  );
}
