import React from 'react';
import { Route } from 'react-router';
import { Navigate } from 'react-router-dom';
import { MasterClientPage } from '../components/pages/Master/Client/IndexPage';
import { MasterClientNewPage, MasterClientDetailPage } from '../components/pages/Master/Client/ViewPage';
import { MasterCountryPage } from '../components/pages/Master/Country/IndexPage';
import { MasterCountryNewPage, MasterCountryDetailPage } from '../components/pages/Master/Country/ViewPage';
import { MasterCompanyPage } from '../components/pages/Master/Company/IndexPage';
import { MasterCompanyNewPage, MasterCompanyDetailPage } from '../components/pages/Master/Company/ViewPage';
import { MasterMediaVendorPage } from '../components/pages/Master/MediaVendor/IndexPage';
import { MasterMediaVendorNewPage, MasterMediaVendorDetailPage } from '../components/pages/Master/MediaVendor/ViewPage';
import { TradingAgencyProfilePage } from '../components/pages/Trading/AgencyProfile/IndexPage';
import { TradingAgencyProfileNewPage, TradingAgencyProfileDetailPage } from '../components/pages/Trading/AgencyProfile/ViewPage';
import { TradingClientProfilePage } from '../components/pages/Trading/ClientProfile/IndexPage';
import { TradingClientProfileNewPage, TradingClientProfileDetailPage } from '../components/pages/Trading/ClientProfile/ViewPage';
import { TradingMasterClientProfilePage } from '../components/pages/Trading/MasterClientProfile/IndexPage';
import { TradingMasterClientProfileNewPage, TradingMasterClientProfileDetailPage } from '../components/pages/Trading/MasterClientProfile/ViewPage';
import { TradingRevenueStreamPage } from '../components/pages/Trading/RevenueStream/IndexPage';
import { TradingRevenueStreamNewPage, TradingRevenueStreamDetailPage } from '../components/pages/Trading/RevenueStream/ViewPage';
import { TradingDealCommitmentPage } from '../components/pages/Trading/DealCommitment/IndexPage';
import { TradingDealCommitmentNewPage, TradingDealCommitmentDetailPage } from '../components/pages/Trading/DealCommitment/ViewPage';
import { TradingMediaOwnerProfilePage } from '../components/pages/Trading/MediaOwnerProfile/IndexPage';
import { TradingMediaOwnerProfileNewPage, TradingMediaOwnerProfileDetailPage } from '../components/pages/Trading/MediaOwnerProfile/ViewPage';
import { TradingDealForecastPage } from '../components/pages/Trading/DealForecast/IndexPage';
import { TradingDealForecastNewPage, TradingDealForecastDetailPage } from '../components/pages/Trading/DealForecast/ViewPage';
import { TradingDealCategoryPage } from '../components/pages/Trading/DealCategory/IndexPage';
import { TradingDealCategoryNewPage, TradingDealCategoryDetailPage } from '../components/pages/Trading/DealCategory/ViewPage';
import { TradingRevenueForecastPage } from '../components/pages/Trading/RevenueForecast/IndexPage';
import { TradingRevenueForecastNewPage, TradingRevenueForecastDetailPage } from '../components/pages/Trading/RevenueForecast/ViewPage';
import { MasterMasterClientPage } from '../components/pages/Master/MasterClient/IndexPage';
import { MasterMasterClientNewPage, MasterMasterClientDetailPage } from '../components/pages/Master/MasterClient/ViewPage';
import { MasterLanguagePage } from '../components/pages/Master/Language/IndexPage';
import { MasterLanguageNewPage, MasterLanguageDetailPage } from '../components/pages/Master/Language/ViewPage';
import { MasterMasterMediaTypePage } from '../components/pages/Master/MasterMediaType/IndexPage';
import { MasterMasterMediaTypeNewPage, MasterMasterMediaTypeDetailPage } from '../components/pages/Master/MasterMediaType/ViewPage';
import { MasterMediaClassificationPage } from '../components/pages/Master/MediaClassification/IndexPage';
import { MasterMediaClassificationNewPage, MasterMediaClassificationDetailPage } from '../components/pages/Master/MediaClassification/ViewPage';
import { MasterMasterVendorOwnerPage } from '../components/pages/Master/MasterVendorOwner/IndexPage';
import { MasterMasterVendorOwnerNewPage, MasterMasterVendorOwnerDetailPage } from '../components/pages/Master/MasterVendorOwner/ViewPage';
import { MasterMediaOwnerPage } from '../components/pages/Master/MediaOwner/IndexPage';
import { MasterMediaOwnerNewPage, MasterMediaOwnerDetailPage } from '../components/pages/Master/MediaOwner/ViewPage';
import { MasterCurrencyPage } from '../components/pages/Master/Currency/IndexPage';
import { MasterCurrencyNewPage, MasterCurrencyDetailPage } from '../components/pages/Master/Currency/ViewPage';
import { MasterMarketPage } from '../components/pages/Master/Market/IndexPage';
import { MasterMarketNewPage, MasterMarketDetailPage } from '../components/pages/Master/Market/ViewPage';
import { MasterMasterMarketPage } from '../components/pages/Master/MasterMarket/IndexPage';
import { MasterMasterMarketNewPage, MasterMasterMarketDetailPage } from '../components/pages/Master/MasterMarket/ViewPage';
import { TradingForecastBreakPage } from '../components/pages/Trading/ForecastBreak/IndexPage';
import { TradingForecastBreakNewPage, TradingForecastBreakDetailPage } from '../components/pages/Trading/ForecastBreak/ViewPage';
import { TradingForecastAuditTrailPage } from '../components/pages/Trading/ForecastAuditTrail/IndexPage';
import { TradingForecastAuditTrailNewPage, TradingForecastAuditTrailDetailPage } from '../components/pages/Trading/ForecastAuditTrail/ViewPage';
import { TradingClientProfileGroupPage } from '../components/pages/Trading/ClientProfileGroup/IndexPage';
import { TradingClientProfileGroupNewPage, TradingClientProfileGroupDetailPage } from '../components/pages/Trading/ClientProfileGroup/ViewPage';
import { TradingOrderedSpendPage } from '../components/pages/Trading/OrderedSpend/IndexPage';
import { TradingOrderedSpendNewPage, TradingOrderedSpendDetailPage } from '../components/pages/Trading/OrderedSpend/ViewPage';
import { TradingBilledSpendPage } from '../components/pages/Trading/BilledSpend/IndexPage';
import { TradingBilledSpendNewPage, TradingBilledSpendDetailPage } from '../components/pages/Trading/BilledSpend/ViewPage';
import { TradingManualStreamPage } from '../components/pages/Trading/ManualStream/IndexPage';
import { TradingManualStreamNewPage, TradingManualStreamDetailPage } from '../components/pages/Trading/ManualStream/ViewPage';
import { TradingSpendAccountPage } from '../components/pages/Trading/SpendAccount/IndexPage';
import { TradingSpendAccountNewPage, TradingSpendAccountDetailPage } from '../components/pages/Trading/SpendAccount/ViewPage';

export const createRoutes = (isAuthenticated) => (
  <>
	  <Route path="/Master/Client/Index" element={isAuthenticated ? <MasterClientPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Client/New" element={isAuthenticated ? <MasterClientNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Client/:id" element={isAuthenticated ? <MasterClientDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Country/Index" element={isAuthenticated ? <MasterCountryPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Country/New" element={isAuthenticated ? <MasterCountryNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Country/:id" element={isAuthenticated ? <MasterCountryDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Company/Index" element={isAuthenticated ? <MasterCompanyPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Company/New" element={isAuthenticated ? <MasterCompanyNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Company/:id" element={isAuthenticated ? <MasterCompanyDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MediaVendor/Index" element={isAuthenticated ? <MasterMediaVendorPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MediaVendor/New" element={isAuthenticated ? <MasterMediaVendorNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MediaVendor/:id" element={isAuthenticated ? <MasterMediaVendorDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AgencyProfile/Index" element={isAuthenticated ? <TradingAgencyProfilePage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AgencyProfile/New" element={isAuthenticated ? <TradingAgencyProfileNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AgencyProfile/:id" element={isAuthenticated ? <TradingAgencyProfileDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ClientProfile/Index" element={isAuthenticated ? <TradingClientProfilePage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ClientProfile/New" element={isAuthenticated ? <TradingClientProfileNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ClientProfile/:id" element={isAuthenticated ? <TradingClientProfileDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MasterClientProfile/Index" element={isAuthenticated ? <TradingMasterClientProfilePage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MasterClientProfile/New" element={isAuthenticated ? <TradingMasterClientProfileNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MasterClientProfile/:id" element={isAuthenticated ? <TradingMasterClientProfileDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/RevenueStream/Index" element={isAuthenticated ? <TradingRevenueStreamPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/RevenueStream/New" element={isAuthenticated ? <TradingRevenueStreamNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/RevenueStream/:id" element={isAuthenticated ? <TradingRevenueStreamDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealCommitment/Index" element={isAuthenticated ? <TradingDealCommitmentPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealCommitment/New" element={isAuthenticated ? <TradingDealCommitmentNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealCommitment/:id" element={isAuthenticated ? <TradingDealCommitmentDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MediaOwnerProfile/Index" element={isAuthenticated ? <TradingMediaOwnerProfilePage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MediaOwnerProfile/New" element={isAuthenticated ? <TradingMediaOwnerProfileNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MediaOwnerProfile/:id" element={isAuthenticated ? <TradingMediaOwnerProfileDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealForecast/Index" element={isAuthenticated ? <TradingDealForecastPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealForecast/New" element={isAuthenticated ? <TradingDealForecastNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealForecast/:id" element={isAuthenticated ? <TradingDealForecastDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealCategory/Index" element={isAuthenticated ? <TradingDealCategoryPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealCategory/New" element={isAuthenticated ? <TradingDealCategoryNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealCategory/:id" element={isAuthenticated ? <TradingDealCategoryDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/RevenueForecast/Index" element={isAuthenticated ? <TradingRevenueForecastPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/RevenueForecast/New" element={isAuthenticated ? <TradingRevenueForecastNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/RevenueForecast/:id" element={isAuthenticated ? <TradingRevenueForecastDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterClient/Index" element={isAuthenticated ? <MasterMasterClientPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterClient/New" element={isAuthenticated ? <MasterMasterClientNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterClient/:id" element={isAuthenticated ? <MasterMasterClientDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Language/Index" element={isAuthenticated ? <MasterLanguagePage /> : <Navigate to="/" />} />
	  <Route path="/Master/Language/New" element={isAuthenticated ? <MasterLanguageNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Language/:id" element={isAuthenticated ? <MasterLanguageDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterMediaType/Index" element={isAuthenticated ? <MasterMasterMediaTypePage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterMediaType/New" element={isAuthenticated ? <MasterMasterMediaTypeNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterMediaType/:id" element={isAuthenticated ? <MasterMasterMediaTypeDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MediaClassification/Index" element={isAuthenticated ? <MasterMediaClassificationPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MediaClassification/New" element={isAuthenticated ? <MasterMediaClassificationNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MediaClassification/:id" element={isAuthenticated ? <MasterMediaClassificationDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterVendorOwner/Index" element={isAuthenticated ? <MasterMasterVendorOwnerPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterVendorOwner/New" element={isAuthenticated ? <MasterMasterVendorOwnerNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterVendorOwner/:id" element={isAuthenticated ? <MasterMasterVendorOwnerDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MediaOwner/Index" element={isAuthenticated ? <MasterMediaOwnerPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MediaOwner/New" element={isAuthenticated ? <MasterMediaOwnerNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MediaOwner/:id" element={isAuthenticated ? <MasterMediaOwnerDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Currency/Index" element={isAuthenticated ? <MasterCurrencyPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Currency/New" element={isAuthenticated ? <MasterCurrencyNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Currency/:id" element={isAuthenticated ? <MasterCurrencyDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Market/Index" element={isAuthenticated ? <MasterMarketPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Market/New" element={isAuthenticated ? <MasterMarketNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Market/:id" element={isAuthenticated ? <MasterMarketDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterMarket/Index" element={isAuthenticated ? <MasterMasterMarketPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterMarket/New" element={isAuthenticated ? <MasterMasterMarketNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterMarket/:id" element={isAuthenticated ? <MasterMasterMarketDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ForecastBreak/Index" element={isAuthenticated ? <TradingForecastBreakPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ForecastBreak/New" element={isAuthenticated ? <TradingForecastBreakNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ForecastBreak/:id" element={isAuthenticated ? <TradingForecastBreakDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ForecastAuditTrail/Index" element={isAuthenticated ? <TradingForecastAuditTrailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ForecastAuditTrail/New" element={isAuthenticated ? <TradingForecastAuditTrailNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ForecastAuditTrail/:id" element={isAuthenticated ? <TradingForecastAuditTrailDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ClientProfileGroup/Index" element={isAuthenticated ? <TradingClientProfileGroupPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ClientProfileGroup/New" element={isAuthenticated ? <TradingClientProfileGroupNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ClientProfileGroup/:id" element={isAuthenticated ? <TradingClientProfileGroupDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/OrderedSpend/Index" element={isAuthenticated ? <TradingOrderedSpendPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/OrderedSpend/New" element={isAuthenticated ? <TradingOrderedSpendNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/OrderedSpend/:id" element={isAuthenticated ? <TradingOrderedSpendDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/BilledSpend/Index" element={isAuthenticated ? <TradingBilledSpendPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/BilledSpend/New" element={isAuthenticated ? <TradingBilledSpendNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/BilledSpend/:id" element={isAuthenticated ? <TradingBilledSpendDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ManualStream/Index" element={isAuthenticated ? <TradingManualStreamPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ManualStream/New" element={isAuthenticated ? <TradingManualStreamNewPage /> : <Navigate to="/" />} />
		<Route path="/Trading/ManualStream/:id" element={isAuthenticated ? <TradingManualStreamDetailPage /> : <Navigate to="/" />} />
		<Route path="/Trading/SpendAccount/Index" element={isAuthenticated ? <TradingSpendAccountPage /> : <Navigate to="/" />} />
		<Route path="/Trading/SpendAccount/New" element={isAuthenticated ? <TradingSpendAccountNewPage /> : <Navigate to="/" />} />
		<Route path="/Trading/SpendAccount/:id" element={isAuthenticated ? <TradingSpendAccountDetailPage /> : <Navigate to="/" />} />
  </>
);
