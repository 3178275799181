import { OnDemandIcon } from '../../components/helpers/IconUtils';
import { Button } from '@progress/kendo-react-buttons';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { AppBar, AppBarSection, AppBarSpacer, Avatar } from '@progress/kendo-react-layout';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import logo_white from '../../assets/images/logo_white.png';
import { Resources } from '../../config/CommonResources';
import { DARK_MODE } from '../../config/Constants';
import { TOGGLE_NOTIFICATIONS, TOGGLE_SETTINGS, TOGGLE_SIDEBAR, TOGGLE_MESSAGES } from '../../store/actions/navActions';
import { RootState } from '../../store/reducer/rootReducer';
import { IUserInfoProps } from '../services/GraphQLShared';
import { setSecurityProfile } from '../../store/actions/profileActions';
import { APP_CONFIG } from '../../config/Config';

const mapState = (state: RootState) => ({
  theme: state.nav.theme,
  notifications: state.nav.notifications,
  pendingmessages: state.nav.pendingmessages,
  isAuthorized: state.security.isAuthorized,
  profileError: state.security.profileError,
  profile: state.security.profile,
});

const mapDispatch = {
  toggleSidebar: () => ({ type: TOGGLE_SIDEBAR }),
  toggleNotifications: () => ({ type: TOGGLE_NOTIFICATIONS }),
  toggleMessages: () => ({ type: TOGGLE_MESSAGES }),
  toggleSettings: () => ({ type: TOGGLE_SETTINGS }),
  setSecurityProfile,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export type IAppNavBarProps = PropsFromRedux & {
  user: IUserInfoProps;
  isAuthenticated: boolean;
  authButtonMethod: () => void;
};

function AppNavBar(props: IAppNavBarProps): React.ReactElement {
  const handleClickClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    props.toggleSidebar();
  };
  const handleClickSettings = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    props.toggleSettings();
  };
  const handleClickNotifications = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    props.toggleNotifications();
  };
  const handleClickMessages = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    props.toggleMessages();
  };

  const handleChange = (event: DropDownListChangeEvent) => {
    props.setSecurityProfile(event.target.value.Id);
  };

  return (
    <>
      <AppBar positionMode={'fixed'}>
        {props.isAuthorized && (
          <AppBarSection>
            <Button icon={'menu'} fillMode={'flat'} onClick={handleClickClose}></Button>
          </AppBarSection>
        )}
        <AppBarSection>
          <Link to="/" className="k-appbar-brand">
            {props.theme === DARK_MODE ? (
              <img src={logo_white} className="brand-logo" alt={Resources.ApplicationName} />
            ) : (
              <img src={logo} className="brand-logo" alt={Resources.ApplicationName} />
            )}
            <div className="k-appbar-brand-name">
              <h3>{Resources.ApplicationName}</h3>
              {Resources.ApplicationDescription && <h5 className="mb-0">{Resources.ApplicationDescription}</h5>}
            </div>
          </Link>
        </AppBarSection>
        <AppBarSpacer />
        {props.isAuthenticated &&
          props.isAuthorized &&
          props.profile != null &&
          props.profile.DataAuth.Roles.length > 1 && (
            <>
              <AppBarSection>
                <DropDownList
                  header={
                    <span style={{ marginRight: '20px', marginLeft: '10px', fontWeight: 'bold' }}>
                      Choose the role you want to use:
                    </span>
                  }
                  style={{ width: '270px', backgroundColor: 'transparent', textAlign: 'right', border: '0' }}
                  data={props.profile.DataAuth.Roles}
                  textField="Name"
                  dataItemKey="Id"
                  value={props.profile.DataAuth.Roles.find((item) => item.Selected)}
                  onChange={handleChange}
                />
              </AppBarSection>
            </>
          )}
        {props.isAuthenticated && props.isAuthorized && (
          <>
            <AppBarSection className="k-appbar-actions">
              <Button fillMode="flat" onClick={handleClickNotifications}>
                <BadgeContainer>
                  <OnDemandIcon icon="bell" size="lg" fixedWidth={true} />
                  {props.notifications.length > 0 ? (
                    <Badge themeColor="secondary" size="small" shape="circle">
                      {props.notifications.length}
                    </Badge>
                  ) : (
                    <Badge themeColor="info" size="small" shape="circle">
                      {props.notifications.length}
                    </Badge>
                  )}
                </BadgeContainer>
              </Button>
              {APP_CONFIG.ENABLEMESSAGING && (
                <Button fillMode="flat" onClick={handleClickMessages}>
                  <BadgeContainer>
                    <OnDemandIcon icon="envelope" size="lg" fixedWidth={true} />
                    {props.pendingmessages > 0 ? (
                      <Badge themeColor="secondary" size="small" shape="circle">
                        {props.pendingmessages}
                      </Badge>
                    ) : (
                      <Badge themeColor="info" size="small" shape="circle">
                        {props.pendingmessages}
                      </Badge>
                    )}
                  </BadgeContainer>
                </Button>
              )}
            </AppBarSection>
            <AppBarSection>
              <span className="k-appbar-separator" />
            </AppBarSection>
            <AppBarSection>
              <Button fillMode="flat" onClick={handleClickSettings}>
                <Avatar rounded="full" type="image">
                  {props.user && props.user.avatar ? (
                    <img src={props.user.avatar} alt={props.user.displayName} />
                  ) : (
                    <div className="no-user-image">
                      <OnDemandIcon icon="user" />
                    </div>
                  )}
                </Avatar>
              </Button>
            </AppBarSection>
          </>
        )}
        {props.isAuthenticated && !props.isAuthorized && (
          <>
            <AppBarSection>
              <span className="k-appbar-separator" />
            </AppBarSection>
            <AppBarSection>
              <Button fillMode="flat" onClick={handleClickSettings}>
                <Avatar rounded="full" themeColor="base" type="image">
                  {props.user && props.user.avatar ? (
                    <img src={props.user.avatar} alt={props.user.displayName} />
                  ) : (
                    <div className="no-user-image">
                      <OnDemandIcon icon="user" />
                    </div>
                  )}
                </Avatar>
              </Button>
            </AppBarSection>
          </>
        )}
        {!props.isAuthenticated && (
          <>
            <AppBarSection>
              <span className="k-appbar-separator" />
            </AppBarSection>
            <AppBarSection>
              <Button fillMode="flat" className="k-icon-button" onClick={props.authButtonMethod}>
                <OnDemandIcon icon="sign-in-alt" />
              </Button>
            </AppBarSection>
          </>
        )}
      </AppBar>
    </>
  );
}
export default connector(AppNavBar);
