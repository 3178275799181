/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { Suspense, lazy } from 'react';
import { LIGHT_MODE, DARK_MODE } from '../../config/Constants';
import { RootState } from '../../store/reducer/rootReducer';
import { useSelector } from 'react-redux';

function ThemeSelector(props: any): React.ReactElement {
  const LightTheme = lazy(() => import('./LightTheme'));
  const DarkTheme = lazy(() => import('./DarkTheme'));
  const selectTheme = (state: RootState) => state.nav.theme;
  const currentTheme = useSelector(selectTheme);
  return (
    <>
      <Suspense fallback={<></>}>
        {currentTheme === DARK_MODE && <DarkTheme />}
        {(currentTheme === undefined || currentTheme === LIGHT_MODE) && <LightTheme />}
      </Suspense>
      {props.children}
    </>
  );
}

export default ThemeSelector;
