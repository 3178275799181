import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScripts } from '../../helpers/Utils';

export function DataAdminPage(): React.ReactElement {
  const currentSecurity = useSecurity('Shared', 'DataAdmin');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScripts(['DataAdmin.js']);
    }
  }, []);
  return (
    <>
      <div id="DataAdminToolbar" className="main-toolbar"></div>
      <div style={{ padding: '0 35px' }} id="DataAdminGridParent">
        <div id="DataAdminGridContainer"></div>
      </div>
    </>
  );
}
