import axios, { AxiosRequestConfig } from 'axios';
import { API_ENDPOINTS } from '../config/Config';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function handler(err: any): Error {
  let error = err;
  if (err.response && err.response.data.hasOwnProperty('message')) error = err.response.data;
  else if (!err.hasOwnProperty('message')) error = err.toJSON();

  return new Error(error.message);
}
async function getHeadersAsync(accessToken: string) {
  return { headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' } };
}
export async function getContextDetails(accessToken: string): Promise<any> {
  try {
    const options = await getHeadersAsync(accessToken);
    const url = `${API_ENDPOINTS.GET_CONTEXT}`;
    const res = await axios.get(url, options);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function getEntityDetails(bc: string, ent: string, id: string | undefined): Promise<any> {
  try {
    const options: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const parameters = { Filter: { Filters: [{ Field: 'Id', Value: id, Operator: 'eq' }] }, Status: 2 };
    const url = `${API_ENDPOINTS.POST_COMPOSITE}?bc=${bc}&ent=${ent}`;
    const res = await axios.post(url, parameters, options);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function getPreferredDetails(bc: string, ent: string): Promise<any> {
  try {
    const options: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const parameters = { Filter: { Filters: [{ Field: 'Preferred', Value: 'true', Operator: 'eq' }] }, Status: 2 };
    const url = `${API_ENDPOINTS.POST_COMPOSITE}?bc=${bc}&ent=${ent}`;
    const res = await axios.post(url, parameters, options);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function getCustom(bc: string, ent: string, method: string | undefined): Promise<any> {
  try {
    const options: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const parameters = { data: [] };
    const url = `${API_ENDPOINTS.PUT_CUSTOM}?bc=${bc}&ent=${ent}&function=${method}`;
    const res = await axios.put(url, parameters, options);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}

export async function setRole(id: string | undefined): Promise<any> {
  try {
    const options: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const url = `${API_ENDPOINTS.POST_CHANGEROLE}`;
    const res = await axios.post(url, id, options);
    return res.data;
  } catch (e) {
    throw handler(e);
  }
}
