import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import React from 'react';
import { useImmerState } from './Hooks';
import { IUserInfoProps } from '../services/GraphQLShared';
import { loginRequest, msalConfig, tokenRequest, apiConfig } from '../../config/Config';
import { getUserDetails } from '../../services/GraphService';
import { asyncStates, IAsyncState } from '../../config/Enums';
import { appInsights } from '../../services/AppInsights';

const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ') > -1;
  const msie11 = ua.indexOf('Trident/') > -1;
  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;
  return msie || msie11;
};

const useRedirectFlow = isIE();

export interface AuthComponentProps {
  error: any;
  isAuthenticated: boolean;
  user: IUserInfoProps;
  msalUserStatus: IAsyncState;
  login: () => void;
  loginWithRedirect: () => void;
  logout: () => void;
  getAccessToken: (scopes: string[]) => Promise<string>;
}

const isDev = process.env.NODE_ENV === 'development';

const useAuth = (): AuthComponentProps => {
  const emptyUser = {} as IUserInfoProps;
  const [state, setState] = useImmerState({
    error: null,
    isAuthenticated: false,
    user: emptyUser,
    msalUserStatus: asyncStates.idle,
  });
  const { error, isAuthenticated, user, msalUserStatus } = state;

  const publicClientApplication = React.useMemo(
    () =>
      new PublicClientApplication({
        auth: {
          authority: msalConfig.authority,
          clientId: msalConfig.clientId,
          redirectUri: msalConfig.redirectUri,
          navigateToLoginRequestUrl: true,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: true,
        },
        system: {
          loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
              if (containsPii) {
                return;
              }
              switch (level) {
                case LogLevel.Error:
                  if (isDev) console.error(message);
                  return;
                case LogLevel.Info:
                  if (isDev) console.info(message + ' ' + new Date().getMilliseconds());
                  return;
                case LogLevel.Verbose:
                  if (isDev) console.debug(message);
                  return;
                case LogLevel.Warning:
                  if (isDev) console.warn(message);
                  return;
              }
            },
            piiLoggingEnabled: false,
          },
          windowHashTimeout: 60000,
          iframeHashTimeout: 6000,
          loadFrameTimeout: 0,
        },
      }),
    [],
  );

  React.useEffect(() => {
    (async () => {
      await publicClientApplication.initialize();
      if (useRedirectFlow) {
        publicClientApplication
          .handleRedirectPromise()
          .then((response) => {
            if (response !== null) {
              getUserProfile();
            } else {
              const accounts = publicClientApplication.getAllAccounts();
              if (accounts && accounts.length > 0) {
                getUserProfile();
              }
            }
          })
          .catch((e: string | Error | any) => {
            setState({ ...state, error: e.errorMessage });
          });
      }
      // If MSAL already has an account, the user
      // is already logged in
      const accounts = publicClientApplication.getAllAccounts();
      if (accounts && accounts.length > 0) {
        // Enhance user object with data from Graph
        getUserProfile();
      } else {
        login();
      }
    })();
  }, []);

  const getAccessToken = async (scopes: string[]): Promise<string> => {
    try {
      const accounts = publicClientApplication.getAllAccounts();
      if (accounts.length <= 0) throw new Error('login_required');
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      const silentResult = await publicClientApplication.acquireTokenSilent({
        scopes: scopes,
        account: accounts[0],
      });

      return silentResult.accessToken;
    } catch (e: any) {
      const isInteractionRequired = (error: Error) => {
        if (!error.message || error.message.length <= 0) {
          return false;
        }
        return (
          error.message.indexOf('invalid_grant') > -1 ||
          error.message.indexOf('consent_required') > -1 ||
          error.message.indexOf('interaction_required') > -1 ||
          error.message.indexOf('login_required') > -1 ||
          error.message.indexOf('no_account_in_silent_request') > -1
        );
      };
      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes
      if (isInteractionRequired(e)) {
        const interactiveResult = await publicClientApplication.acquireTokenPopup({
          scopes: scopes,
          prompt: 'select_account',
        });

        return interactiveResult.accessToken;
      } else {
        throw e;
      }
    }
  };

  const getUserProfile = async () => {
    setState({ msalUserStatus: asyncStates.pending });
    try {
      const accessToken = await getAccessToken(tokenRequest.scopes);
      if (accessToken) {
        // Get the users profile from Graph
        const user = await getUserDetails(accessToken);
        if (axios.defaults.headers.common['Authorization'] == undefined) {
          // Get the token for API
          const accessToken2 = await getAccessToken(apiConfig.scopes);
          // Attach authorization header for all axios requests
          if (axios.defaults.headers) axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken2}`;
          window['setaccesstoken'](accessToken2);
        }
        appInsights.setAuthenticatedUserContext(user.id ?? '');
        setState({
          isAuthenticated: true,
          user: user,
          msalUserStatus: asyncStates.resolved,
          error: null,
        });
      }
    } catch (err: any) {
      setState({
        isAuthenticated: false,
        user: emptyUser,
        msalUserStatus: asyncStates.rejected,
        error: normalizeError(err),
      });
    }
  };

  const loginWithRedirect = async () => {
    try {
      await publicClientApplication.loginRedirect(loginRequest);
      await getUserProfile();
    } catch (e: any) {
      setState({
        isAuthenticated: false,
        user: emptyUser,
        error: normalizeError(e),
      });
    }
  };

  const login = async () => {
    try {
      // Login via popup
      await publicClientApplication.loginPopup({
        scopes: loginRequest.scopes,
        prompt: 'select_account',
      });

      // After login, get the user\'s profile
      await getUserProfile();
    } catch (err: any) {
      setState({
        isAuthenticated: false,
        user: emptyUser,
        error: normalizeError(err),
      });
    }
  };

  const logout = () => publicClientApplication.logoutPopup({ mainWindowRedirectUri: '/' });

  return {
    error,
    isAuthenticated,
    user,
    msalUserStatus,
    login,
    loginWithRedirect,
    logout,
    getAccessToken,
  };
};

const normalizeError = (error: string | Error): any => {
  let normalizedError = {};
  if (typeof error === 'string') {
    const errParts = error.split('|');
    normalizedError = errParts.length > 1 ? { message: errParts[1], debug: errParts[0] } : { message: error };
  } else {
    normalizedError = {
      message: error.message,
      debug: JSON.stringify(error),
    };
  }
  return normalizedError;
};

export default useAuth;
