import React from 'react';
import { ISignalRMessageProps, SignalRMessageType } from '../../../services/SignalRHelper';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';

export interface INotificationPopupProps {
  message: ISignalRMessageProps;
}
interface State {
  success: boolean;
  error: boolean;
  warning: boolean;
  info: boolean;
  none: boolean;
  show: boolean;
}

const NotificationPopup = (props: INotificationPopupProps): React.ReactElement | null => {
  const [state, setState] = React.useState<State>({
    show: true,
    none: props.message.type == SignalRMessageType.Progress,
    success: props.message.type == SignalRMessageType.Success,
    error: props.message.type == SignalRMessageType.Error,
    warning: props.message.type == SignalRMessageType.Warning,
    info: props.message.type == SignalRMessageType.Info,
  });
  const { show, success, error, warning, info } = state;

  React.useEffect(() => {
    if (props.message)
      setState({
        ...state,
        show: true,
      });
    const timeId = setTimeout(() => {
      setState({
        ...state,
        show: false,
      });
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [props.message]);

  if (!show) {
    return null;
  }
  return (
    <NotificationGroup
      style={{
        right: 0,
        bottom: 0,
        alignItems: 'flex-start',
        flexWrap: 'wrap-reverse',
      }}
    >
      {show && success && (
        <Notification
          type={{ style: 'success', icon: true }}
          closable={true}
          onClose={() => setState({ ...state, success: false, show: false })}
        >
          {props.message.text}
        </Notification>
      )}
      {show && error && (
        <Notification
          type={{ style: 'error', icon: true }}
          closable={true}
          onClose={() => setState({ ...state, error: false, show: false })}
        >
          {props.message.text}
        </Notification>
      )}
      {show && warning && (
        <Notification
          type={{ style: 'warning', icon: true }}
          closable={true}
          onClose={() => setState({ ...state, warning: false, show: false })}
        >
          {props.message.text}
        </Notification>
      )}
      {show && info && (
        <Notification
          type={{ style: 'info', icon: true }}
          closable={true}
          onClose={() => setState({ ...state, info: false, show: false })}
        >
          {props.message.text}
        </Notification>
      )}
    </NotificationGroup>
  );
};

export default NotificationPopup;
