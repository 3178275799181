import {
  JsonHubProtocol,
  HubConnection,
  HubConnectionState,
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from '@microsoft/signalr';
import { apiConfig, API_ENDPOINTS } from '../config/Config';
import { ISecurityProfileProps } from '../components/services/GraphQLShared';

const isDev = process.env.NODE_ENV === 'development';
const startSignalRConnection = async (connection: HubConnection) => {
  try {
    await connection.start();
    console.assert(connection.state === HubConnectionState.Connected);
    if (isDev) console.log('SignalR connection established');
  } catch (err) {
    console.assert(connection.state === HubConnectionState.Disconnected);
    if (isDev) console.error('SignalR Connection Error: ', err);
    setTimeout(() => startSignalRConnection(connection), 5000);
  }
};
export const SignalRMessageType = {
  Info: 0,
  Success: 1,
  Warning: 2,
  Error: 3,
  Progress: 4,
};
export const SignalRMessageStatus = {
  Running: 0,
  Success: 1,
  Warning: 2,
  Error: 3,
  None: 4,
};
export interface ISignalRMessageProps {
  id: string;
  text: string;
  type: number;
  status: number;
  percent: number;
  createOn: Date;
}
// Set up a SignalR connection to the specified hub URL, and actionEventMap.
// actionEventMap should be an object mapping event names, to eventHandlers that will
// be dispatched with the message body.
export const setupSignalRConnection = (
  profile: ISecurityProfileProps,
  eventHandler: (message: ISignalRMessageProps) => void,
  getAccessToken: (scopes: string[]) => Promise<string>,
): HubConnection => {
  const options = {
    logMessageContent: isDev,
    logger: isDev ? LogLevel.Warning : LogLevel.Error,
    accessTokenFactory: () => getAccessToken(apiConfig.scopes),
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
  };
  //const navigate = useNavigate();
  // create the connection instance
  // withAutomaticReconnect will automatically try to reconnect
  // and generate a new socket connection if needed
  const connection = new HubConnectionBuilder()
    .withUrl(`${API_ENDPOINTS.signalrEndPoint}?userid=${profile.UserId}&username=${profile.LoginName}`, options)
    .withAutomaticReconnect()
    .withHubProtocol(new JsonHubProtocol())
    .configureLogging(isDev ? LogLevel.Warning : LogLevel.Error)
    .build();

  // Note: to keep the connection open the serverTimeout should be
  // larger than the KeepAlive value that is set on the server
  // keepAliveIntervalInMilliseconds default is 15000 and we are using default
  // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
  connection.serverTimeoutInMilliseconds = 60000;

  // re-establish the connection if connection dropped
  connection.onclose((error) => {
    console.assert(connection.state === HubConnectionState.Disconnected);
    console.log('Connection closed due to error. Try refreshing this page to restart the connection', error);
  });

  connection.onreconnecting((error) => {
    console.assert(connection.state === HubConnectionState.Reconnecting);
    console.log('Connection lost due to error. Reconnecting.', error);
  });

  connection.onreconnected((connectionId) => {
    console.assert(connection.state === HubConnectionState.Connected);
    console.log('Connection reestablished. Connected with connectionId', connectionId);
  });

  startSignalRConnection(connection);

  connection.on('Info', (message: ISignalRMessageProps) => {
    eventHandler(message);
  });

  connection.on('Error', (message: ISignalRMessageProps) => {
    eventHandler(message);
  });

  connection.on('Progress', (message: ISignalRMessageProps) => {
    eventHandler(message);
  });

  connection.on('Reload', () => {
    window['forcerefresh']('/');
  });

  return connection;
};
