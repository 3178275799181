import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
import { appendScriptsCallback, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
import { getEntityDetails } from '../../../../services/ApiServices';

export interface IIntegrationProcess {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: string;
    Source?: string;
  };
  DocumentId?: string;
  DocumentDisplayValue?: string;
  IntegrationDefinitionId?: string;
  IntegrationDefinitionDisplayValue?: string;
  ContentType?: string;
  CorrelationId?: string;
  CurrentPage?: string;
  DataType?: string;
  EndDate?: string;
  MessageBody?: string;
  MessageCode?: string;
  ProcessSource?: string;
  RowCount?: string;
  StartDate?: string;
  Status?: string;
  Summary?: string;
  TotalErrors?: string;
  TotalPages?: string;
  TotalSpend?: string;
  TotalWarnings?: string;
  Type?: string;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

export function IntegrationProcessDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IIntegrationProcess>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Integrator', 'IntegrationProcess');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Integrator', 'IntegrationProcess', id).then((result) => {
        setState(result.Value[0] as IIntegrationProcess);
        appendScriptsCallback(
          [
            'Areas/Admin/Scripts/Resources/en/res.Integrator.en.js',
            'Areas/Admin/Scripts/Resources/en/res.IntegrationProcess.en.js',
            'Areas/Admin/Scripts/Resources/en/Types.en.js',
            'Areas/Admin/Scripts/Enums.js',
            'Areas/Admin/Scripts/IntegrationProcess/View.js',
            'Areas/Admin/Scripts/Resources/en/res.IntegrationField.en.js',
            'Areas/Admin/Scripts/IntegrationField/View.js',
            'Areas/Admin/Scripts/Resources/en/res.IntegrationTransformation.en.js',
            'Areas/Admin/Scripts/IntegrationTransformation/View.js',
            'Areas/Admin/Scripts/Resources/en/res.IntegrationValidation.en.js',
            'Areas/Admin/Scripts/IntegrationValidation/View.js',
          ],
          'initDetailIntegratorIntegrationProcess',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadIntegratorIntegrationProcessState'] = (data) => {
    setState(data.Value[0] as IIntegrationProcess);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="IntegrationProcess" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      IntegrationProcess: <span>{state.Display}</span>
                      <span
                        id="IntegrationProcessToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line">
                  {state.DocumentId && (
                    <span className="element">
                      <label>
                        Agency: <label className="strong">{state.DocumentDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  {state.IntegrationDefinitionId && (
                    <span className="element">
                      <label>
                        Buying Agency: <label className="strong">{state.IntegrationDefinitionDisplayValue}</label>
                      </label>
                    </span>
                  )}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="IntegrationProcessCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="IntegrationProcessLabelBlockedCode">Blocked</label>
                  <input
                    id="IntegrationProcessCheckBlockedCode"
                    defaultChecked
                    name="IntegrationProcessCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 shortcontrol-container combobox ">
                  <label htmlFor="IntegrationDefinitionId">Definition</label>
                  <select
                    className=""
                    id="IntegrationProcessIntegrationDefinitionId"
                    name="IntegrationDefinitionId"
                    disabled
                    defaultValue={state.IntegrationDefinitionId}
                  >
                    {state.IntegrationDefinitionId && (
                      <option value={state.IntegrationDefinitionId}>{state.IntegrationDefinitionDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-2 xshortcontrol-container dropdownlist  ">
                  <label htmlFor="Type">Type</label>
                  <select
                    className=""
                    id="IntegrationProcessType"
                    name="Type"
                    disabled
                    required
                    data-required-msg="Type is required"
                    value={state.Type}
                  >
                    <option value="0">Master</option>
                    <option value="1">Forecast Spend</option>
                    <option value="2">Actual Spend</option>
                    <option value="3">Billed Spend</option>
                    <option value="4">Purging</option>
                    <option value="5">Audience</option>
                    <option value="6">Batch</option>
                    <option value="7">Propage Batch</option>
                    <option value="8">Campaigns</option>
                    <option value="9">Trading</option>
                    <option value="10">Others</option>
                  </select>
                </div>
                <div className="col-md-2 xshortcontrol-container dropdownlist  ">
                  <label htmlFor="Status">Status</label>
                  <select
                    className=""
                    id="IntegrationProcessStatus"
                    name="Status"
                    disabled
                    required
                    data-required-msg="Status is required"
                    value={state.Status}
                  >
                    <option value="0">Pending</option>
                    <option value="1">Processed</option>
                    <option value="2">Error</option>
                    <option value="3">Running</option>
                    <option value="4">Scheduled</option>
                    <option value="5">Warning</option>
                  </select>
                </div>
                <div className="col-md-2 xshortcontrol-container dropdownlist  ">
                  <label htmlFor="ContentType">Content Type</label>
                  <select
                    className=""
                    id="IntegrationProcessContentType"
                    name="ContentType"
                    disabled
                    required
                    data-required-msg="Content Type is required"
                    value={state.ContentType}
                  >
                    <option value="0">XML</option>
                    <option value="1">JSON</option>
                    <option value="2">CSV</option>
                    <option value="99">Unknown</option>
                  </select>
                </div>
                <div className="col-md-4 shortcontrol-container">
                  <label htmlFor="ProcessSource">Source System</label>
                  <input
                    data-required-msg="Source System is required"
                    id="IntegrationProcessProcessSource"
                    max="100"
                    min="1"
                    name="ProcessSource"
                    required
                    type="text"
                    value={state.ProcessSource}
                    className="k-input k-textbox"
                    disabled
                  />
                </div>
                <div className="field textareafield">
                  <label htmlFor="Description">Summary</label>
                  <div style={{ display: 'inline-block' }}>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="IntegrationProcessSummary"
                      defaultValue={state.Summary}
                      name="Summary"
                      maxLength={2147483647}
                      rows={5}
                    ></textarea>
                  </div>
                </div>
                <div className="clear"></div>
                <div className="col-md-2 shortcontrol-container">
                  <label htmlFor="StartDate">Started at</label>
                  <input
                    data-val="true"
                    data-val-date="The field Started at must be a date."
                    id="IntegrationProcessStartDate"
                    max="12/31/9999 23:59:59"
                    min="01/01/0001 00:00:00"
                    name="StartDate"
                    type="text"
                    value={state.StartDate}
                    disabled
                  />
                </div>
                <div className="col-md-2 shortcontrol-container">
                  <label htmlFor="EndDate">Finalize at</label>
                  <input
                    data-val="true"
                    data-val-date="The field Finalize at must be a date."
                    id="IntegrationProcessEndDate"
                    max="12/31/9999 23:59:59"
                    min="01/01/0001 00:00:00"
                    name="EndDate"
                    type="text"
                    value={state.EndDate}
                    disabled
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-2 numcontrol-container">
                  <label htmlFor="RowCount">#Records Processed</label>
                  <input
                    data-required-msg="#Records Processed is required"
                    data-val="true"
                    data-val-number="The field #Records Processed must be a number."
                    data-val-required="The #Records Processed field is required."
                    id="IntegrationProcessRowCount"
                    min="-2147483648"
                    name="RowCount"
                    required
                    type="text"
                    value={state.RowCount}
                    disabled
                  />
                </div>
                <div className="col-md-2 numcontrol-container">
                  <label htmlFor="TotalErrors">#Errors</label>
                  <input
                    data-required-msg="#Errors is required"
                    data-val="true"
                    data-val-number="The field #Errors must be a number."
                    data-val-required="The #Errors field is required."
                    id="IntegrationProcessTotalErrors"
                    min="-2147483648"
                    name="TotalErrors"
                    required
                    type="text"
                    value={state.TotalErrors}
                    disabled
                  />
                </div>
                <div className="col-md-2 numcontrol-container">
                  <label htmlFor="TotalWarnings">#Warnings</label>
                  <input
                    data-required-msg="#Warnings is required"
                    data-val="true"
                    data-val-number="The field #Warnings must be a number."
                    data-val-required="The #Warnings field is required."
                    id="IntegrationProcessTotalWarnings"
                    min="-2147483648"
                    name="TotalWarnings"
                    required
                    type="text"
                    value={state.TotalWarnings}
                    disabled
                  />
                </div>
                <div className="col-md-2 numcontrol-container">
                  <label htmlFor="TotalSpend">#Spend</label>
                  <input
                    data-val="true"
                    data-val-number="The field #Spend must be a number."
                    id="IntegrationProcessTotalSpend"
                    max="79228162514264337593543950335"
                    min="-79228162514264337593543950335"
                    name="TotalSpend"
                    type="text"
                    value={state.TotalSpend}
                    disabled
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-2 numcontrol-container">
                  <label htmlFor="CurrentPage">Current Page</label>
                  <input
                    data-required-msg="Current Page is required"
                    data-val="true"
                    data-val-number="The field Current Page must be a number."
                    data-val-required="The Current Page field is required."
                    id="IntegrationProcessCurrentPage"
                    min="-2147483648"
                    name="CurrentPage"
                    required
                    type="text"
                    value={state.CurrentPage}
                    disabled
                  />
                </div>
                <div className="col-md-2 numcontrol-container">
                  <label htmlFor="TotalPages">#Pages</label>
                  <input
                    data-required-msg="#Pages is required"
                    data-val="true"
                    data-val-number="The field #Pages must be a number."
                    data-val-required="The #Pages field is required."
                    id="IntegrationProcessTotalPages"
                    min="-2147483648"
                    name="TotalPages"
                    required
                    type="text"
                    value={state.TotalPages}
                    disabled
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-2 middlecontrol-container">
                  <label htmlFor="DataType">Data Type</label>
                  <input
                    id="IntegrationProcessDataType"
                    name="DataType"
                    type="text"
                    value={state.DataType}
                    className="k-input k-textbox"
                    disabled
                  />
                </div>
                <div className="col-md-2 middlecontrol-container">
                  <label htmlFor="CorrelationId">Correlation ID</label>
                  <input
                    id="IntegrationProcessCorrelationId"
                    max="100"
                    name="CorrelationId"
                    type="text"
                    value={state.CorrelationId}
                    className="k-input k-textbox"
                    disabled
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-2 middlecontrol-container">
                  <label htmlFor="MessageCode">#Message</label>
                  <input
                    id="IntegrationProcessMessageCode"
                    max="100"
                    name="MessageCode"
                    type="text"
                    value={state.MessageCode}
                    className="k-input k-textbox"
                    disabled
                  />
                </div>
                <div className="field textareafield">
                  <label htmlFor="MessageBody">Message Body</label>
                  <div style={{ display: 'inline-block' }}>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="IntegrationProcessMessageBody"
                      defaultValue={state.MessageBody}
                      name="MessageBody"
                      maxLength={2147483647}
                      rows={5}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    disabled
                    id="IntegrationProcessSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    disabled
                    className=""
                    id="IntegrationProcessSourceStatus"
                    name="SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select
                    className=""
                    id="IntegrationProcessSource"
                    name="Source"
                    disabled
                    value={state.LoadingInfo.Source}
                  >
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}
