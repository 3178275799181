import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { useParams } from 'react-router-dom';
import { appendScriptsCallback } from '../../helpers/Utils';

export function RevenueForecastReportPage(): React.ReactElement {
  const { area } = useParams();
  const currentSecurity = useSecurity('Trading', 'RevenueForecast');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(['Report.custom.js',
          'Areas/Master/Scripts/Resources/en/res.Client.en.js',
          'Areas/Trading/Scripts/Resources/en/res.AgencyProfile.en.js',
          'Areas/Trading/Scripts/Resources/en/res.RevenueForecastStream.en.js',
          'Areas/Trading/Scripts/Resources/en/res.RevenueStream.en.js',
          'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/RevenueForecast/RevenueTrackerReports/' + area + 'View.custom.js'],
        'initNewCanadaReport', { area: area });
    }
  }, [area]);

  function reportName(area) {
    switch (area) {
      case 'AuditTrail':
        return 'Forecast Audit Trail';
      case 'Dashboard':
        return 'Dashboard';
      case 'DataValidation':
        return 'Data Validation Crosstab';
      case 'GadAnalysis':
        return 'Gad Analysis';
      default:
        return area;
    }
  }

  return (
    <div className="panelcontent-section">
      <div id="sidebar-section" style={{ display: 'none' }}>
        <i className="fas fa-bars" id="sidebarBar"></i>
        <div className="k-content">
          <h3>{reportName(area)}</h3>
          {['GadAnalysis'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Base Forecast</label>
            <input id="inputBaseForecast" />
          </div>}
          {['GadAnalysis'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Comparison Forecast</label>
            <input id="inputCompForecast" />
          </div>}
          {['DataValidation'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Dollars</label>
            <input id="inputDollars" />
          </div>}
          {['AuditTrail', 'Dashboard', 'DataValidation', 'GadAnalysis'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Agency</label>
            <input id="inputAgency" />
          </div>}
          {['Dashboard', 'DataValidation','GadAnalysis'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Client</label>
            <input id="inputClient" />
          </div>}
          {['AuditTrail', 'DataValidation'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Forecast</label>
            <input id="inputForecast" />
          </div>}
          {['AuditTrail', 'Dashboard', 'DataValidation', 'GadAnalysis'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Gad</label>
            <input id="inputGad" />
          </div>}
          {['AuditTrail', 'Dashboard', 'DataValidation', 'GadAnalysis'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Svp</label>
            <input id="inputSvp" />
          </div>}
          {['AuditTrail'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Modified By</label>
            <input id="inputModifiedBy" />
          </div>}
          {['AuditTrail'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Created By</label>
            <input id="inputCreatedBy" />
          </div>}
          {['Dashboard', 'DataValidation', 'GadAnalysis'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Revenue Group</label>
            <input id="inputRevenueGroup" />
          </div>}
          {['Dashboard', 'DataValidation', 'GadAnalysis'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Revenue Category</label>
            <input id="inputRevenueCategory" />
          </div>}
          {['Dashboard', 'GadAnalysis'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Month</label>
            <input id="inputMonth" />
          </div>}
          {['Dashboard', 'DataValidation'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Forecast Only</label>
            <input id="inputForecastOnly" />
          </div>}
          {['GadAnalysis'].includes(area ? area.toString() : '') && <div className="field shortcontrol-container">
            <label>Forecast Only</label>
            <input id="inputForecastOnly" />
          </div>}
          {['GadAnalysis'].includes(area ? area.toString() : '') && <div className="field shortcontrol-container">
            <label>Gross/Net</label>
            <input id="inputNetGross" />
          </div>}
          {['GadAnalysis'].includes(area ? area.toString() : '') && <div className="field shortcontrol-container">
            <label>Show Years</label>
            <input id="inputGroupByYear" />
          </div>}
          {['GadAnalysis'].includes(area ? area.toString() : '') && <div className="field shortcontrol-container">
            <label>Show Months</label>
            <input id="inputGroupByMonth" />
          </div>}
          {['GadAnalysis'].includes(area ? area.toString() : '') && <div className="field shortcontrol-container">
            <label>Show GADs</label>
            <input id="inputGroupByGad" />
          </div>}
          {['DataValidation', 'GadAnalysis'].includes(area ? area.toString() : '') && <div className="field checkbox-container">
            <label>Show Clients</label>
            <input type="checkbox" id="inputGroupByClient" />
          </div>}
          {['DataValidation'].includes(area ? area.toString() : '') && <div className="field checkbox-container">
            <label>Show Total Rows</label>
            <input type="checkbox" id="inputShowTotalRows" />
          </div>}
          <section className="button-toolbar">
            {['AuditTrail', 'DataValidation','GadAnalysis'].includes(area ? area.toString() : '') && <div className="left"><button id="exportFilterButton" type="button" className="k-button" title="Export">Export</button></div>}
            <div className="right"><button id="applyFilterButton" type="button" className="k-button k-primary" title="Go">Go!</button></div>
          </section>
        </div>
      </div>
      <div id="content-section">
        <i className="fas fa-bars" id="contentBar"></i>
        {area == 'AuditTrail' && <div id="AuditTrailViewReportContainer" className="k-content">
          <h3>Forecast Audit Trail</h3>
          <div id="AuditTrailViewSpreadsheetContainer" className="spreadsheet-container"></div>
        </div>}
        {area == 'Dashboard' && <div id="DashboardViewReportContainer" className="k-content">
          <div id="DashboardGraphSection">
            <h3>Forecast Comparision</h3>
            <div id="DashboardGraphToolbarContainer"></div>
            <div id="DashboardGraphContainer"></div>
          </div>
          <div id="DashboardChartSection">
            <h3>Revenue Mix</h3>
            <div id="DashboardChartToolbarContainer"></div>
            <div id="DashboardViewChartContainer"></div>
          </div>
          <div id="DashboardExcelSection">
            <h3>Forecasts by Revenue Group & Category</h3>
            <div id="DashboardExcelToolbarContainer"></div>
            <div id="DashboardExcelContainer"></div>
          </div>
        </div>}
        {area == 'DataValidation' && <div id="DataValidationViewReportContainer" className="k-content">
          <h3>Data Validation Crosstab</h3>
          <div id="DataValidationViewSpreadsheetContainer" className="spreadsheet-container"></div>
        </div>}
        {area == 'GadAnalysis' && <div id="GadAnalysisViewReportContainer" className="k-content">
          <h3>Gad Analysis</h3>
          <div id="GadAnalysisViewSpreadsheetContainer" className="spreadsheet-container"></div>
        </div>}
      </div>
    </div>
  );
}
