import React from 'react';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const cardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);
const guidRegex = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);

export const termsValidator = (value: string): string =>
  value ? '' : "It's required to agree with Terms and Conditions.";
export const emailValidator = (value: string): string =>
  !value || emailRegex.test(value) || (value && value.startsWith('#TEMP#')) ? '' : 'Email is not valid format.';
export const emailRequiredValidator = (value: string): string =>
  !value
    ? 'Email field is required.'
    : emailRegex.test(value) || (value && value.startsWith('#TEMP#'))
    ? ''
    : 'Email is not valid format.';
export const nameValidator = (value: string): string =>
  !value ? 'Name is required' : value.length < 3 ? 'Name should be at least 3 characters long.' : '';
export const codeValidator = (value: string): string =>
  value.length < 2 ? 'Code should be at least 2 characters long.' : '';
export const isoValidator = (value: string): string =>
  !value ? 'ISO Code is required' : value.length !== 2 ? 'ISO Code should be 2 characters long.' : '';

export const guidValidator = (value: string): string =>
  guidRegex.test(value) ? '' : 'The value is not valid GUID format.';

export const guidListValidator = (value: string): string => {
  if (value.indexOf(';') > 0) {
    const guidList = value.split(';');
    let isValid = true;
    guidList.forEach((e) => {
      if (!guidRegex.test(e.trim())) {
        isValid = false;
      }
    });
    return isValid ? '' : 'The value is not valid list of GUIDs.';
  } else return guidRegex.test(value) ? '' : 'The value is not valid list of GUIDs.';
};

export const guidOptionalValidator = (value: string): string =>
  value && guidRegex.test(value) ? '' : 'The value is not valid GUID format.';

export const userNameValidator = (value: string): string =>
  !value ? 'User Name is required' : value.length < 5 ? 'User name should be at least 3 characters long.' : '';
export const phoneValidator = (value: string): string =>
  !value ? 'Phone number is required.' : phoneRegex.test(value) ? '' : 'Not a valid phone number.';
export const cardValidator = (value: string): string =>
  !value ? 'Credit card number is required. ' : cardRegex.test(value) ? '' : 'Not a valid credit card number format.';
export const cvcValidator = (value: string): string =>
  !value ? 'CVC code is required,' : cvcRegex.test(value) || value.length !== 3 ? '' : 'Not a valid CVC code format.';
export const dateValidator = (value: string): string => (value ? '' : 'Date is required');
export const colorValidator = (value: string): string => (value ? '' : 'Color is required.');

export const requiredValidator = (value: string): string => (value ? '' : 'Error: This field is required.');
export const requiredListValidator = (value: string): string =>
  value && value.length > 0 ? '' : 'Error: This field is required.';
export const checkboxValidator = (value: boolean): string => (value ? '' : '');
export const radioGroupValidator = (value: string) => (!value ? 'Value is required' : '');
export const ValidatedInput = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};
