import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../helpers/Utils';
export function IndexRevenuePeriodPage(): React.ReactElement {
  const currentSecurity = useSecurity('Trading', 'AgencyProfile');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/AgencyProfile/IndexTree.custom.js',
          'Areas/Trading/Scripts/jquery.shared.custom.js',
        ],
        'initSearch',
        { area : 0},
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <h2 style={{ paddingTop: '1em' }}>Forecast Periods Hierarchy</h2>
      <div
        id="main-section-content"
        className="row"
        style={{ paddingTop: '0 !important', backgroundColor: 'inherit', height: 'calc(100vh - 11rem)' }}
      >
        <div id="agencyprofile-details" className="col-md-4" style={{ height: '100%' }}>
          <div
            className="k-content"
            style={{ paddingTop: '0', height: '100%', marginTop: '0', paddingBottom: '0', marginBottom: '0' }}
          >
            <div
              id="treeview-container"
              style={{ backgroundColor: 'rgba(10, 39, 86, 0.05)', height: '100%', overflow: 'auto', padding: '1em' }}
            >
              <div id="treeview-left"></div>
            </div>
          </div>
        </div>
        <div id="agencyprofile-content" className="col-md-8" style={{ height: '100%', overflow: 'auto' }}>
          <div id="manageperiod-Content">
            <div id="manageperiod">
              <div id="mode1" style={{ display: 'none' }}>
                <h3>Creation Of Forecast Periods</h3>
                <div id="manageperiod-addperiod" className="k-content row">
                  <div>
                    <p>Create ALL periods for an specific year with a default break criteria</p>
                  </div>
                  <div className="col-md-6">
                    <label>Forecast Year</label>
                    <input id="inputForecastYear" />
                    <i
                      className="fas fa-info-circle"
                      id="iAvailableYear"
                      style={{ display: 'none', color: '#dc3545' }}
                      title="There are already PERIODS on the select year with spend data. Please validate the information and delete all of them prior to create new configuration."
                    ></i>
                  </div>

                  <div className="col-md-6">
                    <label>Closing Criteria</label>
                    <input id="inputBreakCriteriaClosing" />
                  </div>
                  <div className="col-md-6">
                    <label>Break Criteria</label>
                    <input id="inputBreakCriteria" />
                  </div>
                  <div className="col-md-6">
                    <label>Status</label>
                    <input id="inputStatus" />
                  </div>
                  <div className="clear"></div>
                  <ul id="pWarnings" style={{ display: 'none' }}>
                    <li>
                      <i className="fas fa-info-circle"></i>There are already PERIODS on the select year with spend
                      data. Please validate the information and delete all of them prior to create new configuration.
                    </li>
                    <li>
                      <div className="field checkbox-container" style={{ width: '500px' }}>
                        <label>This action may cause data loss; do you want to continue anyway? </label>
                        <input
                          id="inputContinueAnyway"
                          aria-label="This action may cause data loss; do you want to continue anyway?"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="clear"></div>
                <section className="button-toolbar">
                  <div className="left">
                    <button id="cancelManageperiod" type="button" className="k-button button">
                      Cancel
                    </button>
                  </div>
                  <div className="right">
                    <button id="acceptManagePeriod" type="button" className="k-button button k-primary">
                      Save
                    </button>
                  </div>
                </section>
              </div>
              <div id="mode02" style={{ display: 'none' }}>
                <h3>Current Status of the Period</h3>
                <div id="manageperiod-closingperiod">
                  <div className="dialog-content">
                    <div
                      id="inputAgencyProfileStatus"
                      className="dialog-status"
                      title="This is the CURRENT status of the forecast"
                    ></div>
                    <div className="dialog-item">
                      <div id="inputAgencyProfilePeriod" className="dialog-title"></div>
                    </div>
                    <div className="clear"></div>
                    <div className="dialog-item">
                      <label>Total Clients</label>
                      <div id="inputTotalClientProfile" className="dialog-kpi"></div>
                      <i className="fas fa-thermometer-empty" id="iNotOK" style={{ display: 'none' }}></i>
                      <i className="fas fa-thermometer-full" id="iOK" style={{ display: 'none' }}></i>
                      <div id="inputTotalClientProfilePercent" className="dialog-progress"></div>
                    </div>
                    <div className="dialog-item">
                      <label>Total Forecast</label>
                      <div id="inputTotalForecast" className="dialog-spend"></div>
                    </div>
                    <div className="dialog-item">
                      <label>Forecast Revenue</label>
                      <div id="inputTotalForecastRevenue" className="dialog-spend"></div>
                    </div>
                    <div className="dialog-item">
                      <label>Total Ordered</label>
                      <div id="inputTotalOrdered" className="dialog-spend"></div>
                    </div>
                    <div className="dialog-item">
                      <label>Ordered Revenue</label>
                      <div id="inputTotalOrderedRevenue" className="dialog-spend"></div>
                    </div>
                    <div className="dialog-item">
                      <label>Total Billed</label>
                      <div id="inputTotalBilled" className="dialog-spend"></div>
                    </div>
                    <div className="dialog-item">
                      <label>Billed Revenue</label>
                      <div id="inputTotalBilledRevenue" className="dialog-spend"></div>
                    </div>
                    <div className="dialog-item">
                      <label>YTD Total Budget</label>
                      <div id="inputTotalSpend" className="dialog-spend"></div>
                    </div>
                    <div className="dialog-item">
                      <label>YTD Revenue</label>
                      <div id="inputTotalRevenue" className="dialog-spend"></div>
                    </div>
                    <div style={{ marginTop: '10px' }} className="agencyProfilePeriodId">
                      <p><i className="fas fa-life-ring"></i> Remember that ANY action performed at this level will be applied to ALL the related Client Forecasts. We strongly recommend that you carefully review any action before changing the status of the forecast periods.</p>
                    </div>
                    <div className="clientProfilePeriodId">
                      <div style={{ marginTop: '10px' }}></div>
                    </div>
                    <div id="periodWorflow" className="button-toolbar" style={{ float: 'none', display: 'flex' }}>
                      <button
                        id="openManageperiod"
                        type="button"
                        title="Reopens the forecast for furher modifications"
                        className="k-button button"
                      >
                        Open
                      </button>
                      <button
                        id="submitManageperiod"
                        type="button"
                        title="Submit the forecast"
                        className="k-button button"
                      >
                        Submit
                      </button>
                      <button
                        id="approvedManageperiod"
                        type="button"
                        title="Approve the forecast"
                        className="k-button button"
                      >
                        Aprrove
                      </button>
                      <button
                        id="closedManageperiod"
                        type="button"
                        title="Close the forecast"
                        className="k-button button"
                      >
                        Close
                      </button>
                      <button
                        id="overwriteManageperiod"
                        type="button"
                        title="Overwrite the forecast"
                        className="k-button button"
                      >
                        Copy
                      </button>
                      <div className="clientProfilePeriodId">
                        <button
                          id="populateManageperiod"
                          type="button"
                          title="Populate the forecast"
                          className="k-button button"
                        >
                          Populate
                        </button>
                        <button
                          id="cleanManageperiod"
                          type="button"
                          title="Clean the forecast"
                          className="k-button button"
                        >
                          Clean
                        </button>
                      </div>
                    </div>
                  </div>
                  <div id="ClosingStatusChart" style={{ display: 'none' }}>
                    <div className="agencyProfilePeriodId">
                      <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
                        <div className="criteria-chart"></div>
                      </div>
                      <h3>&nbsp;</h3>
                      <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
                        <div className="secondcriteria-chart"></div>
                      </div>
                    </div>
                    <div className="clientProfilePeriodId">
                      <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
                        <div className="clientcriteria-chart"></div>
                      </div>
                    </div>
                  </div>
                  <div id="NoData" style={{ display: 'none' }}>
                    <p>
                      <i className="fas fa-bug"></i>The selected PERIOD does not have any spend. It is completely empty.
                      For further information please contact your Forecast Administrator.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div id="dialogSelect">
              <div className="dialogSelectContent">
                <input id="filterTextselect" type="text" className="k-textbox" placeholder="Search" />
                <div className="selectAll">
                  <input type="checkbox" id="chbAll" className="k-checkbox k-checkbox-md k-rounded-md" />
                  <label className="k-checkbox-label" htmlFor="chbAll">
                    Select All
                  </label>
                  <span id="result">0 elements selected</span>
                </div>
                <div id="treeviewSelect_0" className="treeviewSelect" style={{ minHeight: '400px' }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export function IndexDealPeriodPage(): React.ReactElement {
  const currentSecurity = useSecurity('Trading', 'AgencyProfile');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/AgencyProfile/IndexTree.custom.js',
          'Areas/Trading/Scripts/jquery.shared.custom.js',
        ],
        'initSearch',
        { area: 1 },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <h2 style={{ paddingTop: '1em' }}>Forecast Periods Hierarchy</h2>
      <div
        id="main-section-content"
        className="row"
        style={{ paddingTop: '0 !important', backgroundColor: 'inherit', height: 'calc(100vh - 11rem)' }}
      >
        <div id="agencyprofile-details" className="col-md-4" style={{ height: '100%' }}>
          <div
            className="k-content"
            style={{ paddingTop: '0', height: '100%', marginTop: '0', paddingBottom: '0', marginBottom: '0' }}
          >
            <div
              id="treeview-container"
              style={{ backgroundColor: 'rgba(10, 39, 86, 0.05)', height: '100%', overflow: 'auto', padding: '1em' }}
            >
              <div id="treeview-left"></div>
            </div>
          </div>
        </div>
        <div id="agencyprofile-content" className="col-md-8" style={{ height: '100%', overflow: 'auto' }}>
          <div id="manageperiod-Content">
            <div id="manageperiod">
              <div id="mode1" style={{ display: 'none' }}>
                <h3>Creation Of Forecast Periods</h3>
                <div id="manageperiod-addperiod" className="k-content row">
                  <div>
                    <p>Create ALL periods for an specific year with a default break criteria</p>
                  </div>
                  <div className="col-md-6">
                    <label>Forecast Year</label>
                    <input id="inputForecastYear" />
                    <i
                      className="fas fa-info-circle"
                      id="iAvailableYear"
                      style={{ display: 'none', color: '#dc3545' }}
                      title="There are already PERIODS on the select year with spend data. Please validate the information and delete all of them prior to create new configuration."
                    ></i>
                  </div>

                  <div className="col-md-6">
                    <label>Closing Criteria</label>
                    <input id="inputBreakCriteriaClosing" />
                  </div>
                  <div className="col-md-6">
                    <label>Break Criteria</label>
                    <input id="inputBreakCriteria" />
                  </div>
                  <div className="col-md-6">
                    <label>Status</label>
                    <input id="inputStatus" />
                  </div>
                  <div className="clear"></div>
                  <ul id="pWarnings" style={{ display: 'none' }}>
                    <li>
                      <i className="fas fa-info-circle"></i>There are already PERIODS on the select year with spend
                      data. Please validate the information and delete all of them prior to create new configuration.
                    </li>
                    <li>
                      <div className="field checkbox-container" style={{ width: '500px' }}>
                        <label>This action may cause data loss; do you want to continue anyway? </label>
                        <input
                          id="inputContinueAnyway"
                          aria-label="This action may cause data loss; do you want to continue anyway?"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="clear"></div>
                <section className="button-toolbar">
                  <div className="left">
                    <button id="cancelManageperiod" type="button" className="k-button button">
                      Cancel
                    </button>
                  </div>
                  <div className="right">
                    <button id="acceptManagePeriod" type="button" className="k-button button k-primary">
                      Save
                    </button>
                  </div>
                </section>
              </div>
              <div id="mode02" style={{ display: 'none' }}>
                <h3>Current Status of the Period</h3>
                <div id="manageperiod-closingperiod">
                  <div className="dialog-content">
                    <div
                      id="inputAgencyProfileStatus"
                      className="dialog-status"
                      title="This is the CURRENT status of the forecast"
                    ></div>
                    <div className="dialog-item">
                      <div id="inputAgencyProfilePeriod" className="dialog-title"></div>
                    </div>
                    <div className="clear"></div>
                    <div className="dialog-item">
                      <label>Total Clients</label>
                      <div id="inputTotalClientProfile" className="dialog-kpi"></div>
                      <i className="fas fa-thermometer-empty" id="iNotOK" style={{ display: 'none' }}></i>
                      <i className="fas fa-thermometer-full" id="iOK" style={{ display: 'none' }}></i>
                      <div id="inputTotalClientProfilePercent" className="dialog-progress"></div>
                    </div>
                    <div className="dialog-item">
                      <label>Total Forecast</label>
                      <div id="inputTotalForecast" className="dialog-spend"></div>
                    </div>
                    <div className="dialog-item">
                      <label>Total Ordered</label>
                      <div id="inputTotalOrdered" className="dialog-spend"></div>
                    </div>
                    <div className="dialog-item">
                      <label>YTD Total Budget</label>
                      <div id="inputTotalSpend" className="dialog-spend"></div>
                    </div>
                    <div style={{ marginTop: '10px' }} className="agencyProfilePeriodId">
                      <p><i className="fas fa-life-ring"></i> Remember that ANY action performed at this level will be applied to ALL the related Client Forecasts. We strongly recommend that you carefully review any action before changing the status of the forecast periods.</p>
                    </div>
                    <div className="clientProfilePeriodId">
                      <div style={{ marginTop: '10px' }}></div>
                    </div>
                    <div id="periodWorflow" className="button-toolbar" style={{ float: 'none', display: 'flex' }}>
                      <button
                        id="openManageperiod"
                        type="button"
                        title="Reopens the forecast for furher modifications"
                        className="k-button button"
                      >
                        Open
                      </button>
                      <button
                        id="submitManageperiod"
                        type="button"
                        title="Submit the forecast"
                        className="k-button button"
                      >
                        Submit
                      </button>
                      <button
                        id="approvedManageperiod"
                        type="button"
                        title="Approve the forecast"
                        className="k-button button"
                      >
                        Aprrove
                      </button>
                      <button
                        id="closedManageperiod"
                        type="button"
                        title="Close the forecast"
                        className="k-button button"
                      >
                        Close
                      </button>
                      <button
                        id="overwriteManageperiod"
                        type="button"
                        title="Overwrite the forecast"
                        className="k-button button"
                      >
                        Copy
                      </button>
                      <div className="clientProfilePeriodId">
                        <button
                          id="populateManageperiod"
                          type="button"
                          title="Populate the forecast"
                          className="k-button button"
                        >
                          Populate
                        </button>
                        <button
                          id="cleanManageperiod"
                          type="button"
                          title="Clean the forecast"
                          className="k-button button"
                        >
                          Clean
                        </button>
                        <button
                          id="applyPriorManageperiod"
                          type="button"
                          title="Apply Prior the forecast"
                          className="k-button button"
                        >
                          Apply Prior
                        </button>
                      </div>
                    </div>
                  </div>
                  <div id="ClosingStatusChart" style={{ display: 'none' }}>
                    <div className="agencyProfilePeriodId">
                      <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
                        <div className="criteria-chart"></div>
                      </div>
                      <h3>&nbsp;</h3>
                      <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
                        <div className="secondcriteria-chart"></div>
                      </div>
                    </div>
                    <div className="clientProfilePeriodId">
                      <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
                        <div className="clientcriteria-chart"></div>
                      </div>
                    </div>
                  </div>
                  <div id="NoData" style={{ display: 'none' }}>
                    <p>
                      <i className="fas fa-bug"></i>The selected PERIOD does not have any spend. It is completely empty.
                      For further information please contact your Forecast Administrator.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div id="dialogSelect">
              <div className="dialogSelectContent">
                <input id="filterTextselect" type="text" className="k-textbox" placeholder="Search" />
                <div className="selectAll">
                  <input type="checkbox" id="chbAll" className="k-checkbox k-checkbox-md k-rounded-md" />
                  <label className="k-checkbox-label" htmlFor="chbAll">
                    Select All
                  </label>
                  <span id="result">0 elements selected</span>
                </div>
                <div id="treeviewSelect_1" className="treeviewSelect" style={{ minHeight: '400px' }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
