import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function MasterCountryNewPage(): React.ReactElement {
  const [state, setState] = useState<IMasterCountry>({ Id: ""  });
  const currentSecurity = useSecurity('Master', 'Country');
  let updatedState: IMasterCountry = { Id: ""  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.Preferred = false;
            getPreferredDetails("Master", "Currency").then((result) => {
              const resultcurrency: any[] = result.Value;
              if (resultcurrency && resultcurrency.length > 0)
                updatedState = { ...updatedState, CurrencyId: resultcurrency[0].Id, CurrencyDisplayValue: resultcurrency[0].Display };
            getPreferredDetails("Master", "Language").then((result) => {
              const resultlanguage: any[] = result.Value;
              if (resultlanguage && resultlanguage.length > 0)
                updatedState = { ...updatedState, LanguageId: resultlanguage[0].Id, LanguageDisplayValue: resultlanguage[0].Display };
      setState(updatedState);
      appendScriptsCallback(["Areas/Master/Scripts/Resources/en/res.Country.en.js",
        "Areas/Master/Scripts/Resources/en/Types.en.js",
        "Areas/Master/Scripts/Enums.js",
        "Areas/Master/Scripts/Country/View.js"], "initNewMasterCountry", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
            });
            });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="Country" className="controls-container default block">
              <h3>Create a New <b>Countries</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="CountryCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="CountryLabelBlockedCode">Blocked</label>
				<input id="CountryCheckBlockedCode" defaultChecked name="CountryCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="CountryEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('CountryEnglishName','CountryNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="CountryNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="CountryEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('CountryEnglishShortName','CountryNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="CountryNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="LanguageId">Language</label><select className="" id="CountryLanguageId" name="LanguageId" defaultValue={state.LanguageId}   required data-required-msg="Language is required"  >{state.LanguageId && <option value={state.LanguageId}>{state.LanguageDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="ISOCulture">ISO Code</label><input  required data-required-msg="ISO Code is required"  id="CountryISOCulture" name="ISOCulture" type="text" defaultValue={state.ISOCulture} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="CurrencyId">Currency</label><select className="" id="CountryCurrencyId" name="CurrencyId" defaultValue={state.CurrencyId}   required data-required-msg="Currency is required"  >{state.CurrencyId && <option value={state.CurrencyId}>{state.CurrencyDisplayValue}</option>}</select></div>
		<div className="col-md-2"><label htmlFor="Preferred">Preferred</label><input type="checkbox" id="CountryPreferred" name="Preferred" defaultChecked={state.Preferred} /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="CountrySourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarCountry">
                <div className="left">
                  <button id="cancelCountryButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveCountryButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface IMasterCountry {
  EnglishName?: string,
  EnglishShortName?: string,
  NativeName?: string,
  NativeShortName?: string,
  LanguageId?: string,
  LanguageDisplayValue?: string,
  ISOCulture?: string,
  CurrencyId?: string,
  CurrencyDisplayValue?: string,
  Preferred?: boolean,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}


export function MasterCountryDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IMasterCountry>({ Id: ""  });
  const currentSecurity = useSecurity('Master', 'Country');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Master", "Country", id).then(result => {
        setState(result.Value[0] as IMasterCountry);
        appendScriptsCallback(["Areas/Master/Scripts/Resources/en/res.Country.en.js",
        "Areas/Master/Scripts/Resources/en/Types.en.js",
        "Areas/Master/Scripts/Enums.js",
        "Areas/Master/Scripts/Country/View.js"
        ,"Areas/Master/Scripts/Resources/en/res.Province.en.js"
        ,"Areas/Master/Scripts/Resources/en/res.City.en.js"
        ,"Areas/Master/Scripts/Province/View.js"
        ], "initDetailMasterCountry", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadMasterCountryState'] = (data) => {
    setState(data.Value[0] as IMasterCountry);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="Country" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Countries: <span>{state.Display}</span><span id="CountryToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.LanguageId && <span className='element'><label>Language: <label className="strong">{state.LanguageDisplayValue}</label></label></span>}
                  <span className='element'><label>ISO Code: <label className="strong">{state.ISOCulture}</label></label></span>
                  {state.CurrencyId && <span className='element'><label>Currency: <label className="strong">{state.CurrencyDisplayValue}</label></label></span>}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="CountryCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="CountryLabelBlockedCode">Blocked</label>
				<input id="CountryCheckBlockedCode" defaultChecked name="CountryCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="CountryEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('CountryEnglishName','CountryNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="CountryNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="CountryEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('CountryEnglishShortName','CountryNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="CountryNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="LanguageId">Language</label><select className="" id="CountryLanguageId" name="LanguageId" defaultValue={state.LanguageId}   required data-required-msg="Language is required"  >{state.LanguageId && <option value={state.LanguageId}>{state.LanguageDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="ISOCulture">ISO Code</label><input  required data-required-msg="ISO Code is required"  id="CountryISOCulture" name="ISOCulture" type="text" defaultValue={state.ISOCulture} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="CurrencyId">Currency</label><select className="" id="CountryCurrencyId" name="CurrencyId" defaultValue={state.CurrencyId}   required data-required-msg="Currency is required"  >{state.CurrencyId && <option value={state.CurrencyId}>{state.CurrencyDisplayValue}</option>}</select></div>
		<div className="col-md-2"><label htmlFor="Preferred">Preferred</label><input type="checkbox" id="CountryPreferred" name="Preferred" defaultChecked={state.Preferred} /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="CountrySourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarCountry">
                <div className="left">
                  <button id="cancelCountryButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveCountryButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="associationCountryProvince">
              <div className="k-block grid-details">
                <h2>Provinces</h2>
                <div id="ProvinceGrid" className="grid-control"></div>
              </div>
            </div>
        </section>
      </section>
    </div>
  );
}


