import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function MasterClientNewPage(): React.ReactElement {
  const [state, setState] = useState<IMasterClient>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Master', 'Client');
  let updatedState: IMasterClient = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.Status = 4;
            updatedState.Green = 0;
            getPreferredDetails("Master", "Country").then((result) => {
              const resultcountry: any[] = result.Value;
              if (resultcountry && resultcountry.length > 0)
                updatedState = { ...updatedState, CountryId: resultcountry[0].Id, CountryDisplayValue: resultcountry[0].Display };
      setState(updatedState);
      appendScriptsCallback(["Areas/Master/Scripts/Resources/en/res.Client.en.js",
        "Areas/Master/Scripts/Resources/en/Types.en.js",
        "Areas/Master/Scripts/Enums.js",
        "Areas/Master/Scripts/Client/View.js"], "initNewMasterClient", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
            });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="Client" className="controls-container default block">
              <h3>Create a New <b>Clients</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="ClientCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="ClientLabelBlockedCode">Blocked</label>
				<input id="ClientCheckBlockedCode" defaultChecked name="ClientCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="ClientEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('ClientEnglishName','ClientNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="ClientNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="ClientEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('ClientEnglishShortName','ClientNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="ClientNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="BuyingAgencyId">Buying Agency</label><select className="" id="ClientBuyingAgencyId" name="BuyingAgencyId" defaultValue={state.BuyingAgencyId}  >{state.BuyingAgencyId && <option value={state.BuyingAgencyId}>{state.BuyingAgencyDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="CountryId">Country</label><select className="" id="ClientCountryId" name="CountryId" defaultValue={state.CountryId}  >{state.CountryId && <option value={state.CountryId}>{state.CountryDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="AgencyId">Agency</label><select className="" id="ClientAgencyId" name="AgencyId" defaultValue={state.AgencyId}   required data-required-msg="Agency is required"  >{state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterClientId">Master Client</label><select className="" id="ClientMasterClientId" name="MasterClientId" defaultValue={state.MasterClientId}  >{state.MasterClientId && <option value={state.MasterClientId}>{state.MasterClientDisplayValue}</option>}</select></div>
		<div className="col-md-2" ><label htmlFor="CorporateColor">Corporate Color</label><input  id="ClientCorporateColor" name="CorporateColor"  type="text" defaultValue={state.CorporateColor} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Green" className="required">Green</label>
      <select className="" id="ClientGreen" name="Green" value={state.Green} required data-required-msg="Green is required" >
        <option value="0">Yes</option>
        <option value="1">No</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Status" className="required">Status</label>
      <select className="" id="ClientStatus" name="Status" value={state.Status} required data-required-msg="Status is required" >
        <option value="0">Prospect</option>
        <option value="1">Pursuing</option>
        <option value="2">Pitching</option>
        <option value="3">Pending</option>
        <option value="4">Effective</option>
      </select>
    </div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="ClientSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarClient">
                <div className="left">
                  <button id="cancelClientButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveClientButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface IMasterClient {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  EnglishName?: string,
  EnglishShortName?: string,
  NativeName?: string,
  NativeShortName?: string,
  BuyingAgencyId?: string,
  BuyingAgencyDisplayValue?: string,
  CountryId?: string,
  CountryDisplayValue?: string,
  AgencyId?: string,
  AgencyDisplayValue?: string,
  MasterClientId?: string,
  MasterClientDisplayValue?: string,
  CorporateColor?: string,
  Green?: number,
  Status?: number,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum ClientStatusEnum { 'Prospect' = 0, 'Pursuing' = 1, 'Pitching' = 2, 'Pending' = 3, 'Effective' = 4 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum ApplianceModeEnum { 'Yes' = 0, 'No' = 1 }

export function MasterClientDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IMasterClient>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Master', 'Client');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Master", "Client", id).then(result => {
        setState(result.Value[0] as IMasterClient);
        appendScriptsCallback(["Areas/Master/Scripts/Resources/en/res.Client.en.js",
        "Areas/Master/Scripts/Resources/en/Types.en.js",
        "Areas/Master/Scripts/Enums.js",
        "Areas/Master/Scripts/Client/View.js"
        ,"Areas/Master/Scripts/Resources/en/res.Product.en.js"
        ,"Areas/Master/Scripts/Product/View.js"
        ], "initDetailMasterClient", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadMasterClientState'] = (data) => {
    setState(data.Value[0] as IMasterClient);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="Client" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Clients: <span>{state.Display}</span><span id="ClientToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.BuyingAgencyId && <span className='element'><label>Buying Agency: <label className="strong">{state.BuyingAgencyDisplayValue}</label></label></span>}
                  {state.CountryId && <span className='element'><label>Country: <label className="strong">{state.CountryDisplayValue}</label></label></span>}
                  {state.AgencyId && <span className='element'><label>Agency: <label className="strong">{state.AgencyDisplayValue}</label></label></span>}
                </div>
                <div className='content-line'>
                  {state.MasterClientId && <span className='element'><label>Master Client: <label className="strong">{state.MasterClientDisplayValue}</label></label></span>}
                  <span className='element'><label>Green: <label className="strong">{state.Green != undefined ? ApplianceModeEnum[state.Green] : ''}</label></label></span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="ClientCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="ClientLabelBlockedCode">Blocked</label>
				<input id="ClientCheckBlockedCode" defaultChecked name="ClientCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="ClientEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('ClientEnglishName','ClientNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="ClientNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="ClientEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('ClientEnglishShortName','ClientNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="ClientNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="BuyingAgencyId">Buying Agency</label><select className="" id="ClientBuyingAgencyId" name="BuyingAgencyId" defaultValue={state.BuyingAgencyId}  >{state.BuyingAgencyId && <option value={state.BuyingAgencyId}>{state.BuyingAgencyDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="CountryId">Country</label><select className="" id="ClientCountryId" name="CountryId" defaultValue={state.CountryId}  >{state.CountryId && <option value={state.CountryId}>{state.CountryDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="AgencyId">Agency</label><select className="" id="ClientAgencyId" name="AgencyId" defaultValue={state.AgencyId}   required data-required-msg="Agency is required"  >{state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterClientId">Master Client</label><select className="" id="ClientMasterClientId" name="MasterClientId" defaultValue={state.MasterClientId}  >{state.MasterClientId && <option value={state.MasterClientId}>{state.MasterClientDisplayValue}</option>}</select></div>
		<div className="col-md-2" ><label htmlFor="CorporateColor">Corporate Color</label><input  id="ClientCorporateColor" name="CorporateColor"  type="text" defaultValue={state.CorporateColor} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Green" className="required">Green</label>
      <select className="" id="ClientGreen" name="Green" value={state.Green} required data-required-msg="Green is required" >
        <option value="0">Yes</option>
        <option value="1">No</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Status" className="required">Status</label>
      <select className="" id="ClientStatus" name="Status" value={state.Status} required data-required-msg="Status is required" >
        <option value="0">Prospect</option>
        <option value="1">Pursuing</option>
        <option value="2">Pitching</option>
        <option value="3">Pending</option>
        <option value="4">Effective</option>
      </select>
    </div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="ClientSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="ClientSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="ClientSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarClient">
                <div className="left">
                  <button id="cancelClientButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveClientButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="associationClientProduct">
              <div className="k-block grid-details">
                <h2>Product</h2>
                <div id="ProductGrid" className="grid-control"></div>
              </div>
            </div>
        </section>
      </section>
    </div>
  );
}


