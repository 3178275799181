import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../helpers/Utils';

export function InsightsViewerPage(): React.ReactElement {
  const currentSecurity = useSecurity('Shared', 'InsightsViewer');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(['InsightsViewer.js'], 'initInsightsViewer', {
        appSettings: currentSecurity.profile.AppSettings.Values,
      });
    }
  }, []);
  return (
    <div className="container-fluid default-dashboard">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div id="content-reportTypes" className="k-content k-content-box">
            <h2>Report Type</h2>
          </div>
        </div>
        <div className="col-lg-8 col-md-6 col-sm-12">
          <div className="k-content k-content-box" id="content-reportParameters">
            <h2>Report Setup & Parameters</h2>
            <form id="form">
              <div id="ParametersList"></div>
            </form>
            <section className="button-toolbar">
              <div className="left"></div>
              <div className="right">
                <button className="k-button k-primary" id="generateReport">
                  Generate
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="grid-details" id="content-report" style={{ paddingTop: '1em' }}>
          <div id="InsightsViewerContainer"></div>
        </div>
      </div>
    </div>
  );
}
