import React from 'react';
import ReactDOM from 'react-dom';
import { Dialog as KendoDialog, DialogProps } from '@progress/kendo-react-dialogs';

const Dialog = (props: DialogProps) => {
  React.useEffect(() => {
    const body = document.querySelector('body');
    body?.classList.add('body-overflow');
    return () => body?.classList.remove('body-overflow');
  }, []);

  return ReactDOM.createPortal(
    <div className="nmodal">
      <KendoDialog {...props} className="nmodal__dialog" autoFocus />
    </div>,
    document.querySelector('.modal-root') as any,
  );
};

export default Dialog;
