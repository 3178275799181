import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function TradingClientProfileNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingClientProfile>({ Id: "" , LoadingInfo: {} , Sequence: "0"  });
  const currentSecurity = useSecurity('Trading', 'ClientProfile');
  let updatedState: ITradingClientProfile = { Id: "" , LoadingInfo: {} , Sequence: "0"  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.GroupAccountDirectorId = currentSecurity.profile.UserId;
            updatedState.GroupAccountDirectorDisplayValue = currentSecurity.profile.LoginName;
            updatedState.SeniorVicePresidentId = currentSecurity.profile.UserId;
            updatedState.SeniorVicePresidentDisplayValue = currentSecurity.profile.LoginName;
            updatedState.AccountType = 0;
            updatedState.NetGross = 0;
            updatedState.Category = 0;
            updatedState.MappingType = 0;
            getPreferredDetails("Master", "Currency").then((result) => {
              const resultcurrency: any[] = result.Value;
              if (resultcurrency && resultcurrency.length > 0)
                updatedState = { ...updatedState, CurrencyId: resultcurrency[0].Id, CurrencyDisplayValue: resultcurrency[0].Display };
      setState(updatedState);
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.ClientProfile.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/ClientProfile/View.js"], "initNewTradingClientProfile", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
            });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="ClientProfile" className="controls-container default block">
              <h3>Create a New <b>Client Profiles</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="ClientProfileCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="ClientProfileLabelBlockedCode">Blocked</label>
				<input id="ClientProfileCheckBlockedCode" defaultChecked name="ClientProfileCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="AgencyId">Agency</label><select className="" id="ClientProfileAgencyId" name="AgencyId" defaultValue={state.AgencyId}   required data-required-msg="Agency is required"  >{state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="ClientProfileGroupId">Client Profile Group</label><select className="" id="ClientProfileClientProfileGroupId" name="ClientProfileGroupId" defaultValue={state.ClientProfileGroupId}  >{state.ClientProfileGroupId && <option value={state.ClientProfileGroupId}>{state.ClientProfileGroupDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="Alias">Alias</label><input  required data-required-msg="Alias is required"  id="ClientProfileAlias" name="Alias" type="text" defaultValue={state.Alias} className="k-input k-input-solid k-textbox" /></div>
        <div className="clear"></div>
		<div className="field textareafield"><label htmlFor="Comments">Comments</label><div><textarea className="k-input k-textbox" cols={20} id="ClientProfileComments" name="Comments" maxLength={2147483647} rows={5} defaultValue={state.Comments}></textarea></div></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="AccountType" className="required">Account Type</label>
      <select className="" id="ClientProfileAccountType" name="AccountType" value={state.AccountType} required data-required-msg="Account Type is required" >
        <option value="0">Commission</option>
        <option value="1">Fee</option>
        <option value="2">Both</option>
        <option value="3">None</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Category" className="required">Cartesis Category</label>
      <select className="" id="ClientProfileCategory" name="Category" value={state.Category} required data-required-msg="Cartesis Category is required" >
        <option value="0">Designated</option>
        <option value="1">Significant Local</option>
        <option value="2">Other</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="MappingType" className="required">Mapping Type</label>
      <select className="" id="ClientProfileMappingType" name="MappingType" value={state.MappingType} required data-required-msg="Mapping Type is required" >
        <option value="0">Default</option>
        <option value="1">Custom</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="NetGross" className="required">Net/Gross</label>
      <select className="" id="ClientProfileNetGross" name="NetGross" value={state.NetGross} required data-required-msg="Net/Gross is required" >
        <option value="0">Net</option>
        <option value="1">Gross</option>
      </select>
    </div>
		<div className="col-md-4 combobox  " ><label htmlFor="CurrencyId">Currency</label><select className="" id="ClientProfileCurrencyId" name="CurrencyId" defaultValue={state.CurrencyId}   required data-required-msg="Currency is required"  >{state.CurrencyId && <option value={state.CurrencyId}>{state.CurrencyDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="GroupAccountDirectorId">Group Account Director</label><select className="" id="ClientProfileGroupAccountDirectorId" name="GroupAccountDirectorId" defaultValue={state.GroupAccountDirectorId}  >{state.GroupAccountDirectorId && <option value={state.GroupAccountDirectorId}>{state.GroupAccountDirectorDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="SeniorVicePresidentId">Senior  Vice  President</label><select className="" id="ClientProfileSeniorVicePresidentId" name="SeniorVicePresidentId" defaultValue={state.SeniorVicePresidentId}  >{state.SeniorVicePresidentId && <option value={state.SeniorVicePresidentId}>{state.SeniorVicePresidentDisplayValue}</option>}</select></div>
		<div className="col-md-2" ><label htmlFor="Sequence" className="required">Sequence</label><input data-required-msg="Sequence is required" data-val="true" data-val-number="The field Sequence must be a number." defaultValue={state.Sequence} data-val-required="The Sequence field is required." id="ClientProfileSequence" min="-2147483648" name="Sequence" required type="text" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="ClientProfileSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarClientProfile">
                <div className="left">
                  <button id="cancelClientProfileButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveClientProfileButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingClientProfile {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  Sequence?: string,
  AgencyId?: string,
  AgencyDisplayValue?: string,
  ClientProfileGroupId?: string,
  ClientProfileGroupDisplayValue?: string,
  Alias?: string,
  Comments?: string,
  AccountType?: number,
  Category?: number,
  MappingType?: number,
  NetGross?: number,
  CurrencyId?: string,
  CurrencyDisplayValue?: string,
  GroupAccountDirectorId?: string,
  GroupAccountDirectorDisplayValue?: string,
  SeniorVicePresidentId?: string,
  SeniorVicePresidentDisplayValue?: string,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum NetGrossFlagEnum { 'Net' = 0, 'Gross' = 1 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum RevenueAccountEnum { 'Commission' = 0, 'Fee' = 1, 'Both' = 2, 'None' = 3 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DataMappingTypeEnum { 'Default' = 0, 'Custom' = 1 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum FinanceCategoryEnum { 'Designated' = 0, 'Significant Local' = 1, 'Other' = 2 }

export function TradingClientProfileDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingClientProfile>({ Id: "" , LoadingInfo: {} , Sequence: "0"  });
  const currentSecurity = useSecurity('Trading', 'ClientProfile');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Trading", "ClientProfile", id).then(result => {
        setState(result.Value[0] as ITradingClientProfile);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.ClientProfile.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/ClientProfile/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.ClientProfileCommission.en.js"
        ,"Areas/Trading/Scripts/ClientProfileCommission/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.MediaToolsClient.en.js"
        ,"Areas/Trading/Scripts/MediaToolsClient/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.ClientProfileFee.en.js"
        ,"Areas/Trading/Scripts/ClientProfileFee/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.ClientProfileMap.en.js"
        ,"Areas/Trading/Scripts/ClientProfileMap/View.js"
        ], "initDetailTradingClientProfile", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadTradingClientProfileState'] = (data) => {
    setState(data.Value[0] as ITradingClientProfile);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="ClientProfile" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Client Profiles: <span>{state.Display}</span><span id="ClientProfileToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.AgencyId && <span className='element'><label>Agency: <label className="strong">{state.AgencyDisplayValue}</label></label></span>}
                  {state.ClientProfileGroupId && <span className='element'><label>Client Profile Group: <label className="strong">{state.ClientProfileGroupDisplayValue}</label></label></span>}
                  {state.CurrencyId && <span className='element'><label>Currency: <label className="strong">{state.CurrencyDisplayValue}</label></label></span>}
                </div>
                <div className='content-line'>
                  {state.GroupAccountDirectorId && <span className='element'><label>Group Account Director: <label className="strong">{state.GroupAccountDirectorDisplayValue}</label></label></span>}
                  {state.SeniorVicePresidentId && <span className='element'><label>Senior  Vice  President: <label className="strong">{state.SeniorVicePresidentDisplayValue}</label></label></span>}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="ClientProfileCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="ClientProfileLabelBlockedCode">Blocked</label>
				<input id="ClientProfileCheckBlockedCode" defaultChecked name="ClientProfileCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="AgencyId">Agency</label><select className="" id="ClientProfileAgencyId" name="AgencyId" defaultValue={state.AgencyId}   required data-required-msg="Agency is required"  >{state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="ClientProfileGroupId">Client Profile Group</label><select className="" id="ClientProfileClientProfileGroupId" name="ClientProfileGroupId" defaultValue={state.ClientProfileGroupId}  >{state.ClientProfileGroupId && <option value={state.ClientProfileGroupId}>{state.ClientProfileGroupDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="Alias">Alias</label><input  required data-required-msg="Alias is required"  id="ClientProfileAlias" name="Alias" type="text" defaultValue={state.Alias} className="k-input k-input-solid k-textbox" /></div>
        <div className="clear"></div>
		<div className="field textareafield"><label htmlFor="Comments">Comments</label><div><textarea className="k-input k-textbox" cols={20} id="ClientProfileComments" name="Comments" maxLength={2147483647} rows={5} defaultValue={state.Comments}></textarea></div></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="AccountType" className="required">Account Type</label>
      <select className="" id="ClientProfileAccountType" name="AccountType" value={state.AccountType} required data-required-msg="Account Type is required" >
        <option value="0">Commission</option>
        <option value="1">Fee</option>
        <option value="2">Both</option>
        <option value="3">None</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Category" className="required">Cartesis Category</label>
      <select className="" id="ClientProfileCategory" name="Category" value={state.Category} required data-required-msg="Cartesis Category is required" >
        <option value="0">Designated</option>
        <option value="1">Significant Local</option>
        <option value="2">Other</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="MappingType" className="required">Mapping Type</label>
      <select className="" id="ClientProfileMappingType" name="MappingType" value={state.MappingType} required data-required-msg="Mapping Type is required" >
        <option value="0">Default</option>
        <option value="1">Custom</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="NetGross" className="required">Net/Gross</label>
      <select className="" id="ClientProfileNetGross" name="NetGross" value={state.NetGross} required data-required-msg="Net/Gross is required" >
        <option value="0">Net</option>
        <option value="1">Gross</option>
      </select>
    </div>
		<div className="col-md-4 combobox  " ><label htmlFor="CurrencyId">Currency</label><select className="" id="ClientProfileCurrencyId" name="CurrencyId" defaultValue={state.CurrencyId}   required data-required-msg="Currency is required"  >{state.CurrencyId && <option value={state.CurrencyId}>{state.CurrencyDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="GroupAccountDirectorId">Group Account Director</label><select className="" id="ClientProfileGroupAccountDirectorId" name="GroupAccountDirectorId" defaultValue={state.GroupAccountDirectorId}  >{state.GroupAccountDirectorId && <option value={state.GroupAccountDirectorId}>{state.GroupAccountDirectorDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="SeniorVicePresidentId">Senior  Vice  President</label><select className="" id="ClientProfileSeniorVicePresidentId" name="SeniorVicePresidentId" defaultValue={state.SeniorVicePresidentId}  >{state.SeniorVicePresidentId && <option value={state.SeniorVicePresidentId}>{state.SeniorVicePresidentDisplayValue}</option>}</select></div>
		<div className="col-md-2" ><label htmlFor="Sequence" className="required">Sequence</label><input data-required-msg="Sequence is required" data-val="true" data-val-number="The field Sequence must be a number." defaultValue={state.Sequence} data-val-required="The Sequence field is required." id="ClientProfileSequence" min="-2147483648" name="Sequence" required type="text" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="ClientProfileSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="ClientProfileSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="ClientProfileSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarClientProfile">
                <div className="left">
                  <button id="cancelClientProfileButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveClientProfileButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="ClientProfileTabstrip">
                <ul>
                    <li id="ClientProfileClientProfileCommission" className="k-active"><i className="fas fa-percent"></i><span className="labeltext">Default Commissions</span></li>
                    <li id="ClientProfileMediaToolsClient"><i className="fas fa-chart-line"></i><span className="labeltext">MediaTools Client Profile</span></li>
                    <li id="ClientProfileClientProfileFee"><i className="fas fa-calculator"></i><span className="labeltext">Default Fees</span></li>
                    <li id="ClientProfileClientProfileMap"><i className="fas fa-braille"></i><span className="labeltext">Mappings</span></li>
                </ul>
                <div>
                    <div id="associationClientProfileClientProfileCommission">
                      <div className="k-block grid-details">
                        <h2>Default Commissions</h2>
                        <div id="ClientProfileCommissionGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
                <div>
                    <div id="associationClientProfileMediaToolsClient">
                      <div className="k-block grid-details">
                        <h2>MediaTools Client Profile</h2>
                        <div id="MediaToolsClientGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
                <div>
                    <div id="associationClientProfileClientProfileFee">
                      <div className="k-block grid-details">
                        <h2>Default Fees</h2>
                        <div id="ClientProfileFeeGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
                <div>
                    <div id="associationClientProfileClientProfileMap">
                      <div className="k-block grid-details">
                        <h2>Mappings</h2>
                        <div id="ClientProfileMapGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
            </div>
        </section>
      </section>
    </div>
  );
}


