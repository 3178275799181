import { ISecurityProfileProps } from '../../components/services/GraphQLShared';
import { actions as profileActions } from '../actions/profileActions';
import { asyncStates, IAsyncState } from '../../config/Enums';

export interface ProfileState {
  profile: ISecurityProfileProps | null;
  profileStatus: IAsyncState;
  profileError: any;
  isAuthorized: boolean;
}

export const initialState: ProfileState = {
  profile: null,
  profileStatus: asyncStates.idle,
  profileError: null,
  isAuthorized: false,
};

export function profileReducer(state = initialState, action: any): ProfileState {
  switch (action.type) {
    case profileActions.GET_SECURITY_PROFILE_PENDING:
      return { ...state, profileStatus: asyncStates.pending };
    case profileActions.GET_SECURITY_PROFILE_RESOLVED:
      const { profile } = action;
      return {
        profile,
        profileStatus: asyncStates.resolved,
        profileError: null,
        isAuthorized: profile.State == 3,
      };
    case profileActions.GET_SECURITY_PROFILE_REJECTED:
      const { error } = action;
      return {
        profile: null,
        profileStatus: asyncStates.rejected,
        profileError: error,
        isAuthorized: false,
      };
    case profileActions.RESET_SECURITY_PROFILE:
      return {
        profile: null,
        profileStatus: asyncStates.idle,
        profileError: null,
        isAuthorized: false,
      };
    default:
      return state;
  }
}
export default profileReducer;
