import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function TradingManualStreamNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingManualStream>({ Id: "" , LoadingInfo: {} , Sequence: "0"  });
  const currentSecurity = useSecurity('Trading', 'ManualStream');
  const updatedState: ITradingManualStream = { Id: "" , LoadingInfo: {} , Sequence: "0"  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      setState(updatedState);
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.ManualStream.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/ManualStream/View.js"], "initNewTradingManualStream", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="ManualStream" className="controls-container default block">
              <h3>Create a New <b>Manual Streams</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="ManualStreamCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="ManualStreamLabelBlockedCode">Blocked</label>
				<input id="ManualStreamCheckBlockedCode" defaultChecked name="ManualStreamCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="Alias">Alias</label><input  required data-required-msg="Alias is required"  id="ManualStreamAlias" name="Alias" type="text" defaultValue={state.Alias} className="k-input k-input-solid k-textbox" /></div>
        <div className="clear"></div>
		<div className="field textareafield"><label htmlFor="Notes">Notes</label><div><textarea className="k-input k-textbox" cols={20} id="ManualStreamNotes" name="Notes" maxLength={2147483647} rows={5} defaultValue={state.Notes}></textarea></div></div>
		<div className="col-md-2" ><label htmlFor="Sequence" className="required">Sequence</label><input data-required-msg="Sequence is required" data-val="true" data-val-number="The field Sequence must be a number." defaultValue={state.Sequence} data-val-required="The Sequence field is required." id="ManualStreamSequence" min="-2147483648" name="Sequence" required type="text" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="ManualStreamSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarManualStream">
                <div className="left">
                  <button id="cancelManualStreamButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveManualStreamButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingManualStream {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  Sequence?: string,
  Alias?: string,
  Notes?: string,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}


export function TradingManualStreamDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingManualStream>({ Id: "" , LoadingInfo: {} , Sequence: "0"  });
  const currentSecurity = useSecurity('Trading', 'ManualStream');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Trading", "ManualStream", id).then(result => {
        setState(result.Value[0] as ITradingManualStream);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.ManualStream.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/ManualStream/View.js"
        ], "initDetailTradingManualStream", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadTradingManualStreamState'] = (data) => {
    setState(data.Value[0] as ITradingManualStream);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="ManualStream" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Manual Streams: <span>{state.Display}</span><span id="ManualStreamToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="ManualStreamCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="ManualStreamLabelBlockedCode">Blocked</label>
				<input id="ManualStreamCheckBlockedCode" defaultChecked name="ManualStreamCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="Alias">Alias</label><input  required data-required-msg="Alias is required"  id="ManualStreamAlias" name="Alias" type="text" defaultValue={state.Alias} className="k-input k-input-solid k-textbox" /></div>
        <div className="clear"></div>
		<div className="field textareafield"><label htmlFor="Notes">Notes</label><div><textarea className="k-input k-textbox" cols={20} id="ManualStreamNotes" name="Notes" maxLength={2147483647} rows={5} defaultValue={state.Notes}></textarea></div></div>
		<div className="col-md-2" ><label htmlFor="Sequence" className="required">Sequence</label><input data-required-msg="Sequence is required" data-val="true" data-val-number="The field Sequence must be a number." defaultValue={state.Sequence} data-val-required="The Sequence field is required." id="ManualStreamSequence" min="-2147483648" name="Sequence" required type="text" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="ManualStreamSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="ManualStreamSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="ManualStreamSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarManualStream">
                <div className="left">
                  <button id="cancelManualStreamButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveManualStreamButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}


