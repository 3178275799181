import React, { useEffect } from 'react';
import { OnDemandIcon } from '../../helpers/IconUtils';
import { DISMISS_NOTIFICATION } from '../../../store/actions/navActions';
import { ProgressBar } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store/reducer/rootReducer';
import { ISignalRMessageProps, SignalRMessageType, SignalRMessageStatus } from '../../../services/SignalRHelper';
import { CommonResources } from '../../../config/CommonResources';
import { Button } from '@progress/kendo-react-buttons';
import { formatDate } from '@progress/kendo-intl';
import { getMinutesFromNow } from '../../helpers/Utils';

const mapState = (state: RootState) => ({
  notifications: state.nav.notifications,
});
const mapDispatch = {
  dismissNotification: (id: string) => ({ type: DISMISS_NOTIFICATION, id: id }),
};
export type IMessageDataProps = ISignalRMessageProps & {
  dismiss: boolean;
};
export const getMessageIcon = (message: IMessageDataProps): JSX.Element => {
  switch (message.type) {
    case SignalRMessageType.Progress:
      return <OnDemandIcon icon="cog" size="lg" spin={message.percent < 100} className={'icon-progress'} />;
    case SignalRMessageType.Info:
      return <OnDemandIcon icon="info-circle" size="lg" className="icon-info" />;
    case SignalRMessageType.Warning:
      return <OnDemandIcon icon="exclamation-circle" size="lg" className="icon-warning" />;
    case SignalRMessageType.Error:
      return <OnDemandIcon icon="times-circle" size="lg" className="icon-error" />;
    default:
      return <OnDemandIcon icon="check-circle" size="lg" className="icon-success" />;
  }
};
export const getMessageType = (message: IMessageDataProps): string => {
  switch (message.type) {
    case SignalRMessageType.Progress:
      return CommonResources.Progress;
    case SignalRMessageType.Info:
      return CommonResources.Info;
    case SignalRMessageType.Warning:
      return CommonResources.Warning;
    case SignalRMessageType.Error:
      return CommonResources.Error;
    default:
      return CommonResources.Success;
  }
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export type IMessageProps = PropsFromRedux & {
  data: IMessageDataProps;
};
const Message = (props: IMessageProps) => {
  useEffect(() => {
    const timeId = setTimeout(() => {
      props.dismissNotification(props.data.id);
    }, 86400000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  const delay = formatDate(new Date(props.data.createOn), 'dd/MM/yy HH:mm');
  const minutesLeft = () => {
    if (props.data.percent == 100) return `Completed 100%!`;
    return getMinutesFromNow(new Date(props.data.createOn));
  };
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    props.dismissNotification(props.data.id);
  };
  const renderProgressBar = () => {
    switch (props.data.status) {
      case SignalRMessageStatus.Running:
        return <ProgressBar animated now={props.data.percent} />;
      case SignalRMessageStatus.Warning:
        return <ProgressBar variant="warning" animated now={props.data.percent} />;
      case SignalRMessageStatus.Error:
        return <ProgressBar variant="danger" animated now={props.data.percent} />;
      default:
        return <ProgressBar variant="success" now={props.data.percent} />;
    }
  };
  return (
    <li className="list-group-item border-bottom border-bottom-light">
      <div className="d-flex w-100 justify-content-between">
        <div className="icon-container">{getMessageIcon(props.data)}</div>
        <div className="flex-grow-1">
          <div className="d-flex w-100 justify-content-between">
            <h5 className="mb-2">{getMessageType(props.data)}</h5>
            <small>{delay}</small>
          </div>
          <p className="mb-2">{props.data.text}</p>
          {props.data.type !== SignalRMessageType.Progress ? (
            <>
              <Button themeColor="primary" onClick={handleClick} style={{ float: 'right', padding: '2px 8px' }}>
                {CommonResources.Button_Dismiss}
              </Button>
            </>
          ) : (
            <React.Fragment>
              {renderProgressBar()}
              <small>{minutesLeft()}</small>
              <Button themeColor="primary" onClick={handleClick}>
                {CommonResources.Button_Dismiss}
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
    </li>
  );
};
export default connector(Message);
