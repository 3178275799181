import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { useParams } from 'react-router-dom';
import { appendScriptsCallback } from '../../helpers/Utils';

export function DealForecastReportPage(): React.ReactElement {
  const { area } = useParams();
  const currentSecurity = useSecurity('Trading', 'DealForecast');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(['Report.custom.js',
          'Areas/Master/Scripts/Resources/en/res.MasterClient.en.js',
          'Areas/Master/Scripts/Resources/en/res.Province.en.js',
          'Areas/Master/Scripts/Resources/en/res.MasterMarket.en.js',
          'Areas/Trading/Scripts/Resources/en/res.AgencyProfile.en.js',
          'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/DealForecast/CanadaSpendReports/' + area + 'View.custom.js'],
        'initNewCanadaReport', { area: area });
    }
  }, [area]);

  function reportName(area) {
    switch (area) {
      case 'Crosstab':
        return 'The Big Crosstab';
      case 'OwnerWordCloud':
        return 'Owner Word Cloud';
      case 'ClientDashboard':
        return 'Clients Dashboard';
      case 'MarketDashboard':
        return 'Market Dashboard';
      case 'MediaSpend':
        return 'Media Spend Analysis';
      case 'MediaSpendTrail':
        return 'Media Spend Trends';
      case 'OwnerDashboard':
        return 'Owner Dashboard';
      default:
        return 'foo';
    }
  }

  return (
    <div className="panelcontent-section">
      <div id="sidebar-section" style={{ display: 'none' }}>
        <i className="fas fa-bars" id="sidebarBar"></i>
        <div className="k-content">
          <h3>{reportName(area)}</h3>
          {area != 'MediaSpendTrail' && <div className="field datecontrol-container">
            <label className="required">DateFrom</label>
            <input id="inputStartDate" />
          </div>}
          {area != 'MediaSpendTrail' && <div className="field datecontrol-container">
            <label className="required">DateTo</label>
            <input id="inputEndDate" />
          </div>}
          {area == 'MediaSpendTrail' && <div className="field datecontrol-container">
            <label className="required">Year</label>
            <input id="inputYear" />
          </div>}
          {area == 'MediaSpendTrail' && <div className="field datecontrol-container">
            <label className="required">Month</label>
            <input id="inputMonth" />
          </div>}
          <div className="field fullwidth-container">
            <label>Agency</label>
            <input id="inputAgency" />
          </div>
          <div className="field fullwidth-container">
            <label>Master Clients</label>
            <input id="inputMasterClient" />
          </div>
          {area == 'MarketDashboard' && <div className="field fullwidth-container">
            <label>@ResourceTrading.MasterMarket</label>
            <input id="inputMasterMarket" />
          </div>}
          {area == 'MarketDashboard' && <div className="field fullwidth-container">
            <label>@ResourceTrading.Province</label>
            <input id="inputProvince" />
          </div>}
          <div className="field fullwidth-container">
            <label>Deal Owner</label>
            <input id="inputDealOwner" />
          </div>
          {area == 'MediaSpendTrail' && <div className="field fullwidth-container">
            <label>Media Owner</label>
            <input id="inputMediaOwner" />
          </div>}
          {['Crosstab', 'ClientDashboard', 'OwnerDashboard'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Master Owner</label>
            <input id="inputMasterOwner" />
          </div>}
          {['Crosstab', 'OwnerWordCloud', 'ClientDashboard', 'MediaSpendTrail', 'OwnerDashboard'].includes(area ? area.toString() : '') && <div className="field fullwidth-container">
            <label>Master Media</label>
            <input id="inputMasterMedia" />
          </div>}
          {area == 'Crosstab' && <div className="field fullwidth-container">
            <label>Client</label>
            <input id="inputClient" />
          </div>}
          {area == 'Crosstab' && <div className="field fullwidth-container">
            <label>Media Vendor</label>
            <input id="inputMediaVendor" />
          </div>}
          <div className="field shortcontrol-container">
            <label>Green Client</label>
            <input id="inputGreenClient" />
          </div>
          <div className="field shortcontrol-container">
            <label>Gross/Net</label>
            <input id="inputGrossNet" />
          </div>
          {area == 'Crosstab' && <div className="field shortcontrol-container">
            <label>Show Clients</label>
            <input id="inputGroupByClient" />
          </div>}
          {area == 'Crosstab' && <div className="field shortcontrol-container">
            <label>Show Medias</label>
            <input id="inputGroupByMedia" />
          </div>}
          {area == 'Crosstab' && <div className="field shortcontrol-container">
            <label>Show Owners</label>
            <input id="inputGroupByOwner" />
          </div>}
          {area == 'Crosstab' && <div className="field shortcontrol-container">
            <label>Show Vendors</label>
            <input id="inputGroupByVendor" />
          </div>}
          {area == 'Crosstab' && <div className="field shortcontrol-container">
            <label>Column Mode</label>
            <input id="inputColumnMode" />
          </div>}
          <section className="button-toolbar">
            {['Crosstab', 'ClientDashboard', 'MediaSpendTrail', 'OwnerDashboard'].includes(area ? area.toString() : '') && <div className="left"><button id="exportFilterButton" type="button" className="k-button" title="Export">Export</button></div>}
            <div className="right"><button id="applyFilterButton" type="button" className="k-button k-primary" title="Go">Go!</button></div>
          </section>
        </div>
      </div>
      <div id="content-section">
        <i className="fas fa-bars" id="contentBar"></i>
        {area == 'Crosstab' && <div id="CrosstabViewReportContainer" className="k-content">
          <h3>The Big Crosstab</h3>
          <div id="CrosstabViewSpreadsheetContainer" className="spreadsheet-container"></div>
        </div>}
        {area == 'OwnerWordCloud' && <div id="OwnerWordCloudViewReportContainer" className="k-content">
          <div>
            <h3>Owner Word Cloud (Top 100)</h3>
            <div style={{ height: '600px', overflowY: 'auto' }}>
              <div id="OwnerWordCloudViewChartContainer"></div>
            </div>
          </div>
        </div>}
        {area == 'ClientDashboard' && <div id="ClientDashboardViewReportContainer" className="k-content">
          <div>
            <h3>Master Clients Weights</h3>
            <div style={{ height: '400px', overflowY: 'auto' }}>
              <div id="ClientDashboardViewChartContainer"></div>
            </div>
          </div>
          <div>
            <h3>Master Client Spend</h3>
            <div id="ClientDashboardViewSpreadsheetContainer" className="spreadsheet-container"></div>
          </div>
        </div>}
        {area == 'MarketDashboard' && <div className="k-content">
          <div id="MarketDashboardViewMaptContainer"></div>
          <div id="MarketDashboardViewReportContainer">
            <div>
              <h3>Spend by Market (Alphabeticaly)</h3>
              <div id="SpendByMarketSpreadsheetToolbarContainer"></div>
              <div id="SpendByMarketSpreadsheetContainer"></div>
            </div>
            <div>
              <h3>Top Markets by Spend</h3>
              <div id="MarketsBySpendSpreadsheetToolbarContainer"></div>
              <div id="MarketsBySpendSpreadsheetContainer"></div>
            </div>
          </div>
        </div>}
        {area == 'MediaSpend' && <div id="MediaSpendViewReportContainer" className="k-content">
          <div>
            <h3>Spend by Media</h3>
            <div style={{ height: '400px', overflowY: 'auto' }}>
              <div id="MediaSpendViewChartContainer"></div>
            </div>
          </div>
          <div>
            <h3>Total Media Spend</h3>
            <div style={{ height: '400px', overflowY: 'auto' }}>
              <div id="MediaSpendTotalContainer"></div>
            </div>
          </div>
        </div>}
        {area == 'MediaSpendTrail' && <div id="MediaSpendTrailViewReportContainer" className="k-content">
          <div>
            <h3>Monthly Spend Trend Chart</h3>
            <div style={{ height: '400px', overflowY: 'auto' }}>
              <div id="MediaSpendTrailViewChartContainer"></div>
            </div>
          </div>
          <div>
            <h3>Monthly Spend Trend</h3>
            <div id="MediaSpendTrailViewSpreadsheetContainer" className="spreadsheet-container"></div>
          </div>
        </div>}
        {area == 'OwnerDashboard' && <div id="OwnerDashboardViewReportContainer" className="k-content">
          <div>
            <h3>Owner Spend Chart (Top 100)</h3>
            <div style={{ height: '400px', overflowY: 'auto' }}>
              <div id="OwnerDashboardViewChartContainer"></div>
            </div>
          </div>
          <div>
            <h3>All Owners (Alphabetically)</h3>
            <div id="OwnerDashboardViewSpreadsheetContainer" className="spreadsheet-container"></div>
          </div>
        </div>}
      </div>
    </div>
  );
}
