import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function MasterMarketNewPage(): React.ReactElement {
  const [state, setState] = useState<IMasterMarket>({ Id: ""  });
  const currentSecurity = useSecurity('Master', 'Market');
  const updatedState: IMasterMarket = { Id: ""  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      setState(updatedState);
      appendScriptsCallback(["Areas/Master/Scripts/Resources/en/res.Market.en.js",
        "Areas/Master/Scripts/Resources/en/Types.en.js",
        "Areas/Master/Scripts/Enums.js",
        "Areas/Master/Scripts/Market/View.js"], "initNewMasterMarket", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="Market" className="controls-container default block">
              <h3>Create a New <b>Markets</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="MarketCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="MarketLabelBlockedCode">Blocked</label>
				<input id="MarketCheckBlockedCode" defaultChecked name="MarketCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="MarketEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('MarketEnglishName','MarketNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="MarketNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="MarketEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('MarketEnglishShortName','MarketNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="MarketNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterMarketId">Master Market</label><select className="" id="MarketMasterMarketId" name="MasterMarketId" defaultValue={state.MasterMarketId}   required data-required-msg="Master Market is required"  >{state.MasterMarketId && <option value={state.MasterMarketId}>{state.MasterMarketDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="Location">Alias</label><input  required data-required-msg="Alias is required"  id="MarketLocation" name="Location" type="text" defaultValue={state.Location} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="Latitude">Latitude</label><input  required data-required-msg="Latitude is required"  data-val-number="The field Latitude must be a number." id="MarketLatitude" name="Latitude" type="text" defaultValue={state.Latitude} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="Longitude">Longitude</label><input  required data-required-msg="Longitude is required"  data-val-number="The field Longitude must be a number." id="MarketLongitude" name="Longitude" type="text" defaultValue={state.Longitude} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="MarketSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarMarket">
                <div className="left">
                  <button id="cancelMarketButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveMarketButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface IMasterMarket {
  EnglishName?: string,
  EnglishShortName?: string,
  NativeName?: string,
  NativeShortName?: string,
  MasterMarketId?: string,
  MasterMarketDisplayValue?: string,
  Location?: string,
  Latitude?: number,
  Longitude?: number,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}


export function MasterMarketDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IMasterMarket>({ Id: ""  });
  const currentSecurity = useSecurity('Master', 'Market');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Master", "Market", id).then(result => {
        setState(result.Value[0] as IMasterMarket);
        appendScriptsCallback(["Areas/Master/Scripts/Resources/en/res.Market.en.js",
        "Areas/Master/Scripts/Resources/en/Types.en.js",
        "Areas/Master/Scripts/Enums.js",
        "Areas/Master/Scripts/Market/View.js"
        ], "initDetailMasterMarket", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadMasterMarketState'] = (data) => {
    setState(data.Value[0] as IMasterMarket);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="Market" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Markets: <span>{state.Display}</span><span id="MarketToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.MasterMarketId && <span className='element'><label>Master Market: <label className="strong">{state.MasterMarketDisplayValue}</label></label></span>}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="MarketCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="MarketLabelBlockedCode">Blocked</label>
				<input id="MarketCheckBlockedCode" defaultChecked name="MarketCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="MarketEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('MarketEnglishName','MarketNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="MarketNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="MarketEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('MarketEnglishShortName','MarketNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="MarketNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterMarketId">Master Market</label><select className="" id="MarketMasterMarketId" name="MasterMarketId" defaultValue={state.MasterMarketId}   required data-required-msg="Master Market is required"  >{state.MasterMarketId && <option value={state.MasterMarketId}>{state.MasterMarketDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="Location">Alias</label><input  required data-required-msg="Alias is required"  id="MarketLocation" name="Location" type="text" defaultValue={state.Location} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="Latitude">Latitude</label><input  required data-required-msg="Latitude is required"  data-val-number="The field Latitude must be a number." id="MarketLatitude" name="Latitude" type="text" defaultValue={state.Latitude} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="Longitude">Longitude</label><input  required data-required-msg="Longitude is required"  data-val-number="The field Longitude must be a number." id="MarketLongitude" name="Longitude" type="text" defaultValue={state.Longitude} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="MarketSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarMarket">
                <div className="left">
                  <button id="cancelMarketButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveMarketButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}


