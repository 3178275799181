import { ISignalRMessageProps } from '../../services/SignalRHelper';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const TOGGLE_NOTIFICATIONS = 'TOGGLE_NOTIFICATIONS';
export const TOGGLE_MESSAGES = 'TOGGLE_MESSAGES';
export const TOGGLE_SETTINGS = 'TOGGLE_SETTINGS';
export const CLOSE_SETTINGS = 'CLOSE_SETTINGS';
export const CHANGE_APPPLATFORM = 'CHANGE_APPPLATFORM';
export const CHANGE_THEME = 'CHANGE_THEME';
export const EVAL_NAVIGATION_HISTORY = 'EVAL_NAVIGATION_HISTORY';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const SET_PENDINGMESSAGES = 'SET_PENDINGMESSAGES';
export const CLOSE_NOTIFICATIONS = 'CLOSE_NOTIFICATIONS';
export const CLOSE_MESSAGES = 'CLOSE_MESSAGES';
export const PURGE_NOTIFICATIONS = 'PURGE_NOTIFICATIONS';
export const LOAD_SYSTEM_INFO = 'LOAD_SYSTEM_INFO';
export const ERROR_SYSTEM_INFO = 'GET_SYSTEM_INFO';

interface ChangeThemeAction {
  type: typeof CHANGE_THEME;
  value: string;
}
interface ToggleSidebarAction {
  type: typeof TOGGLE_SIDEBAR;
}
interface ToggleNotificationAction {
  type: typeof TOGGLE_NOTIFICATIONS;
}
interface ToggleMessageAction {
  type: typeof TOGGLE_MESSAGES;
}
interface ToggleSettingsAction {
  type: typeof TOGGLE_SETTINGS;
}
interface CloseSettingsAction {
  type: typeof CLOSE_SETTINGS;
}
interface CloseSidebarAction {
  type: typeof CLOSE_SIDEBAR;
}
interface DismissNotificationAction {
  type: typeof DISMISS_NOTIFICATION;
  id: string;
}
interface SetPendingMessages {
  type: typeof SET_PENDINGMESSAGES;
  value: number;
}
interface AddNotification {
  type: typeof ADD_NOTIFICATION;
  data: ISignalRMessageProps;
}
interface CloseNotifications {
  type: typeof CLOSE_NOTIFICATIONS;
}
interface CloseMessages {
  type: typeof CLOSE_MESSAGES;
}
interface PurgueNotificationsAction {
  type: typeof PURGE_NOTIFICATIONS;
}
interface EvalNavigationAction {
  type: typeof EVAL_NAVIGATION_HISTORY;
  location: string;
}
export type NavigationActionTypes =
  | ChangeThemeAction
  | ToggleSidebarAction
  | CloseSidebarAction
  | ToggleNotificationAction
  | ToggleSettingsAction
  | CloseSettingsAction
  | DismissNotificationAction
  | AddNotification
  | CloseNotifications
  | CloseMessages
  | PurgueNotificationsAction
  | EvalNavigationAction
  | ToggleMessageAction
  | SetPendingMessages;

export function changeTheme(value: string): NavigationActionTypes {
  return { type: CHANGE_THEME, value: value };
}
export function toggleNotifications(): NavigationActionTypes {
  return { type: TOGGLE_NOTIFICATIONS };
}
export function toggleMessages(): NavigationActionTypes {
  return { type: TOGGLE_MESSAGES };
}
export function toggleSettings(): NavigationActionTypes {
  return { type: TOGGLE_SETTINGS };
}
export function closeSettings(): NavigationActionTypes {
  return { type: CLOSE_SETTINGS };
}
export function toggleSidebarint(): NavigationActionTypes {
  return { type: TOGGLE_SIDEBAR };
}
export function closeSidebar(): NavigationActionTypes {
  return { type: CLOSE_SIDEBAR };
}
export function dismissNotification(id: string): NavigationActionTypes {
  return { type: DISMISS_NOTIFICATION, id: id };
}
export function addNotification(data: ISignalRMessageProps): NavigationActionTypes {
  return { type: ADD_NOTIFICATION, data: data };
}
export function setPendingMessages(value: number): NavigationActionTypes {
  return { type: SET_PENDINGMESSAGES, value: value };
}
export function closeNotifications(): NavigationActionTypes {
  return { type: CLOSE_NOTIFICATIONS };
}
export function closeMessages(): NavigationActionTypes {
  return { type: CLOSE_MESSAGES };
}
export function purgueNotifications(): NavigationActionTypes {
  return { type: PURGE_NOTIFICATIONS };
}

export function evalNavigation(location: string): NavigationActionTypes {
  return { type: EVAL_NAVIGATION_HISTORY, location: location };
}
