import React from 'react';
import { CommonResources, Resources } from '../../config/CommonResources';
import Footer from '../shared/Footer';
import { JumboImageBox } from '../shared/JumboBox';
import { Button } from '@progress/kendo-react-buttons';
import { IUserInfoProps } from '../services/GraphQLShared';
import { OnDemandIcon } from '../../components/helpers/IconUtils';
import { Colors } from '../../config/Constants';

export interface IWelcomeNoAuthorizedProps {
  user: IUserInfoProps;
}

function WelcomeNoAuthorized(props: IWelcomeNoAuthorizedProps): React.ReactElement {
  return (
    <div className="app__content">
      <div>
        <JumboImageBox styleName="full-screen">
          <h2 className="mb-0">
            {CommonResources.Hello}, {props.user != null ? props.user.givenName : 'Stranger'}!
          </h2>
          <h4>
            Welcome to <b>{Resources.ApplicationName}</b>!
          </h4>
          <p
            dangerouslySetInnerHTML={{
              __html: `${Resources.ApplicationIntro}`,
            }}
          ></p>
          <p>
            <OnDemandIcon icon="exclamation-triangle" color={Colors.AlertColor} className="me-2" />
            {CommonResources.Message_UnauthorizeIntro}
          </p>
          <p dangerouslySetInnerHTML={{ __html: Resources.ApplicationContanctTeam }}></p>
          <Button
            themeColor="primary"
            onClick={() => {
              window.open('https://wppit.service-now.com/service/', '_blank');
            }}
          >
            <OnDemandIcon icon={'life-ring'} className="me-2" />
            {CommonResources.Button_ContactSupport}
          </Button>
        </JumboImageBox>
      </div>
      <Footer />
    </div>
  );
}

export default React.memo(WelcomeNoAuthorized);
