import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function MasterMediaOwnerNewPage(): React.ReactElement {
  const [state, setState] = useState<IMasterMediaOwner>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Master', 'MediaOwner');
  let updatedState: IMasterMediaOwner = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.BillingReference = "TBC";
            getPreferredDetails("Master", "Language").then((result) => {
              const resultlanguage: any[] = result.Value;
              if (resultlanguage && resultlanguage.length > 0)
                updatedState = { ...updatedState, LanguageId: resultlanguage[0].Id, LanguageDisplayValue: resultlanguage[0].Display };
      setState(updatedState);
      appendScriptsCallback(["Areas/Master/Scripts/Resources/en/res.MediaOwner.en.js",
        "Areas/Master/Scripts/Resources/en/Types.en.js",
        "Areas/Master/Scripts/Enums.js",
        "Areas/Master/Scripts/MediaOwner/View.js"], "initNewMasterMediaOwner", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
            });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="MediaOwner" className="controls-container default block">
              <h3>Create a New <b>Media Owners</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="MediaOwnerCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="MediaOwnerLabelBlockedCode">Blocked</label>
				<input id="MediaOwnerCheckBlockedCode" defaultChecked name="MediaOwnerCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="MediaOwnerEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('MediaOwnerEnglishName','MediaOwnerNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="MediaOwnerNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="MediaOwnerEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('MediaOwnerEnglishShortName','MediaOwnerNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="MediaOwnerNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="LanguageId">Language</label><select className="" id="MediaOwnerLanguageId" name="LanguageId" defaultValue={state.LanguageId}   required data-required-msg="Language is required"  >{state.LanguageId && <option value={state.LanguageId}>{state.LanguageDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterVendorOwnerId">Master Vendor</label><select className="" id="MediaOwnerMasterVendorOwnerId" name="MasterVendorOwnerId" defaultValue={state.MasterVendorOwnerId}   required data-required-msg="Master Vendor is required"  >{state.MasterVendorOwnerId && <option value={state.MasterVendorOwnerId}>{state.MasterVendorOwnerDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="BillingReference">Billing Reference</label><input  id="MediaOwnerBillingReference" name="BillingReference" type="text" defaultValue={state.BillingReference} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="MediaOwnerSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarMediaOwner">
                <div className="left">
                  <button id="cancelMediaOwnerButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveMediaOwnerButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface IMasterMediaOwner {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  EnglishName?: string,
  EnglishShortName?: string,
  NativeName?: string,
  NativeShortName?: string,
  LanguageId?: string,
  LanguageDisplayValue?: string,
  MasterVendorOwnerId?: string,
  MasterVendorOwnerDisplayValue?: string,
  BillingReference?: string,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}


export function MasterMediaOwnerDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IMasterMediaOwner>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Master', 'MediaOwner');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Master", "MediaOwner", id).then(result => {
        setState(result.Value[0] as IMasterMediaOwner);
        appendScriptsCallback(["Areas/Master/Scripts/Resources/en/res.MediaOwner.en.js",
        "Areas/Master/Scripts/Resources/en/Types.en.js",
        "Areas/Master/Scripts/Enums.js",
        "Areas/Master/Scripts/MediaOwner/View.js"
        ], "initDetailMasterMediaOwner", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadMasterMediaOwnerState'] = (data) => {
    setState(data.Value[0] as IMasterMediaOwner);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="MediaOwner" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Media Owners: <span>{state.Display}</span><span id="MediaOwnerToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.LanguageId && <span className='element'><label>Language: <label className="strong">{state.LanguageDisplayValue}</label></label></span>}
                  {state.MasterVendorOwnerId && <span className='element'><label>Master Vendor: <label className="strong">{state.MasterVendorOwnerDisplayValue}</label></label></span>}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="MediaOwnerCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="MediaOwnerLabelBlockedCode">Blocked</label>
				<input id="MediaOwnerCheckBlockedCode" defaultChecked name="MediaOwnerCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="MediaOwnerEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('MediaOwnerEnglishName','MediaOwnerNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="MediaOwnerNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="MediaOwnerEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('MediaOwnerEnglishShortName','MediaOwnerNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="MediaOwnerNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="LanguageId">Language</label><select className="" id="MediaOwnerLanguageId" name="LanguageId" defaultValue={state.LanguageId}   required data-required-msg="Language is required"  >{state.LanguageId && <option value={state.LanguageId}>{state.LanguageDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterVendorOwnerId">Master Vendor</label><select className="" id="MediaOwnerMasterVendorOwnerId" name="MasterVendorOwnerId" defaultValue={state.MasterVendorOwnerId}   required data-required-msg="Master Vendor is required"  >{state.MasterVendorOwnerId && <option value={state.MasterVendorOwnerId}>{state.MasterVendorOwnerDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="BillingReference">Billing Reference</label><input  id="MediaOwnerBillingReference" name="BillingReference" type="text" defaultValue={state.BillingReference} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="MediaOwnerSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="MediaOwnerSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="MediaOwnerSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarMediaOwner">
                <div className="left">
                  <button id="cancelMediaOwnerButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveMediaOwnerButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}


