import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import Dialog from '../../shared/Dialog';
import { CommonResources } from '../../../config/CommonResources';

export interface IDialogProps {
  title?: string;
  message?: string;
  description?: string;
  onClickOk: () => void;
  onClickCancel: () => void;
  visible: boolean;
}
export interface IRouteParams {
  id: string;
}
export function ConfirmDialog(props: IDialogProps): React.ReactElement | null {
  return (
    <>
      {props.visible && (
        <Dialog title={props.title} onClose={props.onClickCancel}>
          {props.message && props.message?.indexOf('<') > 0 ? (
            <p className="mb-0" dangerouslySetInnerHTML={{ __html: props.message }}></p>
          ) : (
            <p className="mb-0">{props.message}</p>
          )}
          {props.description && <p>{props.description}</p>}
          <DialogActionsBar>
            <Button onClick={props.onClickCancel}>{CommonResources.No}</Button>
            <Button themeColor="primary" onClick={props.onClickOk}>
              {CommonResources.Yes}
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
}
