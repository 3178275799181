import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { toggleMessages, closeMessages, setPendingMessages } from '../../store/actions/navActions';
import { RootState } from '../../store/reducer/rootReducer';
import { IUserInfoProps } from '../services/GraphQLShared';
import cn from 'classnames';
import { Button } from '@progress/kendo-react-buttons';
import { getCustom } from '../../services/ApiServices';
import { appendScripts } from '../helpers/Utils';

const mapState = (state: RootState) => ({
  messageOpen: state.nav.messageOpen,
  pendingmessages: state.nav.pendingmessages,
});
const mapDispatch = {
  toggleMessages,
  setPendingMessages,
  closeMessages,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export type IMessagesProps = PropsFromRedux & {
  isAuthenticated: boolean;
  user: IUserInfoProps;
};
let gridloaded = false;
let pendingNotificationsloaded = false;
const Messages = (props: IMessagesProps) => {
  React.useEffect(() => {
    const closeMessages = (e: any) => {
      if (e.code === 'Escape' && props.messageOpen) props.closeMessages();
    };
    document.addEventListener('keydown', closeMessages);
    return () => document.removeEventListener('keydown', closeMessages);
  }, [props.messageOpen]);

  useEffect(() => {
    if (props.isAuthenticated && !pendingNotificationsloaded) {
      pendingNotificationsloaded = true;
      getCustom('Messaging', 'Notification', 'PendingNotifications').then((result) => {
        props.setPendingMessages(result.Value);
      });
    }
  }, [props.isAuthenticated]);

  React.useEffect(() => {
    const body = document.querySelector('body');
    const removeOverflow = () => body?.classList.remove('body-overflow');
    if (props.messageOpen) {
      body?.classList.add('body-overflow');
      if (!gridloaded) {
        gridloaded = true;
        appendScripts(['messages.js']);
      } else {
        window['refreshmessages'](true);
      }
    } else {
      removeOverflow();
    }
    return removeOverflow;
  }, [props.messageOpen]);

  const onClickClose = () => props.toggleMessages();
  const MessagesClass = cn('messages dialog mobile shadow', { open: props.messageOpen });

  return (
    <>
      <aside className={MessagesClass}>
        <div className="dialog-header large">
          <h4 className="title">Messages</h4>
          <Button type="button" className="btn-close" fillMode={'flat'} icon={'close'} onClick={onClickClose} />
        </div>
        <div className="dialog-content">
          <section id={'gridContainerMessage'}></section>
          <ul id={'gridContainerMessage-menu'}></ul>
        </div>
      </aside>
    </>
  );
};
export default connector(Messages);
