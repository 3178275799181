import React from 'react';
import { CommonResources, Resources } from '../../config/CommonResources';
import Footer from '../shared/Footer';
import { JumboImageBox } from '../shared/JumboBox';
import { Button } from '@progress/kendo-react-buttons';
import { OnDemandIcon } from '../../components/helpers/IconUtils';

export interface IWelcomeProps {
  login: Function;
}
function Welcome(props: IWelcomeProps): React.ReactElement {
  return (
    <div className="app__content">
      <div>
        <JumboImageBox styleName="full-screen">
          <h2 className="mb-0">{CommonResources.Hello}, Stranger!</h2>
          <h4>
            Welcome to <b>{Resources.ApplicationName}</b>!
          </h4>
          <p
            dangerouslySetInnerHTML={{
              __html: `${Resources.ApplicationIntro}`,
            }}
          ></p>
          <p>
            If you not have been automatically logged in, please click into the <b>Login</b> button and provide your
            credentials.
          </p>
          <p dangerouslySetInnerHTML={{ __html: Resources.ApplicationContanctTeam }}></p>
          <Button themeColor="primary" onClick={() => props.login()}>
            {CommonResources.Button_Login}
          </Button>
          <Button
            fillMode="flat"
            onClick={() => {
              window.open('https://wppit.service-now.com/service/', '_blank');
            }}
          >
            <OnDemandIcon icon={'life-ring'} className="me-2" />
            {CommonResources.Button_ContactSupport}
          </Button>
        </JumboImageBox>
      </div>
      <Footer />
    </div>
  );
}

export default React.memo(Welcome);
