import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
import { appendScriptsCallback, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
import { getEntityDetails } from '../../../../services/ApiServices';
import { sidebar_menu } from '../../../../config/Menu';

export function SecurityGroupNewPage(): React.ReactElement {
  const [state] = useState<ISecurityGroup>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Security', 'Group');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(
        [
          'Areas/Admin/Scripts/Resources/en/res.Group.en.js',
          'Areas/Admin/Scripts/Resources/en/Types.en.js',
          'Areas/Admin/Scripts/Enums.js',
          'Areas/Admin/Scripts/Group/View.js',
        ],
        'initNewSecurityGroup',
        { viewmodel: state, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="Group" className="controls-container default block">
              <h3>
                Create a New <b>Security Group</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="GroupCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="GroupLabelBlockedCode">Blocked</label>
                  <input id="GroupCheckBlockedCode" defaultChecked name="GroupCheckBlockedCode" type="checkbox" />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="EnglishName" className="required">
                    Name (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (EN) is required"
                    id="GroupEnglishName"
                    max="255"
                    min="1"
                    name="EnglishName"
                    required
                    type="text"
                    defaultValue={state.EnglishName}
                    onChange={() => window['OnChangeReply']('GroupEnglishName', 'GroupNativeName')}
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeName" className="required">
                    Name (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (NT) is required"
                    id="GroupNativeName"
                    max="255"
                    min="1"
                    name="NativeName"
                    required
                    type="text"
                    defaultValue={state.NativeName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="EnglishShortName" className="required">
                    ShortName (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (EN) is required"
                    id="GroupEnglishShortName"
                    max="50"
                    min="1"
                    name="EnglishShortName"
                    required
                    type="text"
                    defaultValue={state.EnglishShortName}
                    onChange={() => window['OnChangeReply']('GroupEnglishShortName', 'GroupNativeShortName')}
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeShortName" className="required">
                    ShortName (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (NT) is required"
                    id="GroupNativeShortName"
                    max="50"
                    min="1"
                    name="NativeShortName"
                    required
                    type="text"
                    defaultValue={state.NativeShortName}
                  />
                </div>
                <div className="col-md-2 xshortcontrol-container dropdownlist">
                  <label htmlFor="GroupType">Type</label>
                  <select
                    className=""
                    id="GroupGroupType"
                    name="GroupType"
                    required
                    data-required-msg="Type is required"
                  >
                    <option value="0">Operations</option>
                    <option value="1">Data</option>
                    <option value="2">Other</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="GroupSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarGroup">
                <div className="left">
                  <button id="cancelGroupButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveGroupButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

export interface ISecurityGroup {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: string;
    Source?: string;
  };
  EnglishName?: string;
  EnglishShortName?: string;
  NativeName?: string;
  NativeShortName?: string;
  GroupType?: string;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

enum GroupTypeEnum {
  Operations = 0,
  Data = 1,
  Other = 2,
}

export function SecurityGroupDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ISecurityGroup>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Security', 'Group');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Security', 'Group', id).then((result) => {
        setState(result.Value[0] as ISecurityGroup);
        appendScriptsCallback(
          [
            'Areas/Admin/Scripts/Resources/en/res.Group.en.js',
            'Areas/Admin/Scripts/Resources/en/Types.en.js',
            'Areas/Admin/Scripts/Enums.js',
            'Areas/Admin/Scripts/Group/View.js',
            'Areas/Admin/Scripts/Resources/en/res.OperationPermission.en.js',
            'Areas/Admin/Scripts/OperationPermission/View.js',
            'Areas/Admin/Scripts/Resources/en/res.MenuPermission.en.js',
            'Areas/Admin/Scripts/MenuPermission/View.js',
            'Areas/Admin/Scripts/Resources/en/res.DataPermission.en.js',
            'Areas/Admin/Scripts/DataPermission/View.js',
            'Areas/Admin/Scripts/Resources/en/res.MappedEntity.en.js',
            'Areas/Admin/Scripts/MappedEntity/View.js',
            'Areas/Admin/Scripts/Resources/en/res.GroupUser.en.js',
            'Areas/Admin/Scripts/GroupUser/View.js',
          ],
          'initDetailSecurityGroup',
          {
            viewmodel: result.Value[0],
            canEdit: true,
            canCreate: true,
            canRemove: true,
            id: id,
            topmenu: sidebar_menu,
          },
        );
      });
    }
  }, []);
  window['reloadSecurityGroupState'] = (data) => {
    setState(data.Value[0] as ISecurityGroup);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="Group" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      Security Group: <span>{state.Display}</span>
                      <span id="GroupToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }}></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line">
                  <span className="element">
                    <label>
                      Type:{' '}
                      <label className="strong">
                        {state.GroupType != undefined ? GroupTypeEnum[state.GroupType] : ''}
                      </label>
                    </label>
                  </span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="GroupCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="GroupLabelBlockedCode">Blocked</label>
                  <input id="GroupCheckBlockedCode" defaultChecked name="GroupCheckBlockedCode" type="checkbox" />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="EnglishName" className="required">
                    Name (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (EN) is required"
                    id="GroupEnglishName"
                    max="255"
                    min="1"
                    name="EnglishName"
                    required
                    type="text"
                    defaultValue={state.EnglishName}
                    onChange={() => window['OnChangeReply']('GroupEnglishName', 'GroupNativeName')}
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeName" className="required">
                    Name (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (NT) is required"
                    id="GroupNativeName"
                    max="255"
                    min="1"
                    name="NativeName"
                    required
                    type="text"
                    defaultValue={state.NativeName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="EnglishShortName" className="required">
                    ShortName (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (EN) is required"
                    id="GroupEnglishShortName"
                    max="50"
                    min="1"
                    name="EnglishShortName"
                    required
                    type="text"
                    defaultValue={state.EnglishShortName}
                    onChange={() => window['OnChangeReply']('GroupEnglishShortName', 'GroupNativeShortName')}
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeShortName" className="required">
                    ShortName (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (NT) is required"
                    id="GroupNativeShortName"
                    max="50"
                    min="1"
                    name="NativeShortName"
                    required
                    type="text"
                    defaultValue={state.NativeShortName}
                  />
                </div>
                <div className="col-md-2 xshortcontrol-container dropdownlist">
                  <label htmlFor="GroupType">Type</label>
                  <select
                    className=""
                    id="GroupGroupType"
                    name="GroupType"
                    required
                    data-required-msg="Type is required"
                    value={state.GroupType}
                  >
                    <option value="0">Operations</option>
                    <option value="1">Data</option>
                    <option value="2">Other</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="GroupSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    className=""
                    id="GroupSourceStatus"
                    name="SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select className="" id="GroupSource" name="Source" value={state.LoadingInfo.Source}>
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarGroup">
                <div className="left">
                  <button id="cancelGroupButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveGroupButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
          <div id="GroupTabstrip">
            <ul>
              {state.GroupType != '1' && (
                <li id="GroupOperationPermission" className="k-active">
                  Operation Permissions
                </li>
              )}
              {state.GroupType != '1' && <li id="GroupMenuPermission">Menu Permissions</li>}
              {state.GroupType == '1' && (
                <li id="GroupDataPermission" className="k-active">
                  Data Permissions
                </li>
              )}
              {state.GroupType == '2' && <li id="GroupDataPermission">Data Permissions</li>}
              {state.GroupType != '0' && <li id="GroupMappedEntity">Mapped Entities</li>}
              <li id="GroupGroupUser">Group Users</li>
            </ul>
            {state.GroupType != '1' && (
              <div>
                <div id="associationGroupOperationPermission">
                  <div className="k-block grid-details">
                    <h2>Operation Permissions</h2>
                    <div id="OperationPermissionGrid" className="grid-control"></div>
                  </div>
                </div>
              </div>
            )}
            {state.GroupType != '1' && (
              <div>
                <div id="associationGroupMenuPermission">
                  <div className="k-block grid-details">
                    <h2>Menu Permissions</h2>
                    <div id="MenuPermissionGrid" className="grid-control"></div>
                  </div>
                </div>
              </div>
            )}
            {state.GroupType != '0' && (
              <div>
                <div id="associationGroupDataPermission">
                  <div className="k-block grid-details">
                    <h2>Data Permissions</h2>
                    <div id="DataPermissionGrid" className="grid-control"></div>
                  </div>
                </div>
              </div>
            )}
            {state.GroupType != '0' && (
              <div>
                <div id="associationGroupMappedEntity">
                  <div className="k-block grid-details">
                    <h2>Mapped Entities</h2>
                    <div id="MappedEntityGrid" className="grid-control"></div>
                  </div>
                </div>
              </div>
            )}
            <div>
              <div id="associationGroupGroupUser">
                <div className="k-block grid-details">
                  <h2>Group Users</h2>
                  <div id="GroupUserGrid" className="grid-control"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
