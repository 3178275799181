import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScripts } from '../../helpers/Utils';

export function SecurityUserTreePage(): React.ReactElement {
  const currentSecurity = useSecurity('Security', 'User');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScripts([
        'Areas/Admin/Scripts/AdminShare.js',
        'Areas/Admin/Scripts/Resources/en/res.User.en.js',
        'Areas/Admin/Scripts/Resources/en/res.Group.en.js',
        'Areas/Admin/Scripts/Resources/en/res.GroupUser.en.js',
        'Areas/Admin/Scripts/Resources/en/Types.en.js',
        'Areas/Admin/Scripts/User/IndexTree.js',
      ]);
    }
  }, []);
  return (
    <div className="container-fluid treeview-admin">
      <div className="row">
        <div id="groupuser-details" className="col-md-6">
          <div className="k-block k-content">
            <div className="k-header k-shadow">Group</div>
            <input id="dropdown-left" type="text" className="k-textbox" placeholder="Search Group" />
            <div id="treeview-left"></div>
          </div>
        </div>
        <div id="usergroup-details" className="col-md-6">
          <div className="k-block k-content">
            <div className="k-header k-shadow">User</div>
            <input id="dropdown-right" type="text" className="k-textbox" placeholder="Search  User" />
            <div id="treeview-right"></div>
          </div>
        </div>
      </div>
      <div id="dialogSelect_1234">
        <div className="dialogSelectContent">
          <h3>Search User/Security Groups</h3>
          <input id="filterTextselect_1234" type="text" className="k-textbox" placeholder="Search" />
          <div className="selectAll_1234">
            <input type="checkbox" id="chbAll" className="k-checkbox k-checkbox-md k-rounded-md" />
            <label className="k-checkbox-label" htmlFor="chbAll">
              Select All
            </label>
            <span id="result_1234">0 elements selected</span>
          </div>
          <div id="treeviewSelect_1234"></div>
        </div>
      </div>
    </div>
  );
}
