import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../helpers/Utils';

export function AppSettingsPage(): React.ReactElement {
  const currentSecurity = useSecurity('Shared', 'AppSettings');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(['AppSettings.js'], 'initAppSettings', {
        appSettings: currentSecurity.profile.AppSettings.Values,
      });
    }
  }, []);
  return (
    <div className="panelcontent-section">
      <div id="sidebar-section">
        <i className="fas fa-bars" id="sidebarBar"></i>
        <div className="k-content" id="grouplist">
          <h3>Application Settings</h3>
          <p>Please select a settings category</p>
        </div>
      </div>
      <div id="formcontent-section" className="collapsed" style={{ display: 'none', width: 'auto' }}>
        <i className="fas fa-bars hidden" id="contentBar"></i>
        <div className="k-content">
          <form id="form" className="content-view">
            <div id="ParametersList" className="row"></div>
          </form>
          <section className="button-toolbar">
            <div className="left"></div>
            <div className="right">
              <button id="saveButton" type="button" className="k-button k-primary" title="Save">
                Save
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
