import navReducer from './navReducer';
import profileReducer from './profileReducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  nav: navReducer,
  security: profileReducer,
});
export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
