import React from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../../helpers/Utils';

export function TradingDealForecastCustomPage(): React.ReactElement {
  const currentSecurity = useSecurity('Trading', 'DealForecast');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.AgencyProfilePeriod.en.js',
          'Areas/Trading/Scripts/Resources/en/res.DealForecast.en.js',
          'Areas/Trading/Scripts/Resources/en/res.DealForecastStream.en.js',
          'Areas/Trading/Scripts/Resources/en/res.MasterClientProfile.en.js',
          'Areas/Trading/Scripts/Resources/en/res.RevenueStream.en.js',
          'Areas/Trading/Scripts/Resources/en/res.DealForecastDeal.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/jquery.shared.custom.js',
          'Areas/Trading/Scripts/DealForecast/Index.custom.js',
          'Areas/Trading/Scripts/DealForecastStream/View.custom.js',
          'Areas/Trading/Scripts/DealForecast/ApplyShare.custom.js'
        ],
        'initSearchDealForecastIndex',
        {
          canEdit: true,
          canCreate: true,
          canRemove: true,
          mode: 'DealForecast',
          appSettings: currentSecurity.profile.AppSettings.Values,
        },
      );
    }
  }, []);
  return (
    <div className="panelcontent-section" style={{ display: 'none' }}>
      <div
        id="sidebar-section"
        ref={(el) => {
          if (el) {
            el.style.setProperty('padding', '1em 2em', 'important');
          }
        }}
      >
        <i className="fas fa-bars" id="sidebarBar"></i>
        <div className="k-content">
          <h3>Deal Forecasts</h3>
          <div className="field shortcontrol-container">
            <label className="required">Forecast Year</label>
            <input id="inputYear" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Agency</label>
            <input id="inputAgencyProfile" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Break Criteria</label>
            <input id="inputBreakCriteria" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Agency Profile Period</label>
            <input id="inputAgencyProfilePeriod" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Master Client</label>
            <input id="inputMasterClientProfilePeriod" />
          </div>
          <div className="field textareafield">
            <label>Client Profiles</label>
            <input id="inputClientProfileNames" />
          </div>
          <div className="field fullwidth-container">
            <label>Deal Category</label>
            <input id="inputDealCategory" />
          </div>
          <div className="field fullwidth-container">
            <label>Currency</label>
            <input id="inputCurrency" />
          </div>
          <section className="button-toolbar">
            <div className="right">
              <button id="applyFilterButton" type="button" className="k-button k-primary" title="Go">
                Go
              </button>
            </div>
          </section>
        </div>
      </div>
      <div id="content-section" className="collapsed">
        <i className="fas fa-bars" id="contentBar" style={{ display: 'none' }}></i>
        <div id="DealForecastContainer" className="k-content">
        </div>
      </div>
    </div>
  );
}
