import React from 'react';
import { useParams } from 'react-router-dom';
import { useSecurity } from '../../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../../helpers/Utils';

export function AdminAuditPage(): React.ReactElement {
  const { bcentity } = useParams();
  const bounded = bcentity ? bcentity.split('-') : ['', ''];
  const currentSecurity = useSecurity(bounded[0], bounded[1]);
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(
        [
          'Areas/Admin/Scripts/Resources/en/res.Audit.en.js',
          'Areas/Admin/Scripts/Resources/en/Types.en.js',
          'Areas/Admin/Scripts/Enums.js',
          'Areas/Admin/Scripts/Audit/Index.js',
        ],
        'initAudit',
        bounded[1],
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <header className="complete-grid">
        <div id="gridFilter1">
          <div id="gridfilter-toolbar"></div>
        </div>
      </header>
      <section id="gridContainer" style={{ paddingTop: '20px' }}></section>
      <ul id="gridContainer-menu"></ul>
    </div>
  );
}
